import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BaseModal from "../base-modal";
import {adminGetAllGroups} from "../../../actions";


class ModalAddEditRegexes extends BaseModal {

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {include, exclude, category, expression} = data;

        const {
            t: translate,
        } = this.props;

        console.log('render modal ModalAddEditRegexes', this.props, this.state);

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="category">
                            <Form.Control
                                type="number"
                                name="category"
                                defaultValue={category}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter category")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="expression">
                            <Form.Control
                                type="text"
                                name="expression"
                                defaultValue={expression}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter expression")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="include">
                            <Form.Control
                                type="text"
                                name="include"
                                defaultValue={include}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter include")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="exclude">
                            <Form.Control
                                type="text"
                                name="exclude"
                                defaultValue={exclude}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter exclude")}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch, {tlService}) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            adminGetAllGroups: adminGetAllGroups(tlService)
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditRegexes);

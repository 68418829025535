import BaseModal from "../base-modal";
import {bindActionCreators} from "redux";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Alert, Col, Form, Row} from "react-bootstrap";
import React from "react";


class ModalAddEditTextbinReplacement extends BaseModal {
    componentDidMount() {
        //const {index} = this.props;
        //const data = getDataFromModals(this.props, index);
        //const {id, words} = data;
    }

    render() {
        const {index, t: translate} = this.props;
        const data = getDataFromModals(this.props, index);
        const {word, error} = data;
        console.log('render', index, error)

        return (
            <>
                {error &&
                    <Alert variant="danger" onClose={() => this.props.modalSetErrorData(index, false)} dismissible>
                        <p className="mx-auto text-center">{error}</p>
                    </Alert>}
                <Row className="m-2">
                    <Col>
                        <Row>
                            <Col className="p-1">
                                <Form.Control
                                    size="xs"
                                    type="text"
                                    name="word"
                                    value={word}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={translate("Enter word")}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditTextbinReplacement);
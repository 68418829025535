import React, {Component} from "react";

import {
    compose,
    convertLanguagesToFull,
    convertPlainToHTML, removeTagMark,
    setLanguage,
    cumulativeOffset, convertArrayToText, stripHtml
} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Alert, Button, Col, Row} from "react-bootstrap";
import BenchmarkPanelTemplates from "../../elements/benchmark-panel-templates";
import TinyEditor from "../../elements/tiny-editor";
import BenchmarkChoose from "../../elements/benchmark-choose";
import Dnd from "../../elements/dnd/dnd";
import backendLanguages from "../../../utils/backendlanguages.json";
import mammoth from "mammoth";
import Spinner from "../../elements/spinner";
import ResultTemplates from "../../result-templates";
import config from "../../../config";

import "./slider.css"
import {bindActionCreators} from "redux";
import {
    adminGetAllBenchmarkTemplates,
    adminGetAllCalcelements,
    adminGetAllCompanies,
    adminGetAllGroups,
    adminGetAllOptions,
    adminGetAllReturnValues, adminGetAllSettings,
    adminGetAllTextTypes
} from "../../../actions";
import {PopupNegativeTerm, PopupDefault, PopupColorWords, PopupSpellcheck} from "../../popups";


class Enduser extends Component {

    state = {
        huspell: {},
        mustReload: false,
        tooltips: [],
        analyseButtonDisable: true,
        submitted: false,
        error: false,
        editor: false,
        text: '',
        setBenchmark: 0,
        textLanguage: false,
        autoTextLanguage: convertLanguagesToFull(this.props.i18n.language, backendLanguages),
        enabledBenchmarks: [],
        resultTemplate: 0,
        resultData: {},

        // popover/ popup
        popups: [],
        popover: {
            popId: 0,
            left: 0,
            top: 0,
            data: {}
        },
        PopupComponent: <PopupDefault/>,
        popoverOpen: false,
        popoverFixed: false,

    }

    checkSizes(full, chars) {
        const {t: translate} = this.props;
        if (full > config.maxSize) {
            this.setState({
                error: translate("too big size"),
                submitted: false
            });
            return false;
        }
        if (chars > config.maxSizeInCharacters) {
            this.setState({
                error: translate("text too long"),
                submitted: false
            });
            return false;
        }
        return true;
    }

    async loadBenchmarksList() {
        const {userData: {token}, tlService, t: translate} = this.props;
        const {textLanguage} = this.state;
        let benchmarks;
        try {
            benchmarks = await tlService.getBenchmarks(token);
        } catch (e) {
            this.setState({error: translate('No benchmark for you')})
        }

        if (benchmarks && benchmarks.data && benchmarks.data.length > 0) {
            let tmpBench = [];

            benchmarks.data.map(bench => {

                if (bench["locale_name"] === textLanguage) {
                    tmpBench.push({
                        ...bench
                    })
                }
                return null;
            });
            this.setState({enabledBenchmarks: tmpBench});
        }
    }

    onReadDropFiles = (acceptedFiles) => {
        const {t: translate} = this.props;
        this.setState({submitted: true});

        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                reader.onload = () => {
                    try {

                        mammoth.convertToHtml({arrayBuffer: reader.result}).then(result => {

                            console.log('fullsize', result.value.length);
                            console.log('charsize ', stripHtml(result.value).length);

                            if (!this.checkSizes(result.value.length, stripHtml(result.value).length))
                                return false;

                            const lang = setLanguage(result.value);
                            this.setState({
                                error: false,
                                textLanguage: lang,
                                autoTextLanguage: lang,
                                text: result.value,
                                resultTemplate: 0,
                                resultData: {},
                                submitted: false
                            });
                        })
                    } catch (e) {
                        console.log(e)
                        alert(`${translate("File conversion error")}: ${e.toString()}`);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else if (file.type === "text/plain") {
                reader.onload = () => {

                    let htmlResult = reader.result;
                    if (htmlResult.length > 0 && !htmlResult.match(/<.+>/)) {
                        htmlResult = convertPlainToHTML(htmlResult);
                    }

                    console.log('fullsize', htmlResult.length);
                    console.log('charsize ', stripHtml(htmlResult).length);

                    if (!this.checkSizes(htmlResult.length, stripHtml(htmlResult).length))
                        return false;

                    const lang = setLanguage(htmlResult);
                    this.setState({
                        error: false,
                        textLanguage: lang,
                        autoTextLanguage: lang,
                        text: htmlResult,
                        resultTemplate: 0,
                        resultData: {},
                        submitted: false
                    });

                };
                reader.readAsText(file);
            } else if (file.type === "text/html") {
                reader.onload = () => {
                    let htmlResult = reader.result;

                    console.log('fullsize', htmlResult.length);
                    console.log('charsize ', stripHtml(htmlResult).length);

                    if (!this.checkSizes(htmlResult.length, stripHtml(htmlResult).length))
                        return false;

                    const lang = setLanguage(htmlResult);
                    this.setState({
                        textLanguage: lang,
                        autoTextLanguage: lang,
                        text: htmlResult,
                        resultTemplate: 0,
                        resultData: {},
                        submitted: false
                    });
                };
                reader.readAsText(file);

            } else {
                this.setState({submitted: false});
                alert(`${translate("Unsupported file type")}: ${file.type}`);
            }
        });
    };

    handleOnEditorInit(event, editor) {
        this.setState({editor});
    }

    setButtonState(textLength, benchmarkId) {
        if (textLength && textLength > 0 && benchmarkId && benchmarkId > 0) {
            this.setState({analyseButtonDisable: false});
        } else {
            this.setState({analyseButtonDisable: true});
        }
    }

    handleOnEditorChange(text, editor) {
        const {textLanguage, text: oldText} = this.state;

        /*
        const findFirstDiff = (str1, str2) => {
            const finded = [...str1].findIndex((el, index) => el !== str2[index])
            return str2.substr(finded, 20) + ' ' + finded;
        }

         */

        const cuttedText = removeTagMark(text);
        const cuttedOldText = removeTagMark(oldText);

        if (cuttedOldText !== cuttedText) {

           // console.log('handleOnEditorChange', findFirstDiff(cuttedOldText, cuttedText))

            this.setState({mustReload: true});
        }

        const lang = setLanguage(text);
        if (!textLanguage) {
            this.setState({textLanguage: lang});
        }
        this.setState({autoTextLanguage: lang});
        this.setState({editor});
        this.setState({text: text});
    }

    handleOnEditorKeyUp(key, editor) {
        /*
        let content = editor.getContent();
        const lang = setLanguage(content);
        this.setState({textLanguage: lang});
        this.setState({autoTextLanguage: lang});
        this.setState({editor});
        */
    }

    componentDidMount() {
        const {
            userData: {token},
        } = this.props;

        this.props.adminGetAllSettings(token);
        this.loadBenchmarksList();

        this.runSpellcheck();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // check for update text language
        if (prevState.textLanguage !== this.state.textLanguage) {
            this.loadBenchmarksList();
        }

        if (prevState.text !== this.state.text || prevState.setBenchmark !== this.state.setBenchmark) {
            this.setButtonState(this.state.text.length, this.state.setBenchmark);
        }
    }

    findClaStart(text, start) {
        for (let i = start - 1; i >= 0; i--) {
            if (text[i]['cla'] === 'CLA' || text[i]['snt'] === 'SNT') {
                return i + 1;
            }
        }
        return 0;
    }

    findSntStart(text, start) {
        for (let i = start - 1; i >= 0; i--) {
            if (text[i]['snt'] === 'SNT') {
                return i + 1;
            }
        }
        return 0;
    }

    findToNotHTML(text, start, limit = 0, direction = -1) {
        let i = start;
        while (text[i] && text[i]['text'].match(/<.+>/)) {
            if (i === limit) return limit;
            i += direction;
        }
        return i;
    }

    splitTextToArray(dataTokenizer, currentContent) {
        let splittedText = [];
        if (dataTokenizer && dataTokenizer.length) {
            for (let i = 0; i < dataTokenizer.length; i++) {

                let preText = '';
                if (i === 0) {
                    preText = currentContent.substring(0, dataTokenizer[0]['begin']);
                } else if (dataTokenizer[i]['begin'] > dataTokenizer[i - 1]['end']) {
                    preText = currentContent.substring(dataTokenizer[i - 1]['end'], dataTokenizer[i]['begin']);
                }

                splittedText.push({
                    pre: preText,
                    dataTokenizer: dataTokenizer[i],
                    text: currentContent.substring(dataTokenizer[i]['begin'], dataTokenizer[i]['end']),
                    post: i === dataTokenizer.length - 1 ? currentContent.substring(dataTokenizer[i]['end'], currentContent.length) : ''
                })
            }
        }
        return splittedText
    }

    joinTextFromArray(splittedText) {
        let tmpContent = '';
        for (let i = 0; i < splittedText.length; i++) {
            tmpContent += splittedText[i]['pre'] + splittedText[i]['text'] + splittedText[i]['post'];
        }
        return tmpContent;
    }

    async restartAnalyse() {
        const {mustReload, text} = this.state;
        if (mustReload) {
            this.setState({text: removeTagMark(text)});
            this.setState({mustReload: false});
            await this.startAnalyse();
        }

    }

    async startAnalyse() {
        const {setBenchmark, textLanguage, text} = this.state;
        const {tlService, userData: {token}, t: translate} = this.props;


        console.log('startAnalyse');

        if (setBenchmark > 0) {


            this.setState({mustReload: false});

            // get current content
            let tmpContent = removeTagMark(text);

            // check for size
            if (!this.checkSizes(text.length, stripHtml(text).length))
                return false;

            this.setState({submitted: true});

            let benchmarkRes = false;
            try {
                benchmarkRes = await tlService.runBenchmark(token, setBenchmark, textLanguage, tmpContent)
                //benchmarkRes = dummy;

            } catch (e) {
                console.log(e);
                this.setState({error: e.toString()})
                this.setState({submitted: false});
                return false;
            }
            // console.log(benchmarkRes);
            this.setState({error: false});

            // set template

            console.log('resultData', benchmarkRes);

            this.setState({resultTemplate: setBenchmark})
            this.setState({resultData: benchmarkRes});
            this.setState({submitted: false});

            return true;
        } else {
            this.setState({error: translate('Set the benchmark')})
        }

        return false;
    }

    setUpState(obj) {
        this.setState(obj);
        this.setState({resultTemplate: 0});
        this.setState({resultData: {}});
    }

    handleOnMouseOver(element, editor) {
        this.setState({editor});


        const {popups} = this.state;
        const leftShift = 10;
        const topShift = 90;

        if (element.target.nodeName === 'MARK' && element.target.id) {
            let cumulative = cumulativeOffset(editor.container);
            const popId = parseInt(element.target.id);
            if (popups[popId]) {

                // console.log('handleOnMouseOver element', element);
                // console.log(element.clientX + cumulative.left + leftShift, element.clientY + cumulative.top + topShift);
                // console.log(element.target.offsetLeft + cumulative.left + leftShift, element.target.offsetTop + cumulative.top + topShift);
                // console.log('handleOnMouseOver', popId);

                this.setState({
                    popover: {
                        popId,
                        left: element.clientX + cumulative.left + leftShift,
                        top: element.clientY + cumulative.top + topShift,
                        data: popups[popId]["data"]
                    },
                    PopupComponent: popups[popId]["component"],
                    popoverOpen: true
                });
                return true;
            }
        }
        this.setState({popoverOpen: false});


    }

    handleOnMouseClick(element, editor) {
        this.setState({editor});
        const {popups} = this.state;
        if (element.target.nodeName === 'MARK' && element.target.id) {
            const popId = parseInt(element.target.id);
            console.log('handleOnMouseUp', popId);
            if (popups[popId]) {
                this.setState({popoverFixed: true})
                return true;
            }
        }
        this.setState({popoverFixed: false})
        return true;
    }

    runDataTerms(key) {
        const {resultData, text} = this.state;
        let popups = [];
        let tmpContent = removeTagMark(text);
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);

        //console.log('splittedText',splittedText);

        const valCategoryTerm = resultData['dataTerms'][key];
        for (const valTerm of valCategoryTerm['result']) {
            for (const i of valTerm['position']) {
                popups.push({
                    index: i,
                    component: PopupNegativeTerm,
                    data: {

                        term: valTerm['term'],
                        replacement: valTerm['replacement'],
                        position: valTerm['position'],
                        currentPosition: i,
                        category: {
                            category_id: key,
                            name: valCategoryTerm.category['name'],
                            category_type: valCategoryTerm.category['category_type'],
                            description: valCategoryTerm.category['description'],
                            locale_name: valCategoryTerm.category['locale_name'],
                        }
                    }
                })
                if (valCategoryTerm.category['category_type'] === 'negative') {
                    let position = i;

                    if (splittedText[i] === undefined) {
                        position = i - 1;
                    }
                    splittedText[position]['text'] = `<mark id="${(popups.length - 1)}" class="snt">` + splittedText[position]['text'];
                    splittedText[position + valTerm['term']['wordcount'] - 1]['text'] += `</mark>`

                }
            }

        }

        // set new text
        const textResult = this.joinTextFromArray(splittedText);
        this.setState({text: textResult, popups});

    }

    runDataColors(key) {
        const {resultData, text} = this.state;
        let popups = [];
        //console.log('text', text.length)
        //console.log('text', text)
        let tmpContent = removeTagMark(text);

        //console.log('tmpContent', tmpContent)
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);

        //console.log('splittedText', splittedText)


        //console.log('runDataColors', Object.values(resultData['dataColorWords']).filter((val) => val['category_type'] === key))
        const categoryAllTerm = Object.values(resultData['dataColorWords']).filter((val) => val['category_type'] === key)

        // const valCategoryTerm = resultData['dataTerms'][key];
        for (const valCategoryTerm of categoryAllTerm) {
            for (const valTerm of valCategoryTerm['result']) {
                for (const i of valTerm['position']) {
                    popups.push({
                        index: i,
                        component: PopupColorWords,
                        data: {
                            term: valTerm['term'],
                            replacement: valTerm['replacement'],
                            position: valTerm['position'],
                            currentPosition: i,
                            category: {
                                category_id: key,
                                name: valCategoryTerm['name'],
                                category_type: valCategoryTerm['category_type'],
                                description: valCategoryTerm['description'],
                                locale_name: valCategoryTerm['locale_name'],
                            }
                        }
                    })
                    splittedText[i]['text'] = `<mark id="${(popups.length - 1)}" class="${valCategoryTerm['category_type']}">` + splittedText[i]['text'];
                    splittedText[i + valTerm['term']['wordcount'] - 1]['text'] = splittedText[i + valTerm['term']['wordcount'] - 1]['text'] + `</mark>`;

                }

            }
        }


        // set new text
        const textResult = this.joinTextFromArray(splittedText);
        //console.log('textResult', textResult)
        this.setState({text: textResult, popups});

    }

    runDataTonality(key) {
        const {resultData, text} = this.state;
        let popups = [];
        //let category;
        let categoryType;

        if (key === "Negative") {
            categoryType = 'dataTonality' + key;
            /*
            category = {
                category_id: "Negative",
                name: "Negative",
                category_type: "Negative",
                description: "Negative",
            };
            */
        } else {
            categoryType = 'dataTonality' + key;
/*
            category = {
                category_id: "Positive",
                name: "Positive",
                category_type: "Positive",
                description: "Positive",
            };
            */
        }


        const categoryAllTerm = Object.values(resultData['dataTonality' + key]);
        //console.log('categoryAllTerm', categoryAllTerm)

        if (categoryAllTerm) {
            let tmpContent = removeTagMark(text);
            let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
            for (const valCategoryTerm of categoryAllTerm) {
                for (const i of valCategoryTerm['position']) {
                    /*
                    popups.push({
                        index: i,
                        component: PopupColorWords,
                        data: {
                            term: valCategoryTerm['term'],
                            replacement: valCategoryTerm['replacement'],
                            position: valCategoryTerm['position'],
                            currentPosition: i,
                            category: category
                        }
                    })
                    */
                    splittedText[i]['text'] = `<mark id="${(popups.length - 1)}" class="${categoryType}">` + splittedText[i]['text'];
                    splittedText[i + valCategoryTerm['term']['wordcount'] - 1]['text'] = splittedText[i + valCategoryTerm['term']['wordcount'] - 1]['text'] + `</mark>`;

                }
            }
            // set new text
            const textResult = this.joinTextFromArray(splittedText);
            //console.log('textResult', textResult)
            this.setState({text: textResult, popups});

        }

    }

    runDataParticles(key) {
        const {resultData, text} = this.state;
        let popups = [];
        const categoryAllTerm = resultData[key];

        if (categoryAllTerm) {
            let tmpContent = removeTagMark(text);
            let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
            for (const valCategoryTerm of categoryAllTerm) {
                splittedText[valCategoryTerm]['text'] = `<mark class="${key}">` + splittedText[valCategoryTerm]['text'] + `</mark>`;
            }
            // set new text
            const textResult = this.joinTextFromArray(splittedText);
            this.setState({text: textResult, popups});

        }

    }

    runCountTonalityClause(key) {
        const {resultData, text} = this.state;
        let popups = [];

        const categoryAllTerm = Object.values(resultData['countTonalityClause' + key]);

        if (categoryAllTerm) {
            let tmpContent = removeTagMark(text);
            let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
            for (const valCategoryTerm of categoryAllTerm) {
                splittedText[valCategoryTerm[0]]['text'] = `<mark class="${'countTonalityClause' + key}">` + splittedText[valCategoryTerm[0]]['text'];
                splittedText[valCategoryTerm[1]]['text'] = splittedText[valCategoryTerm[1]]['text'] + `</mark>`;
            }
            // set new text
            const textResult = this.joinTextFromArray(splittedText);
            //console.log('textResult', textResult)
            this.setState({text: textResult, popups});

        }

    }

    countTonalityParagraph(key) {
        const {resultData, text} = this.state;
        let popups = [];

        const categoryAllTerm = Object.values(resultData['countTonalityParagraph' + key]);
        //console.log('categoryAllTerm', categoryAllTerm)

        if (categoryAllTerm) {
            let tmpContent = removeTagMark(text);
            let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
            for (const valCategoryTerm of categoryAllTerm) {
                splittedText[valCategoryTerm[0]]['text'] = `<mark class="${'countTonalityParagraph' + key}">` + splittedText[valCategoryTerm[0]]['text'];
                splittedText[valCategoryTerm[1]]['text'] = splittedText[valCategoryTerm[1]]['text'] + `</mark>`;
            }
            // set new text
            const textResult = this.joinTextFromArray(splittedText);
            //console.log('textResult', textResult)
            this.setState({text: textResult, popups});

        }

    }

    runVoiceInSentence(type) {
        const {resultData, text} = this.state;
        let popups = [];
        let tmpContent = removeTagMark(text);
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
        const allData = resultData[type]


        for (const valData of allData) {
            popups.push({
                index: valData[0],
                component: PopupDefault,
                data: `${valData[0]} ${valData[1]}  ${valData[2]}`
            })

            const begin = this.findToNotHTML(splittedText, valData[0], splittedText.length - 1, 1);
            const end = this.findToNotHTML(splittedText, valData[1] - 1);

            for(let j = begin; j <= end; j++) {
                if (splittedText[j].dataTokenizer["tag"].match(/^V.*$/)) {
                    splittedText[j]['text'] = `<mark class="verbal">` + splittedText[j]['text'] + `</mark>`;
                }
                if ( splittedText[j].dataTokenizer["tag"].match(/^PTKZU$/)) {
                    splittedText[j]['text'] = `<mark class="verbal2">` + splittedText[j]['text'] + `</mark>`;
                }
            }

            splittedText[begin]['text'] = `<mark  id="${(popups.length - 1)}" class="${type}">` + splittedText[begin]['text'];
            splittedText[end]['text'] = splittedText[end]['text'] + `</mark>`;

        }

        // set new text

        const textResult = this.joinTextFromArray(splittedText);
        this.setState({text: textResult, popups});
    }

    runExceedances(type) {
        const {resultData, text} = this.state;
        let popups = [];
        let tmpContent = removeTagMark(text);
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
        const allData = resultData[type]

        for (const valData of allData) {
            popups.push({
                index: valData[0],
                component: PopupDefault,
                data: `${valData[0]} ${valData[1]}`
            })

            const begin = this.findToNotHTML(splittedText, valData[0], splittedText.length - 1, 1);
            const end = this.findToNotHTML(splittedText, valData[1]);

            splittedText[begin]['text'] = `<mark  id="${(popups.length - 1)}" class="${type}">` + splittedText[begin]['text'];
            splittedText[end]['text'] = splittedText[end]['text'] + `</mark>`;
        }

        // set new text

        const textResult = this.joinTextFromArray(splittedText);
        this.setState({text: textResult, popups});
    }

    runExceedancesWords(type) {
        const {resultData, text} = this.state;
        let popups = [];
        let tmpContent = removeTagMark(text);
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);
        const allData = resultData[type]
        for (const valData of allData) {
            if (splittedText[valData[0]]['text'].length > 0) {
                popups.push({
                    index: valData[0],
                    component: PopupDefault,
                    data: `${valData[0]} ${valData[1]}`
                })
                splittedText[valData[0]]['text'] = `<mark  id="${(popups.length - 1)}" class="${type}">${splittedText[valData[0]]['text']}</mark>`;
            }
        }

        // set new text

        const textResult = this.joinTextFromArray(splittedText);
        this.setState({text: textResult, popups});
    }

    highlightTonality(key) {
        const {resultData, text} = this.state;
        let popups = [];
        let tmpContent = removeTagMark(text);
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);

        const tonality = key[0];


        for (let categoryType of tonality) {
            const categoryAllTerm = Object.values(resultData[categoryType]);
            if (categoryAllTerm) {
                for (const valCategoryTerm of categoryAllTerm) {
                    for (const i of valCategoryTerm['position']) {
                        splittedText[i]['text'] = `<mark id="${(popups.length - 1)}" class="${categoryType}">` + splittedText[i]['text'];
                        splittedText[i + valCategoryTerm['term']['wordcount'] - 1]['text'] = splittedText[i + valCategoryTerm['term']['wordcount'] - 1]['text'] + `</mark>`;

                    }
                }
            }
        }

        const clauses = key[1];

        for (let clauseType of clauses) {
            const categoryAllTerm = Object.values(resultData[clauseType]);
            if (categoryAllTerm) {
                for (const valCategoryTerm of categoryAllTerm) {
                    splittedText[valCategoryTerm[0]]['text'] = `<mark class="${clauseType}">` + splittedText[valCategoryTerm[0]]['text'];
                    splittedText[valCategoryTerm[1]]['text'] = splittedText[valCategoryTerm[1]]['text'] + `</mark>`;
                }
            }
        }
        // set new text
        const textResult = this.joinTextFromArray(splittedText);
        //console.log('textResult', textResult)
        this.setState({text: textResult, popups});

    }

    runInEditor(element, key) {

        console.log('runInEditor', element, key)

        switch (element) {
            case "countNouns":
            case "countAdjectives":
            case "countNumbers":
            case "countVerbs":
            case "countArticles":
            case "countParticles":
            case "countPronouns":
            case"countConjunctions":
            case "countPrepositions":
            case "countMisc":
                this.runDataParticles(element);
                break;
            case "huspell":
                this.runSpellcheck();
                break;
            case "highlightTonality":
                this.highlightTonality([
                    ['dataTonalityNegative', 'dataTonalityPositive'],
                    ['countTonalityClauseNegative', 'countTonalityClausePositive']
                ]);
                break;
            case "highlightTonalityPN":
                this.highlightTonality([
                    ['dataTonalityNeutral', 'dataTonalityPersonal'],
                    ['countTonalityClauseNeutral', 'countTonalityClausePersonal']
                ]);
                break;
            case "countTonalityParagraph":
                this.countTonalityParagraph(key);
                break;
            case "countTonalityClause":
                this.runCountTonalityClause(key);
                break;
            case "countTonalityClausePN":
                this.runCountTonalityClause(key);
                break;
            case "countTonalityParagraphPN":
                this.countTonalityParagraph(key);
                break;
            case "dataTerms":
                this.runDataTerms(key);
                break;
            case "dataColor":
                this.runDataColors(key);
                break;
            case "dataTonality":
                this.runDataTonality(key);
                break;
            case "dataTonalityPN":
                this.runDataTonality(key);
                break;
            case "countPassiveVoiceInSentence":
                this.runVoiceInSentence("countPassiveVoiceInSentence");
                break;
            case "countFutureTenseInSentence":
                this.runVoiceInSentence("countFutureTenseInSentence");
                break;
            case "countInfinitiveConstructions":
                this.runVoiceInSentence("countInfinitiveConstructions");
                break;
            case "moreParagraphsInWords":
                this.runExceedances("moreParagraphsInWords");
                break;
            case "moreClausesInWords":
                this.runExceedances("moreClausesInWords");
                break;
            case "moreSentencesInPhrases":
                this.runExceedances("moreSentencesInPhrases");
                break;
            case "moreSentencesInWords":
                this.runExceedances("moreSentencesInWords");
                break;
            case "moreWordsInLetters":
                this.runExceedancesWords("moreWordsInLetters");
                break;
            case "moreSentencesInBrackets":
                this.runExceedances("moreSentencesInBrackets");
                break;
            case "moreSentencesInPrepositions":
                this.runExceedances("moreSentencesInPrepositions");
                break;
            case "countNominalStyle":
                this.runExceedances("countNominalStyle");
                break;
            default:
                console.log('default')
        }
    }

    goNextPopup() {

    }

    replaceFromPopup(place, replacement, type = 'negative') {
        const {
            popups, editor
        } = this.state;

        const element = editor.dom.select('mark#' + place.toString())[0];
        if (element) {


            console.log('replaceFromPopup  place', place);
            console.log('replaceFromPopup  popups[place]', popups[place]);
            console.log('replaceFromPopup replacement', replacement);
            console.log('dom ', editor.dom.select('mark#' + place.toString()));

            if (type === 'negative' || type === 'color') {
                element.outerHTML =
                    '<mark class="changed">' + convertArrayToText(JSON.parse(popups[place]['data']['replacement'][replacement]['words'])) + '</mark>';
            } else if (type === 'spell') {
                element.outerHTML = '<mark class="changed">' + replacement + '</mark>';
            }


            this.setState({popoverOpen: false, popoverFixed: false, mustReload: true, text: editor.getContent()});


        }
    }


    refreshEditor() {
        const {text} = this.state;
        let popups = [];
        let tmpContent = removeTagMark(text);
        this.setState({text: tmpContent, popups});
    }

    /*
        async loadSpellcheck(lang = false) {
            const {textLanguage, huspell} = this.state;

            lang = lang || textLanguage;

            if (!huspell[lang]) {
                try {
                    //const spellchecker = new Spellchecker();
                    const aff = await (await fetch(`/static/dict/${lang}/index.aff`)).arrayBuffer();
                    const dic = await (await fetch(`/static/dict/${lang}/index.dic`)).arrayBuffer();
                    huspell[lang] =  nspell({aff: new Buffer(aff), dic: new Buffer(dic)});
                    this.setState({huspell});
                } catch (e) {
                    console.log(e)
                }
            }
        }
        */

    runSpellcheck(lang = false) {
        /*
        spell_correct: true
spell_suggestion: [
        */
        const {resultData, text} = this.state;

        let popups = [];
        let tmpContent = removeTagMark(text);
        let splittedText = this.splitTextToArray(resultData.dataTokenizer, tmpContent);

        for (let i = 0; i < splittedText.length; i++) {
            const currentWord = splittedText[i];
            if (currentWord['dataTokenizer']['tag'] !== 'SGML_TAG' && !currentWord['dataTokenizer']['spell_correct']) {
                console.log('runSpellcheck word ', currentWord);
                popups.push({
                    index: i,
                    component: PopupSpellcheck,
                    data: {
                        suggestion: currentWord['dataTokenizer']['spell_suggestion'] || [],
                        text: currentWord['text']
                    }
                })
                splittedText[i]['text'] = `<mark id="${(popups.length - 1)}" class="huspell">` + splittedText[i]['text'] + `</mark>`;

            }
        }
        const textResult = this.joinTextFromArray(splittedText);
        this.setState({text: textResult, popups});
    }

    render() {
        const {
            setBenchmark, enabledBenchmarks, text, textLanguage,
            autoTextLanguage, error, resultTemplate, resultData, submitted,
            analyseButtonDisable, popoverOpen, PopupComponent, popover, popoverFixed
        } = this.state;
        const {t: translate} = this.props;

        //console.log('dummy',dummy);
        //console.log('resultData.dataTokenizer', JSON.stringify( resultData.dataTokenizer))

        //  if (submitted) {
        //      return <Spinner/>
        //  }


        return (
            <>
                {submitted && <Spinner/>}
                <div className="p-4">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">{translate('End User')}</h1>
                    </div>
                    {error &&
                    <Alert variant="danger" onClose={() => this.setState({error: false})} dismissible>
                        <p className="mx-auto text-center">{error}</p>
                    </Alert>}
                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col className="p-1">
                                    <BenchmarkPanelTemplates
                                        enabledBenchmarks={enabledBenchmarks.find(value => value.id === parseInt(setBenchmark))}
                                        resultTemplate={resultTemplate}
                                        resultData={resultData}/>
                                </Col>
                                <Col sm={2} className="my-auto py-4">
                                    <Dnd disabled={false} text={translate("dnd zone")} onReadDropFiles={(acceptedFiles) => this.onReadDropFiles(acceptedFiles)}/>
                                </Col>
                                <Col sm={2} className="py-4">
                                    <Button variant="success" disabled={analyseButtonDisable}
                                            onClick={() => this.startAnalyse()}>{translate('Start analyse')}</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-2">
                                    <TinyEditor text={text}
                                                handleOnMouseClick={(text, editor) => this.handleOnMouseClick(text, editor)}
                                                handleOnMouseOver={(text, editor) => this.handleOnMouseOver(text, editor)}
                                                handleOnEditorChange={(text, editor) => this.handleOnEditorChange(text, editor)}
                                                handleOnEditorInit={(event, editor) => this.handleOnEditorInit(event, editor)}
                                                handleOnEditorKeyUp={(key, editor) => this.handleOnEditorKeyUp(key, editor)}/>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} className="p-4">
                            <ResultTemplates
                                refreshEditor={() => this.refreshEditor()}
                                restartAnalyse={() => this.restartAnalyse()}
                                runInEditor={(element, key) => this.runInEditor(element, key)}
                                enabledBenchmarks={enabledBenchmarks.find(value => value.id === parseInt(setBenchmark))}
                                resultTemplate={resultTemplate}
                                resultData={resultData}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-2" md={6}>
                            <BenchmarkChoose
                                enabledBenchmarks={enabledBenchmarks}
                                setBenchmark={setBenchmark}
                                textLanguage={textLanguage}
                                autoTextLanguage={autoTextLanguage}
                                setUpState={obj => this.setUpState(obj)}
                            />
                        </Col>
                    </Row>
                    {(popoverOpen || popoverFixed) && <PopupComponent
                        replaceFromPopup={(place, replacement, type) => this.replaceFromPopup(place, replacement, type)}
                        goNextPopup={() => this.goNextPopup()}
                        {...this.props}
                        popover={popover}
                    />}
                </div>
            </>
        );
    }
}


const mapStateToProps = ({userData, openData, adminData}) => {
    return {userData, openData, adminData};
};
const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllGroups: adminGetAllGroups(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService),
        adminGetAllCalcelements: adminGetAllCalcelements(tlService),
        adminGetAllReturnValues: adminGetAllReturnValues(tlService),
        adminGetAllTextTypes: adminGetAllTextTypes(tlService),
        adminGetAllBenchmarkTemplates: adminGetAllBenchmarkTemplates(tlService),
        adminGetAllSettings: adminGetAllSettings(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Enduser);

import React, {useState} from 'react';
import {Button, Col, Form, Popover, Row} from "react-bootstrap";

const prepareReplacement = (replacement) => {
    let returnReplacements = {
        false: {
            fullLength: replacement.length,
            replacements: []
        }
    };
    if (replacement.length > 0) {
        for (let index in replacement) {
            const {description, lemma, status, words, tag} = replacement[index]
            returnReplacements[false].replacements.push({description, lemma, status, words, tag, index})
        }
    }
    return returnReplacements

}

const PopupColorWords = (props) => {
    const [repl, setRepl] = useState(0);
    const {

        replaceFromPopup,
        popover: {
            left, top,
            data: {term, replacement},
            popId
        }
    } = props;

    const preparedReplacement = prepareReplacement(replacement);
   // console.log("preparedReplacement", preparedReplacement);

    return (
        <Popover
            id={"popover" + popId}
            style={{left, top, maxWidth: 'none'}}
        >
            <Popover.Title as="h3">Color words replacement suggestion</Popover.Title>
            <Popover.Content>
                <Row>
                    <Col className="font-weight-bold p-2">
                        {JSON.parse(term['words']).join(" ")}
                    </Col>
                </Row>
                <Row>
                    <Col className="font-weight-bold p-2">Suggestion</Col>

                </Row>
                {preparedReplacement[false].fullLength > 0 ?
                    <Row>
                        <Col md={8} className="p-2">
                            <Form.Control
                                size={"xs"}
                                as="select"
                                htmlSize={5}
                                value={repl}
                                onChange={(event) => setRepl(event.target.value)}
                                name="suggestions">
                            </Form.Control>
                        </Col>
                        <Col className=" p-2">
                            <Button
                                onClick={() => replaceFromPopup(popId, repl, 'color')}
                                variant="secondary">
                                Replace
                            </Button>
                        </Col>
                    </Row> :
                    <Row>
                        <Col className="p-2">
                            No suggestions, hooray!
                        </Col>
                    </Row>

                }

            </Popover.Content>
        </Popover>
    );
};

export default PopupColorWords;

import React, {Component} from 'react';

import {bindActionCreators} from "redux";
import {compose, deepCopy} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";

import * as XLSX from 'xlsx';
import {Row, Col, Form, Button, Container, Alert} from "react-bootstrap";
import {readUploadedFileAsBinary} from "../../../utils";
import siteLanguages from "../../../utils/sitelanguages.json";
import TemplateModal from "../../modals/template-modal";
import Spinner from "../../elements/spinner";


class Abbreviations extends Component {

    regexWrap = {
        de: '[^\\wäöüÄÖÜß-]',
        en: '[^\\w-]',
        ru: '[^\\wа-яА-ЯЁ-]'
    }

    state = {
        selectedFile: null,
        language: this.props.i18n.language,
        languageDownload: this.props.i18n.language,
        error: false,
        submitted: false,
        sendData: false,
        showModal: false,
        update: true
    };

    prepareFile(e) {
        this.setState({selectedFile: e.target.files[0]});
    }

    onChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    onChangeUpdate() {
        this.setState({update: !this.state.update});
    }

    async startUpload() {
        const {selectedFile, language} = this.state;
        const {t: translate} = this.props;
        if (selectedFile) {
            if (
                selectedFile['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                selectedFile['type'] === "application/vnd.ms-excel"
            ) {
                const fileContents = await readUploadedFileAsBinary(selectedFile, false);
                const workbook = XLSX.read(fileContents, {type: 'binary'})
                if (workbook && workbook.SheetNames[0] && workbook.Sheets[workbook.SheetNames[0]]) {
                    const data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 0});
                    if (data && data.length > 0) {
                        let sendData = [];
                        for (let tmpdata of data) {
                            if (tmpdata['Wort'] && tmpdata['Synonym'] && tmpdata['SyllCnt']) {
                                sendData.push({
                                    regexp: this.regexWrap[language] + tmpdata['Wort'].trim() + this.regexWrap[language],
                                    syllcount: parseInt(tmpdata['SyllCnt']),
                                    synonym: tmpdata['Synonym'].split('|').map(Function.prototype.call, String.prototype.trim)
                                })
                            }
                        }
                        if (sendData.length > 0) {
                            this.setState({sendData})
                            this.modalOpen();
                        } else {
                            this.setState({error: translate('Data is empty 2')})
                        }
                    } else {
                        this.setState({error: translate('Data is empty')})
                    }
                } else {
                    this.setState({error: translate('Wrong data')})
                }
            } else {
                this.setState({error: translate('Wrong file format')})
            }
        } else {
            this.setState({error: translate('Choose file for upload')})
        }
    }

    modalClose() {
        this.setState({showModal: false});
    }

    modalOpen() {
        this.setState({error: false});
        this.setState({showModal: true});
    }

    async modalAction() {
        const {
            userData: {token},
            t: translate,
            tlService
        } = this.props;
        const {language, update} = this.state;
        let sendData = deepCopy(this.state.sendData);
        this.setState({submitted: true})

        if (update) {
            let data = false;
            let newdata = [];
            try {
                data = await tlService.abbreviationDownload(token, language);
            } catch (e) {
                this.setState({submitted: false});
                this.setState({error: translate('Download error')})
                return true;
            }
            if (data && data.data && data.data.length > 0) {
                newdata = data.data.map((word) => {
                    return {
                        regexp: word.regexp,
                        syllcount: word.syllcount,
                        synonym: word.synonym
                    }
                })
                for (let word of sendData) {
                    if (newdata.find((value) => value.regexp === word.regexp)) {
                        continue;
                    }
                    newdata.push(word);
                }
                sendData = newdata;
            }
        }
        try {
            await tlService.abbreviationUpload(token, language, sendData);
            this.setState({sendData: false})
        } catch (e) {
            console.log(e);
            this.setState({error: translate('Upload error')})
        }
        this.setState({showModal: false});
        this.setState({submitted: false});
    }

    async startDownload() {
        const {
            userData: {token},
            t: translate,
            tlService
        } = this.props;
        const {languageDownload} = this.state;
        this.setState({submitted: true});
        let data = false;
        try {
            data = await tlService.abbreviationDownload(token, languageDownload);
        } catch (e) {
            this.setState({submitted: false});
            this.setState({error: translate('Download error')})
            return true;
        }
        if (data && data.data) {
            let formattedData = [{Wort: '', SyllCnt: '', Synonym: ''}];
            if (data.data.length > 0) {
                formattedData = data.data.map((value) => {
                    return {
                        Wort: value.regexp.replace(this.regexWrap[languageDownload], '').replace(this.regexWrap[languageDownload], ''),
                        SyllCnt: parseInt(value.syllcount),
                        Synonym: value.synonym.join(' | ')
                    }
                })
            }
            const workSheet = XLSX.utils.json_to_sheet(formattedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, workSheet, 'Sheet1');
            const bin = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
            const element = document.createElement("a");
            const file = new Blob([bin], {type: 'application/vnd.ms-excel'});
            element.href = URL.createObjectURL(file);
            element.download = "download.xlsx";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();

        }


        this.setState({submitted: false});
    }

    render() {
        const {
            t: translate
        } = this.props;

        const {language, languageDownload, showModal, error, submitted, update} = this.state;
        if (submitted) {
            return <Spinner/>
        }

        return (
            <Container fluid>
                {error && !showModal &&
                <Alert variant="danger" onClose={() => this.setState({error: false})} dismissible>
                    <p className="mx-auto text-center">{error}</p>
                </Alert>}
                <Row>
                    <Col md={6}>
                        <h3 className="px-1 py-1">Abbreviations upload</h3>
                        <Row className="px-1">
                            <Col className="px-1">
                                <Form.Group controlId="uploadFile">
                                    <Form.Control
                                        type="file"
                                        name="uploadFile"
                                        onChange={e => {
                                            this.prepareFile(e)
                                        }}
                                        placeholder={translate("Upload file")}/>
                                </Form.Group>
                            </Col>
                            <Col className="px-1">
                                <Form.Group controlId="language">
                                    <Form.Control as="select" size="xs" custom name="language" value={language}
                                                  onChange={e => this.onChange(e)}>
                                        {
                                            Object.keys(siteLanguages).map(lang =>
                                                <option value={lang}
                                                        key={lang}>{siteLanguages[lang]['name']} </option>
                                            )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="px-1">
                            <Col className="px-1">
                                <Form.Group controlId="update">
                                    <Form.Check
                                        checked={update}
                                        onChange={() => this.onChangeUpdate()}
                                        name="update"
                                        type="checkbox"
                                        label={translate("Add the new records only")}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="px-2">
                                <Button
                                    onClick={() => this.startUpload()}
                                    className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm">{translate('Upload file')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <h3 className="px-1 py-1">Abbreviations download</h3>
                        <Row className="px-1">
                            <Col className="px-1">
                                <Form.Group controlId="languageDownload">
                                    <Form.Control as="select" size="xs" custom name="languageDownload"
                                                  value={languageDownload}
                                                  onChange={e => this.onChange(e)}>
                                        {
                                            Object.keys(siteLanguages).map(lang =>
                                                <option value={lang}
                                                        key={lang}>{siteLanguages[lang]['name']} </option>
                                            )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-2">
                                <Button
                                    onClick={() => this.startDownload()}
                                    className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm">{translate('Download file')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <TemplateModal options={{
                    show: showModal,
                    header: translate('Alert'),
                    text: translate('All data will be deleted!'),
                    error,
                    footer: true,
                    closeButton: true,
                    closeButtonText: translate('Close'),
                    saveButton: true,
                    ok: true,
                    saveButtonText: translate('Ok')
                }}
                               setErrorFalse={() => true}
                               modalClose={() => this.modalClose()}
                               modalAction={() => this.modalAction()}/>
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData}) => {
    return {userData, openData, modalData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Abbreviations);

import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { JsonEditor } from 'jsoneditor-react'
import { isJson } from '../../../utils'
import ace from 'brace'
import 'brace/mode/json'
import './style.css'

const JsonModal = ({
    showModal,
    setShowModal,
    inputName,
    onChangeSettingValue,
    translate: t,
    tempVal,
    setTempVal,
    settingType,
    settingName,
    isUGC = false
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const sectionName = isUGC ? settingName : inputName.split('|')[0]
    const defaultValue = isJson(tempVal) ? JSON.parse(tempVal) : tempVal

    const handleChange = (value) => {
        setTempVal(JSON.stringify(value))
        setButtonDisabled(value === null )
    }

    const handleValidate = (error) => {
        if (error.length > 0) {
           setButtonDisabled(true) 
        }
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleSaveChanges = () => {
        setButtonDisabled(true)
        const target = { target: { value: tempVal, name: inputName } }
        isUGC ? onChangeSettingValue(target, true) : onChangeSettingValue(target, settingType)
        setShowModal(false)
    }

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='mr-2 text-capitalize'> {t("edit")}</span>
                    <span><i>{t(sectionName)}</i></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <JsonEditor
                    ace={ace}
                    mode="code"
                    htmlElementProps={{ style: { height: '70vh', width: '100%' } }}
                    value={defaultValue}
                    onChange={handleChange}
                    name={t(sectionName)}
                    onValidationError={handleValidate}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    {t('Close')}
                </Button>
                <Button variant="primary" onClick={handleSaveChanges} disabled={buttonDisabled}>
                    {t('Save')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JsonModal

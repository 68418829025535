import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Col, ProgressBar, Row } from "react-bootstrap";
import ModalCopyBenchmarks from "../../modals/modal-copy-benchmarks";


const CompanyHeader = (props) => {
  const [disableButtons, setDisableButtons] = useState(false);

  const { lang } = props.state;
  const {
    openModal,
    t: translate,
    tlService,
    userData: {
      token,
      user: { user_type_id },
    },
  } = props;

  // console.log("From Header", props);
  const [uploadError, setUploadError] = useState(null);

  const openMod = () => {
    openModal(0, 0, "modalCopyBenchmarks")
  }

  return (
    <Row>
      <Col md={1} className="mr-2">
        <Link to="/">
          <i className="fas fa-arrow-left" />
        </Link>
      </Col>
      {user_type_id === 1 && (
        <>
          <Col className="col-9 m-4">
            <Row>
              <Col className="pr-1">
                <Button
                  disabled={disableButtons}
                  className="btn btn-xs btn-primary  h-auto"
                  onClick={openMod}
                >
                  {translate("Copy Benchmarks Into Companies")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                {uploadError && (
                  <Alert
                    variant="danger"
                    onClose={() => setUploadError(false)}
                    dismissible
                  >
                    <div className="font-smaller mx-auto text-center">
                      <pre>{uploadError}</pre>
                    </div>
                  </Alert>
                )}
              </Col>
            </Row>
          </Col>
        </>
      )}

    </Row>
  );
}

export default CompanyHeader;
import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BaseModal from "../base-modal";
import {adminGetAllGroups} from "../../../actions";
import backendLanguages from "../../../utils/backendlanguages.json";


class ModalAddEditTranslations extends BaseModal {

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        console.log("data",data)
        const {value, translation, user_id, user_group_id, company_id, settings, locale_name} = data;

        const {
            t: translate,
        } = this.props;

        console.log('render modal ModalAddEditTranslations', this.props, this.state);

        let showSettings = settings;
        if (typeof settings === 'object'){
            try{
                showSettings = JSON.stringify(settings);
            } catch (e) {

            }
        }

        return (
            <Container>
                <Row>
                    <Col md={4}> {translate("user_id")}
                        <Form.Group controlId="user_id">
                            <Form.Control
                                type="number"
                                name="user_id"
                                defaultValue={user_id}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter user_id")}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}> {translate("user_group_id")}
                        <Form.Group controlId="user_group_id">
                            <Form.Control
                                type="number"
                                name="user_group_id"
                                defaultValue={user_group_id}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter user_group_id")}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}> {translate("company_id")}
                        <Form.Group controlId="company_id">
                            <Form.Control
                                type="number"
                                name="company_id"
                                defaultValue={company_id}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter company_id")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="align-middle" md={2}>
                        {translate('locale_name')}
                    </Col>
                    <Col md={10}>
                        <Form.Group controlId="locale_name">
                            <Form.Control
                                as="select"
                                value={locale_name}
                                name="locale_name"
                                onChange={e => this.handleChange(e)}>
                                {
                                    Object.keys(backendLanguages).map(lang =>
                                        <option key={lang}
                                                value={lang}>{backendLanguages[lang]['nativeName']}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="align-middle" md={2}>
                        {translate('value')}
                    </Col>
                    <Col md={10}>
                        <Form.Group controlId="value">
                            <Form.Control
                                type="text"
                                name="value"
                                defaultValue={value}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter value")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="align-middle" md={2}>
                        {translate('translation')}
                    </Col>
                    <Col md={10}>
                        <Form.Group controlId="translation">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="translation"
                                defaultValue={translation}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter translation")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="align-middle" md={2}>
                        {translate('settings')}
                    </Col>
                    <Col md={10}>
                        <Form.Group controlId="settings">
                            <Form.Control
                                type="text"
                                name="settings"
                                defaultValue={showSettings}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter settings")}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch, {tlService}) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            adminGetAllGroups: adminGetAllGroups(tlService)
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditTranslations);

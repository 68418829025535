import React, {useState} from "react";
import {Row, Col, Button, Form} from "react-bootstrap";
import siteLanguages from "../../utils/sitelanguages.json";

export default function TableFilter(props) {
    const [filterChoose, setFilterChoose] = useState('');
    const [langChoose, setLangChoose] = useState(props.i18n.language);

    const reduxData = {};
    for (let filter of Object.entries(props.tableFilters)){
        if (filter[1]?.type === 'object'){
            const value = filter[1].value.split('.');
            reduxData[filter[0]] = props[value[0]][value[1]];
        }
    }

    const {
        tableFilters,
        t: translate
    } = props;
    const {filters} = props.state;

    const addFilter = () => {
        const {setState} = props;
        const {filters, limits} = props.state;
        if (tableFilters[filterChoose]) {
            setState({
                filters: {
                    ...filters,
                    [filterChoose]: tableFilters[filterChoose]['default']
                },
                limits: {
                    ...limits,
                    offset: 0
                }
            })
        }

    }

    const clearAllFilters = () => {
        const {setState} = props;
        setState({filters: {}})
    }

    const handleChangeCheckedFilter = (key) => {
        const {setState} = props;
        const {filters, limits} = props.state;
        setState({
            filters: {
                ...filters,
                [key]: !filters[key]
            },
            limits: {
                ...limits,
                offset: 0
            }
        })
    }

    const handleChangeFilterString = (event) => {
        const {setState} = props;
        const {filters, limits} = props.state;
        const {name, value} = event.target;
        setState({
            filters: {
                ...filters,
                [name]: `%${value}%`
            },
            limits: {
                ...limits,
                offset: 0
            }
        })
    }

    const handleChangeFilterSelect = (event) => {
        const {setState} = props;
        const {filters, limits} = props.state;
        const {name, value} = event.target;
        setState({
            filters: {
                ...filters,
                [name]: `${value}`
            },
            limits: {
                ...limits,
                offset: 0
            }
        })
    }

    const handleChangeFilterMultilang = (event) => {
        const {setState} = props;
        const {filters, limits} = props.state;
        const {name, value} = event.target;

        if (value) {
            setState({
                filters: {
                    ...filters,
                    [name]: `${langChoose}%${value}%`
                },
                limits: {
                    ...limits,
                    offset: 0
                }
            })
        } else {
            setState({
                filters: {
                    ...filters,
                    [name]: tableFilters[name]['default']
                },
                limits: {
                    ...limits,
                    offset: 0
                }
            })
        }
    }

    const delFilterElement = (key) => {
        const {setState} = props;
        let filters = {...props.state.filters};
        delete filters[key]
        setState({filters});
    }

    const getValFromMultilangFilter = (val) => {

        if (val && val.length > 0) {
            const reg = new RegExp('[\w]{2}%(.+)%')
            let ret = val.match(reg);
            if (ret) {
                return ret[1];
            }
        }
        return undefined;
    }

    const getValFromStringFilter = (val) => {
        if (val && val.length > 0) {
            const reg = new RegExp('%(.+)%')
            let ret = val.match(reg);
            if (ret) {
                return ret[1];
            }
        }
        return undefined;
    }

    return (
        <>
            <Row>
                <Col md={2} className="p-1 my-auto">
                    <Form.Control
                        size={"xs"}
                        as="select"
                        value={filterChoose}
                        name="filterChoose"
                        onChange={e => setFilterChoose(e.target.value)}>
                        <option key="" value="">{translate('Add filter')}</option>
                        {
                            Object.keys(tableFilters).map((key) => {
                                if (Object.keys(filters).indexOf(key) === -1)
                                    return (<option key={key} value={key}>{translate(key)}</option>)
                            })
                        }
                    </Form.Control>
                </Col>
                <Col md={2} className="p-1 my-auto">
                    <Button variant="success"
                            onClick={() => addFilter()}
                            className="btn-xs"><i
                        className="fas fa-plus"/></Button>
                    <Button variant="danger"
                            onClick={() => clearAllFilters()}
                            className="ml-2 btn-xs"><i
                        className="fas fa-times pr-1"/>{translate('Clear all filters')}</Button>
                </Col>
            </Row>
            {Object.entries(filters).map(([key, val]) => {

                if (tableFilters[key]) {
                    switch (tableFilters[key].type) {
                        case 'boolean':
                            return (
                                <Row key={key}>
                                    <Col md={2} className="p-2 my-auto">
                                        <Form.Check
                                            className="font-smaller"
                                            checked={!!val}
                                            onChange={() => handleChangeCheckedFilter(key)}
                                            name={key}
                                            type="checkbox"
                                            label={translate(tableFilters[key].name)}/>
                                    </Col>
                                    <Col className="p-1  my-auto">
                                        <Button
                                            variant="danger"
                                            onClick={() => delFilterElement(key)}
                                            className="ml-2 btn-xs"><i
                                            className="fas fa-times pr-1"/>{translate('Delete this filter')}</Button>
                                    </Col>
                                </Row>
                            )
                        case 'multilang':
                            return (
                                <Row key={key}>
                                    <Col md={1} className="p-2 my-auto">
                                        <Form.Control
                                            size="xs"
                                            as="select"
                                            value={langChoose}
                                            name="filterChoose"
                                            onChange={e => setLangChoose(e.target.value)}>
                                            {
                                                Object.keys(siteLanguages).map(lang =>
                                                    <option
                                                        key={lang}
                                                        value={lang}
                                                    >{translate(siteLanguages[lang]['name'])}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Col>
                                    <Col md={1} className="p-2 my-auto">
                                        <Form.Control
                                            size='xs'
                                            type="text"
                                            name={key}
                                            value={getValFromMultilangFilter(val)}
                                            onChange={handleChangeFilterMultilang}
                                            placeholder={translate(tableFilters[key].name)}/>
                                    </Col>
                                    <Col className="p-1  my-auto">
                                        <Button
                                            variant="danger"
                                            onClick={() => delFilterElement(key)}
                                            className="ml-2 btn-xs"><i
                                            className="fas fa-times pr-1"/>{translate('Delete this filter')}</Button>
                                    </Col>
                                </Row>
                            )
                        case 'string':
                            return (
                                <Row key={key}>
                                    <Col md={1} className="p-2 my-auto">
                                        <Form.Control
                                            size='xs'
                                            type="text"
                                            name={key}
                                            value={getValFromStringFilter(val)}
                                            onChange={e => handleChangeFilterString(e)}
                                            placeholder={translate(tableFilters[key].name)}/>
                                    </Col>
                                    <Col className="p-1  my-auto">
                                        <Button
                                            variant="danger"
                                            onClick={() => delFilterElement(key)}
                                            className="ml-2 btn-xs"><i
                                            className="fas fa-times pr-1"/>{translate('Delete this filter')}</Button>
                                    </Col>
                                </Row>
                            )
                        case 'integer':
                            return (
                                <Row key={key}>
                                    <Col md={1} className="p-2 my-auto">
                                        <Form.Control
                                            size='xs'
                                            type="number"
                                            name={key}
                                            value={val}
                                            onChange={e => handleChangeFilterSelect(e)}
                                            placeholder={translate(tableFilters[key].name)}/>
                                    </Col>
                                    <Col className="p-1  my-auto">
                                        <Button
                                            variant="danger"
                                            onClick={() => delFilterElement(key)}
                                            className="ml-2 btn-xs"><i
                                            className="fas fa-times pr-1"/>{translate('Delete this filter')}</Button>
                                    </Col>
                                </Row>
                            )
                        case 'select':
                            return (
                                <Row key={key}>
                                    <Col md={1} className="p-2 my-auto">
                                        <Form.Control
                                            size={"xs"}
                                            as="select"
                                            value={val}
                                            name={key}
                                            onChange={e => handleChangeFilterSelect(e)}>
                                            <option key='' value=''>{translate('choose')}</option>
                                            {
                                                tableFilters[key]['dir'].map(dir =>
                                                    <option
                                                        key={dir[0]}
                                                        value={dir[0]}
                                                    >{translate(dir[1])}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Col>
                                    <Col className="p-1  my-auto">
                                        <Button
                                            variant="danger"
                                            onClick={() => delFilterElement(key)}
                                            className="ml-2 btn-xs"><i
                                            className="fas fa-times pr-1"/>{translate('Delete this filter')}</Button>
                                    </Col>
                                </Row>
                            )
                        case 'object':
                            return (
                                <Row key={key}>
                                    <Col md={1} className="p-2 my-auto">
                                        <Form.Control
                                            size={"xs"}
                                            as="select"
                                            value={val}
                                            name={key}
                                            onChange={e => handleChangeFilterSelect(e)}>
                                            <option key='' value=''>{translate('choose')}</option>
                                            {
                                                Object.entries(reduxData[key]).map(([keys,vals]) =>
                                                    <option
                                                        key={keys}
                                                        value={keys}
                                                    >{vals[tableFilters[key]["showField"]]}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Col>
                                    <Col className="p-1  my-auto">
                                        <Button
                                            variant="danger"
                                            onClick={() => delFilterElement(key)}
                                            className="ml-2 btn-xs"><i
                                            className="fas fa-times pr-1"/>{translate('Delete this filter')}</Button>
                                    </Col>
                                </Row>
                            )
                    }

                }


            })}

        </>


    );

}
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import siteLanguages from "../../../utils/sitelanguages.json";

class LangSet extends Component {

    componentDidMount() {
        const {i18n} = this.props;
        if (i18n.language.match(/[a-zA-Z]{2}[-_][a-zA-Z]/)) {
            i18n.changeLanguage( i18n.language.substr(0,2), (err, t) => {
                if (err) return console.log('something went wrong loading', err);
            });
        }
    }

    onLanguageChange = (event) => {
        const {i18n} = this.props;
        i18n.changeLanguage(event.target.value, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    };

    render() {
        const {i18n} = this.props;
        return (
                <Form.Control  as="select"  size="xs" custom value={i18n.language} onChange={this.onLanguageChange}>
                    {
                        Object.keys(siteLanguages).map(lang =>
                            <option value={lang}
                                    key={lang}>{siteLanguages[lang]['nativeName']} </option>
                        )
                    }
                </Form.Control>
        )
    }
}



export default withTranslation()(LangSet);
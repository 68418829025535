import React  from 'react'
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SettingTable from "../../modal-elements/setting-table";
import BaseModal from "../base-modal";
import LicenseTable from "../../modal-elements/license-table";
import {adminGetAllGroups} from "../../../actions";
import JsonModal from '../modal-add-edit-benchmark/json-modal'


class ModalAddEditCompany extends BaseModal {

  state = {
    showModal: false,
    tempVal: '',
  }

   updateState = (name) => (state) => {
     this.setState({[name]: state});
  }

  render() {

        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {name, defaultCompanySettings, useMigration} = data;


        const {
            t: translate,
            openData: {licenses},
            adminData: {options}
        } = this.props;

        console.log('render modal ModalAddEditCompany', this.props, this.state);

        const settingsType = Object.keys(options).filter(v => options[v].level.indexOf('company') > -1).reduce((acc, key) => {
            acc[key] =  options[key].type
            return acc
        }, {})

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Control
                                type="text"
                                name="name"
                                defaultValue={name}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder={translate("Enter name")}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <LicenseTable
                            multiple={true}
                            {...this.props}
                            licenses={licenses}
                            licenseFieldName={"company_licenses"}
                        />
                    </Col>
                </Row>
                {defaultCompanySettings?.length &&
                    <Row>
                        <Col md={10}>
                            <Form.Group
                              onClick={()=>{
                                this.setState({showModal: true});
                                this.setState({tempVal: defaultCompanySettings});
                              }}
                              controlId="defaultCompanySettings">
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="defaultCompanySettings"
                                    value={defaultCompanySettings}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    placeholder={translate("defaultSettings")}/>
                            </Form.Group>
                        </Col>
                        <Form.Group controlId="useMigration">
                            <Form.Check
                                size="xs"
                                checked={useMigration}
                                onChange={e => this.handleChange(e, 'useMigration', true)}
                                name="useMigration"
                                type="checkbox"
                                label={translate("useMigration")}/>
                        </Form.Group>
                    </Row>
                }
                <h1 className="h5 mb-0 text-gray-800 my-3">{translate("Options")}</h1>
                <SettingTable
                    addText='Add option'
                    settingText='Options'
                    valueText='Value'
                    valuePlaceholder='Add Value'
                    selectText='Select option name'
                    settings={Object.keys(options).filter(v => options[v].level.indexOf('company') > -1 && options[v].is_private === 0)}
                    settingFieldName={"company_options"}
                    settingsType={settingsType}
                    {...this.props}
                />
              <JsonModal
                showModal={this.state.showModal}
                setShowModal={this.updateState('showModal')}
                tempVal={this.state.tempVal}
                setTempVal={this.updateState('tempVal')}
                onChangeSettingValue={e=> this.handleChange(e)}
                translate={translate}
                inputName={'defaultCompanySettings'}
                settingName={'defaultCompanySettings'}
                isUGC
              />
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch, {tlService}) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            adminGetAllGroups: adminGetAllGroups(tlService)
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditCompany);

import React, {Component} from "react";
import {compose} from "../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../hoc";
import {connect} from "react-redux";
import {Col, Row, Accordion, Button, Card} from "react-bootstrap";
import ColorWordSection from "./color-word-sections";
import TermsSection from "./terms-sections";
import DefaultSection from "./default-sections";
import PositiveNegativeSection from "./positive-negative-section";
import PersonalNeutralSection from "./personal-neutral-section";

class StatisticTab extends Component {

    state = {
        showedSetting: '',
        showedKey: ''
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showedSetting, showedKey} = this.state;
        if ((!this.props.resultData || Object.keys(this.props.resultData).length === 0) &&
            (showedSetting || showedKey)) {
            this.setState({showedSetting: '', showedKey: ''})
        }
    }

    haveEnabledElements(valSetting) {
        for (let elem of valSetting) {
            if (elem['values']['enabled']) {
                return true;
            }
        }
        return false;
    }

    async openedSetting(val, key = '') {
        const {showedSetting} = this.state;
        const {runInEditor, restartAnalyse} = this.props;
        await restartAnalyse();
        if (showedSetting !== val + key) {
            runInEditor(val, key);
            this.setState({
                showedSetting: val + key
            })
        }

    }

    accOnClose() {
        const {refreshEditor} = this.props;
        this.setState({
            showedSetting: ''
        })
        refreshEditor();
    }

    downloadAsFile(filename, text, textType = 'text/plain') {
        const pom = document.createElement('a');
        pom.setAttribute('href', 'data:' + textType + ';charset=utf-8,' + encodeURIComponent(text));
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            const event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    }


    downloadResult(resultData) {

        if (resultData['dataTokenizer'] && resultData['dataTokenizer'].length > 0) {
            /*
           const initCLa = {
               startPos: 0,
               stopPos: 0,
               voice: "active",
               words: []
           };

           const initSentence = {
               startPos: 0,
               stopPos: 0,
               clauses: [initCLa]
           };
           */

            let textResult = "<code class=\"xml\">\n";
            for (let key = 0; key < resultData['dataTokenizer'].length; key++) {

                const value = resultData['dataTokenizer'][key];

                if (value['tag'] !== 'SGML_TAG') {

                    if (value['snt'] === "START" || (value['snt'] === "SNT" && value['snt_start'] === key)) {
                        textResult += "<s>\n"
                    }

                    if (value['cla'] === "START" || (value['cla'] === "CLA" && value['snt_start'] === key)) {
                        const claEnd = value['cla_end'];

                        let insideTag = {};

                        // type="finite" tense="present" voice="active"

                        if (resultData['countFutureTenseInSentence'].findIndex(value => value[1] === claEnd) > -1) {
                            insideTag['tense'] = "future";
                        } else {
                            insideTag['tense'] = "present";
                        }

                        if (resultData['countInfinitiveConstructions'].findIndex(value => value[1] === claEnd) > -1) {
                            insideTag['type'] = "infinitive";
                        } else {
                            insideTag['type'] = "finite";
                        }

                        if (resultData['countNominalStyle'].findIndex(value => value[1] === claEnd) > -1) {
                            insideTag['style'] = "nominal";
                        }

                        if (resultData['countPassiveVoiceInSentence'].findIndex(value => value[1] === claEnd) > -1) {
                            insideTag['voice'] = "passive";
                        }

                        const innerString = Object.entries(insideTag)
                            .reduce((accumulator, [kKey, val]) => accumulator + ` ${kKey}="${val}" `, '');

                        textResult += `<clause ${innerString} >\n`;
                    }

                    textResult += `\t${value['word']}\t${value['lemma']}\t${value['tag']}\n`;

                    if (value['cla'] === "CLA") {
                        textResult += "</clause>\n"
                    }
                    if (value['snt'] === "SNT") {
                        textResult += "</s>\n"
                    }
                }
            }
            textResult += "\n</code>\n";

            console.log('textResult', textResult)

            this.downloadAsFile('result.xml', textResult, 'text/xml');
        }
    }

    render() {
        const {
            resultTemplate,
            resultData,
            enabledBenchmarks,
            t: translate,
            adminData: {settings_dir}
        } = this.props;
        const {showedSetting} = this.state;

        if (resultTemplate > 0 && settings_dir && enabledBenchmarks) {

            let resultBySections = {};

            for (const [keySetting, valSetting] of Object.entries(enabledBenchmarks['settings'])) {
                if (resultBySections[valSetting['category']] === undefined)
                    resultBySections[valSetting['category']] = [];

                resultBySections[valSetting['category']].push({
                    ...valSetting,
                    name: keySetting
                });
                //console.log('showedSetting', showedSetting)

            }
            //  console.log('enabledBenchmarks', enabledBenchmarks);
            //  console.log('resultData', resultData);
            // console.log('[settings_dir]', settings_dir);
            //console.log('[resultBySections]', resultBySections);

            if (!resultData || Object.keys(resultData).length === 0)
                return '';

            return (
                <Row>
                    <Col className="p-2">
                        <Row>
                            <Col className="p-2">
                                <Button
                                    onClick={() => this.downloadResult(resultData)}>{translate('Download result as file')}</Button>
                            </Col>
                        </Row>
                        <Accordion>
                            <Card key='huspell_section'>
                                <Accordion.Toggle as={Card.Header}
                                                  onClick={() => this.openedSetting('huspell')}
                                                  eventKey={'huspell'}>
                                    {translate('spelling check')}
                                </Accordion.Toggle>
                                <Accordion.Collapse onExit={e => this.accOnClose(e)}
                                                    eventKey={'huspell'}>
                                    <Card.Body>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {Object.entries(resultBySections).map(([keySetting, valSetting]) => {
                                if (keySetting !== 'formulas_section' && this.haveEnabledElements(valSetting)) {
                                    if (keySetting === 'color_word_sections') {
                                        return <ColorWordSection
                                            key={`section_${keySetting}`}
                                            keySetting={keySetting}
                                            valSetting={valSetting}
                                            showedSetting={showedSetting}
                                            accOnClose={e => this.accOnClose(e)}
                                            openedSetting={(val, key) => this.openedSetting(val, key)}
                                            {...this.props} />
                                    } else if (keySetting === 'terms_sections') {
                                        return <TermsSection
                                            key={`section_${keySetting}`}
                                            keySetting={keySetting}
                                            valSetting={valSetting}
                                            showedSetting={showedSetting}
                                            accOnClose={e => this.accOnClose(e)}
                                            openedSetting={(val, key) => this.openedSetting(val, key)}
                                            {...this.props} />
                                    } else if (keySetting === 'tonality_section') {
                                        return <React.Fragment key={'tonality_section_fragment'}>
                                            <PositiveNegativeSection
                                                key={`section_positive_negative`}
                                                keySetting={'Positive/Negative'}
                                                valSetting={valSetting}
                                                showedSetting={showedSetting}
                                                accOnClose={e => this.accOnClose(e)}
                                                openedSetting={(val, key) => this.openedSetting(val, key)}
                                                {...this.props} />
                                            <PersonalNeutralSection
                                                key={`section_passive_neutral`}
                                                keySetting={'Personal/Neutral'}
                                                valSetting={valSetting}
                                                showedSetting={showedSetting}
                                                accOnClose={e => this.accOnClose(e)}
                                                openedSetting={(val, key) => this.openedSetting(val, key)}
                                                {...this.props} />
                                        </React.Fragment>
                                    } else {
                                        return <DefaultSection
                                            key={`section_${keySetting}`}
                                            keySetting={keySetting}
                                            valSetting={valSetting}
                                            showedSetting={showedSetting}
                                            accOnClose={e => this.accOnClose(e)}
                                            openedSetting={(val, key) => this.openedSetting(val, key)}
                                            {...this.props} />
                                    }
                                }
                                return null;
                            })}
                        </Accordion>
                    </Col>
                </Row>
            );
        } else {
            return (<div></div>);
        }

    }
}

const mapStateToProps = ({userData, openData, adminData}) => {
    return {userData, openData, adminData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(StatisticTab);

import React, {Component} from "react";

import { compose, parseErrorObject } from '../../../utils'
import {withTLService} from "../../hoc";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {Button, Form, Alert} from 'react-bootstrap'
import { clearUser, loginUser, loginUserLDAP, userSetUser } from '../../../actions'
import {Redirect} from "react-router-dom";
import LangSet from "../../elements/lang-set";

import './login.css'

class LoginGA extends Component {

    state = {
        totp: '',
        secret: '',
        submitted: false,
        redirect: '',
        identifier: '',
        attempts: 0,
        alert: ''
    };

    componentDidMount () {
        const {userData: { error }}= this.props;
        if (error) {
            const data2FA = parseErrorObject(error)
            this.setState({alert: ''});
            this.setState({secret: data2FA?.secret});
            this.setState({attempts: data2FA?.attempts});
            this.setState({identifier: data2FA?.identifier});
        } else {
            this.goBack()
        }

    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
        this.setState({submitted: false});
    };

    handleSubmit = async (event) => {
        const {totp, secret, identifier} = this.state;
        event.preventDefault();
        event.stopPropagation();

        try {
            const res = await this.props.tlService.loginGA(String(totp), secret, identifier)
            if (res?.token) {
                this.props.userSetUser(res.token)
            } else {
                this.goBack()
            }
        } catch (e) {
            const error = parseErrorObject(e)
            console.log('error', error)
            this.setState({alert: error.message});
            if (error?.secret)
                this.setState({secret: error.secret});
            if (error?.attempts)
                this.setState({attempts: error.attempts});
        }
    };

    goBack = () =>{
        this.props.clearUser();
        this.setState({redirect: '/login'})
    }

    handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.handleSubmit(event)
        }
    }

     render() {
        const {totp, submitted, redirect, attempts, alert} = this.state;
        const {i18n, t: translate, userData: {token} }= this.props;

        if (token) {
            i18n.reloadResources();
            return <Redirect to="/"/>;
        }

        if (redirect) {
            return <Redirect to={redirect}/>;
        }

        return (
            <div className="container  h-100 my-auto mx-auto">
                <div className="row my-auto  h-100 mx-auto">
                    <div className="col-xl-8 col-lg-9 col-md-7 my-auto  mx-auto">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="p-5">
                                    <div className="ml-auto  col-3 ">
                                        <LangSet {...this.props}/>
                                    </div>
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">{translate('Sign In Form')}</h1>
                                    </div>
                                    {attempts > 0 && <Alert
                                      className={`m-0`}
                                      severity="warning">
                                        <p className={`m-0`}>{translate('2fa_ga_attempts_left {{attempts}}', { attempts })}</p>
                                    </Alert>}
                                    <Form
                                        name="loginForm"
                                        className="user"
                                        onSubmit={this.handleSubmit}>
                                        <Form.Group controlId="loginLogin">
                                            <Form.Control
                                              onKeyUp={this.handleKeyUp}
                                                value={totp}
                                                onChange={this.handleChange}
                                                name="totp"
                                                type="text"
                                                placeholder={translate('totp placeholder')}/>
                                        </Form.Group>
                                        <Button
                                            type="submit"
                                            disabled={submitted ? "disabled" : false}
                                            className="btn btn-primary btn-user btn-block">
                                            {translate('totp button')}
                                        </Button>
                                        <Button
                                          onClick={this.goBack}
                                          type="button"
                                          className="btn btn-secondary btn-user btn-block">
                                            {translate('return button')}
                                        </Button>
                                    </Form>
                                    {alert && <Alert
                                      className={`m-0`}
                                      severity="error">
                                        <p className={`m-0`}>{alert}</p>
                                    </Alert>}
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({userData,openData}) => {
    return {userData,openData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        clearUser: clearUser(),
        userSetUser: userSetUser(tlService),
        loginUser: loginUser(tlService),
        loginUserLDAP: loginUserLDAP(tlService)
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(LoginGA);

import React from "react";
import {withTranslation} from 'react-i18next';
import {
    compose,getAllModalFunctions
} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import BaseModal from "../base-modal";


class ModalUploadTerms extends BaseModal {

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalUploadTerms);
import React from "react";
import {withTranslation} from 'react-i18next';
import {Button} from "react-bootstrap";
import {
    checkUserRight,
    compose,  getAllModalFunctions,
    getDataFromModals
} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import DataViewer from "../../elements/data-viewer";
import ModalAddEditTextbinReplacement from "../modal-add-edit-textbin-replacement";

class ModalTableTexbinReplacement extends DataViewer {

    /*  define count of modal (0 because opened in modal windows)*/
    modalWindowsCount = 0

    tableFilters = {}

    async getAllReplacements (token, limits, filters) {
        if (filters?.replacements?.length){
            const arrData = filters.replacements;
            const data = arrData.map((v,i)=> {return { id:i, word: v}});
            console.log({data, fullcount: data.length });
            return {data, fullcount: data.length };
        } else {
            return {data: [], fullcount: 0 };
        }
    }

    functions = {
        getAll: this.getAllReplacements,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditTextbinReplacement,
        modalEdit: ModalAddEditTextbinReplacement,
        modalView: ModalAddEditTextbinReplacement,
    };

    componentDidMount() {
        const {
            userData: {user},
            t: translate
        } = this.props;

        const {index} = this.props;
        const data = getDataFromModals(this.props, index);

        this.columns_default = [
            {
                name: 'word',
                selector: 'word',
                cell: row => row.word
            },
            {
                name: 'edit',
                cell: row => {
                    return (
                        <div className="btn-group">
                            {checkUserRight(user, [701, 702]) &&
                                (<>
                                    <Button onClick={() => this.openModal(1, row, 'modalDelete')} className="ml-2"><i
                                        className="fas fa-trash"/></Button>
                                </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [701, 702, 703]);

        // check add right
        this.addButton = checkUserRight(user, [701, 702, 703]);
        //this.addButton = false;


        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                id: false,
                word: '',
            },
            additionalData: {
            },
            show: false,
            header: translate('Add Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                word: '',
            },
            additionalData: {
            },
            show: false,
            header: translate('Edit Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Term Replacement'),
            footer: true,
            text: translate("Delete Term Replacement?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Term Categories'),
            footer: true,
            text: translate('Delete Selected Term Categories'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {
                replacements: data.replacements,
                id: data.id,
            },
            limits: {
                limit: 100,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Term Replacement'),
                addNewText: translate('Add new term replacement'),
            }
        });

        super.componentDidMount();

    }

    actionAdd = async (input) => {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const { filters} = this.state;
        const {word} = input;
        if (!word?.length){
            return false;
        }

        let arrData = [];
        if (filters?.replacements?.length) {
            arrData = filters.replacements;
        }
        arrData.push(word);
        this.setState({filters: {
                replacements: arrData,
                id: data.id,
            }});
        this.props.modalUpdateData(index,{replacements: arrData})
        this.getAllData();
        return true;

    }

    actionEdit = async (data) => {
        console.log(data);
        return false;
    }

    actionDelete = async (input) => {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const { filters} = this.state;

        let arrData = [];
        if (filters?.replacements?.length) {
            arrData = filters.replacements;
            arrData = arrData.filter((v,i)=> i !== input.id);
        }
        this.setState({filters: {
                replacements: arrData,
                id: data.id,
            }});
        this.props.modalUpdateData(index,{replacements: arrData})
        this.getAllData();
        return true;
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalTableTexbinReplacement);
import {Alert, Button, Modal} from "react-bootstrap";
import React from "react";
import './modal.css';

const TemplateModal = (props) => {
    const {
        index,
        setErrorFalse, modalClose, modalAction,
        options: {show, header, text, ComponentText , error, footer, closeButton, closeButtonText, saveButton, ok, saveButtonText}
    } = props;
    return (
        <Modal dialogClassName={'wide-modal'}  backdropClassName={"modal-backdrop-" + index} className={"modal-border-" + index} centered key={`modal_windows_${index}`} size="xl" show={show} onHide={() => modalClose()} animation={false}>
            {header && <Modal.Header>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>}
            {(text || ComponentText || error) && <Modal.Body>
                {error && show &&
                <Alert variant="danger" onClose={() => setErrorFalse()} dismissible>
                    <p className="mx-auto text-center">{error}</p>
                </Alert>}
                { ComponentText ? <ComponentText index={index} /> : text }
            </Modal.Body>}
            {footer && <Modal.Footer>
                {closeButton && <Button variant="secondary" onClick={() => modalClose()}>
                    {closeButtonText}
                </Button>}
                {saveButton && <Button variant="primary" disabled={!ok} onClick={() => modalAction()}>
                    {saveButtonText}
                </Button>}
            </Modal.Footer>}
        </Modal>
    );
}

export default TemplateModal


import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Alert, Button, Col, Form, ProgressBar, Row} from "react-bootstrap";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {calculateSize, clearCache, downloadTerms, uploadTerms} from './upload-file-function'
import Dnd from "../../elements/dnd/dnd";
import {isObjectEmpty} from "../../../utils";


const DataViewerTermCategoryHeader = (props) => {
    const {headerData: {category: {name, id: category_id}, refreshFunc}, page: {pageHeader}, lang} = props.state;
    const {t , tlService, userData: {token}} = props;

    const [downloadFormat, setDownloadFormat] = useState("XLSX");
    const [downloadBarVisible, setDownloadBarVisible] = useState(false);
    const [downloadBarNow, setDownloadBarNow] = useState(0);
    const [uploadBarVisible, setUploadBarVisible] = useState(false);
    const [uploadBarNow, setUploadBarNow] = useState(0);
    const [uploadBarText, setUploadBarText] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const [returnResult, setReturnResult] = useState(true);
    const [uploadError, setUploadError] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const [uploadAlert, setUploadAlert] = useState(false);

    // simple xml
    const [checkWords, setCheckWords] = useState(true);

    const options = {
        title: t('Are you sure want to upload?'),
        message: t('All data in Category will be REMOVED!'),
        buttons: [
            {
                label: t('Run'),
                onClick: async () => {
                    setUploadError('');
                    await uploadTerms(token, category_id, tlService, lang, uploadFile,  setUploadError,
                        setUploadBarVisible, setUploadBarNow, setUploadBarText, setUploadFile, refreshFunc, returnResult, setDisableButtons, t);
                    setUploadFile(null);
                }
            },
            {
                label: t('Back')
            }
        ],
        childrenElement: () => <div/>,
        closeOnEscape: true,
        closeOnClickOutside: true
    };

    useEffect(() => {
        if (!isObjectEmpty(uploadFile) && uploadAlert){
            setUploadAlert(false);
            confirmAlert(options);
        }
    }, [uploadFile, uploadAlert])

    return (
        <Row>
            <Col md={1} className="mr-2"><Link to="/terms"><i className="fas fa-arrow-left"/></Link></Col>
            <Col md={3}>
                <h1 className="h3 mb-0 text-gray-800">{pageHeader}</h1>
            </Col>
            <Col>({category_id} {name[lang]} )</Col>
            <Col className="col-6">
                <Row>
                    <Col className="pr-1 ">
                        <Button disabled={disableButtons} className="btn btn-xs btn-info h-auto" onClick={() =>calculateSize(token, category_id, tlService)}>
                            {t('Recalculate size')}
                        </Button>
                    </Col>
                    <Col className="pr-1">
                        <Button disabled={disableButtons} className="btn btn-xs btn-primary h-auto" onClick={() =>clearCache(token,category_id, tlService)}>
                            {t('Clear Cache')}
                        </Button>
                    </Col>
                    <Col className="pr-1">
                        <Button disabled={disableButtons} className="btn btn-xs btn-secondary h-auto" onClick={() => downloadTerms(tlService, token,
                            category_id, setDownloadBarVisible, setDownloadBarNow, downloadFormat, checkWords)}>
                            {t('Download Category')}
                        </Button>
                        {downloadBarVisible ?
                            <div className="pt-1 mt-1">
                                <ProgressBar now={downloadBarNow} variant="warning striped"/>
                            </div>
                        :
                            <>
                            <div className="pt-1 mt-1">
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    className="btn-xs"
                                    onChange={e => setDownloadFormat(e.target.value)}
                                    value={downloadFormat}
                                >
                                    <option value="XLSX">XLSX</option>
                                    <option value="CSV">CSV</option>
                                    <option value="SimpleXLSX">SimpleXLSX</option>
                                    <option value="SimpleCSV">SimpleCSV</option>
                                </Form.Control>
                            </div>
                                {(downloadFormat === 'SimpleXLSX' || downloadFormat === 'SimpleCSV') &&
                                    <div className="font-smaller text-nowrap"><input disabled={disableButtons} type="checkbox" checked={checkWords} onChange={() => setCheckWords(i => !i)} /> {t('checkwords')} </div>
                                }

                            </>
                        }
                    </Col>
                    <Col className="pr-1">
                        <Dnd disabled={disableButtons}  text={t('Upload Category')}  onReadDropFiles={
                            acceptedFiles => {
                                if (!isObjectEmpty(acceptedFiles)){
                                    setUploadBarNow(0);
                                    setUploadBarText('0/0');
                                    setUploadFile(acceptedFiles[0]);
                                    setUploadAlert(true);
                                }
                            }}/>
                        {uploadBarVisible ?
                            <div className="pt-1 mt-1">
                                <ProgressBar now={uploadBarNow} variant="warning striped"/> ({uploadBarText})
                            </div>
                            :
                            <div/>
                        }
                    </Col>
                    <Col className="pr-1">
                        <Row>
                            <Col disabled={disableButtons} className="font-smaller text-nowrap"><input type="checkbox" checked={returnResult} onChange={() => setReturnResult(i => !i)} /> {t('save result to file')} </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        {uploadError && <Alert variant="danger" onClose={() => setUploadError(false)} dismissible>
                            <div className="font-smaller mx-auto text-center"><pre>{uploadError}</pre></div>
                        </Alert>}
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}

export default DataViewerTermCategoryHeader;
import React from 'react';
import {checkUserRight, compose, convertFromErrorObject, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {adminGetAllCompanies, adminGetAllOptions} from "../../../actions";
import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import ModalAddEditAllowlist from "../../modals/modal-add-edit-allowlist";
import ModalTableTextbinReplacement from "../../modals/modal-table-textbin-replacement";

class TextBins extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        words: {
            name: 'Filter by words',
            type: 'string',
            default: undefined
        },
        lemma: {
            name: 'Filter by lemmas',
            type: 'string',
            default: undefined
        },
        id: {
            name: 'Term id',
            type: 'integer',
            default: undefined
        },
        description: {
            name: 'Term description',
            type: 'string',
            default: undefined
        },
        term_category_id: {
            name: 'List category id',
            type: 'integer',
            default: undefined
        },
        state: {
            name: 'state',
            type: 'select',
            dir: [
                [0,'Pending'],
                [1,'Approved'],
                [2,'Disapproved']
            ],
            default: 0
        }
    }

    functions = {
        getAll: this.props.tlService.getFilteredTextBins,
        massDelete: data => this.actionMassDelete(data),
        massApprove: data => this.actionMassApprove(data),
        approveAll: data => this.actionApproveAll(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditAllowlist,
        modalEdit: ModalAddEditAllowlist,
        modalView: ModalAddEditAllowlist,
        modalReplacement: ModalTableTextbinReplacement,
    };

    async componentDidMount() {
        const {
            userData: {user},
            t: translate
        } = this.props;

        const status = [translate('Pending'),translate('Approved'),translate('Disapproved')];
        /* get category */
        this.setState({filters: this.props.match.params})
        const category = await this.getCategory(this.props.match.params.category_id)
        if (category) {
            this.setState({headerData: {category, refreshFunc: () => this.getAllData()}})
        }

        /* default table columns config */
        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'term_category_name',
                selector: 'term_category_name',
                cell: row => row.term_category_name[this.props.i18n.language]
            },
            {
                name: 'check_words',
                selector: 'check_words',
                cell: row => row.check_words === 1 ? 'by word' : 'by lemma'
            },
            {
                name: 'words',
                selector: 'words',
                cell: row => row.words
            },
            {
                name: 'lemma',
                selector: 'lemma',
                cell: row => row.lemma
            },
            {
                name: 'state',
                selector: 'state',
                cell: row => status[row.state] !== undefined ? status[row.state] : row.state
            }
        ];

        this.columns_default.push({
            name: 'Term Replacement',
            selector: 'replacements',
            cell: row => {
                  return (<>
                    {row?.replacements?.length}
                    <>
                        <Button 
                        onClick={() => this.openReplacementModal(row.id)}
                            className="ml-2"><i
                            className="fas fa-bars"/></Button>
                    </>
                </>)
            }
        });

        this.columns_default.push({
            name: 'edit',
            cell: row => {
                return (
                    <div className="btn-group">
                        {checkUserRight(user, []) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalApprove')} className="ml-2"><i
                                    className="fas fa-check"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                        }
                    </div>
                )
            }
        });

        // check for enable page
        this.renderIt = checkUserRight(user, []);

        // check add right
        this.addButton = false

        //this.props.adminGetAllCompanies(token);
        //this.props.adminGetAllOptions(token);

        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Add Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Edit Entry'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalApprove'] = {
            action: this.actionApprove,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Approve term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Approve'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('View Group'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Entry'),
            footer: true,
            text: translate('Delete Entry'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Text tone'),
            footer: true,
            text: translate('Delete Selected Text tone'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassApprove'] = {
            action: this.actionDoMassApprove,
            data: {},
            show: false,
            header: translate('Approve Selected Terms'),
            footer: true,
            text: translate('Approve Selected Terms'),
            saveButton: true,
            saveButtonText: translate('Approve'),
            closeButton: true,
            closeButtonText: translate('Approve'),
            ok: true
        }


        /* config modal windows */
        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
                locale_name: category.locale_name
            },
            show: false,
            header: translate('Add Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Edit Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('View Term'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Delete Term'),
            footer: true,
            text: translate("Delete Term?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalReplacement'] = {
            action: this.actionReplacement,
            data: {
                id: false,
                replacements: '',
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
            },
            show: true,
            header: translate('Edit Replacement For Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Terms'),
            footer: true,
            text: translate('Delete Selected Terms'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalDeleteAll'] = {
            action: this.actionDoDeleteAll,
            data: {},
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Delete All Terms'),
            footer: true,
            text: translate('Delete All Terms'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.modalSettings['modalUploadTerms'] = {
            action: this.actionUpload,
            data: {},
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Upload Terms'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        
        this.setState({
            filters: {
                state: 0
            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('textbins'),
                addNewText: translate('Add new Text tone'),
            }
        });
        super.componentDidMount();
    }

    actionMassApprove(data) {
        if (data.length > 0)
            this.openModal(this.props.index !== undefined ? this.props.index + 1 : 0, data, 'modalMassApprove')
    }

    actionDoMassApprove = async (data) => {
        for (let id of data) {
            await this.actionApprove(id)
        }
        this.getAllData();
        return true;
    }

    async getCategory(id) {
        const {
            tlService,
            userData: {token}
        } = this.props;
        let category;
        try {
            category = await tlService.getFilteredTermCategories(token, {}, {id: id})
        } catch (e) {
            category = false;
        }
        if (category && category.data && category.data[0]) {
            return category.data[0]
        }
        return false;
    }

    actionReplacement = async (data) => {
        //const {
        //    tlService,
        //    userData: {token},
        //} = this.props;
        //const {t} = this.props;

        // here code
        console.log('data',data);
        return this.actionEdit(data)



       // this.getAllData();
       // return true;
    }

    actionAdd = async (data) => {
        return true;
    }

    actionApprove = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;
        let words, lemma, tag, description;

            try {
                words = typeof data.words === 'string' ? JSON.parse(data.words) : data.words;
                lemma = typeof data.lemma === 'string' ? JSON.parse(data.lemma) : data.lemma;
                tag = typeof data.tag === 'string' ? JSON.parse(data.tag) : data.tag;
            } catch (e) {
                words = [];
                lemma = [];
                tag = [];
            }

        let res;

        try {
            res = await tlService.addTermInCategory(token, data.term_category_id,
                {
                    words,
                    lemma,
                    tag,
                    check_words: !!data.check_words ? 1 : 0,
                    description: data?.description?.length ? data.description : ""
                });
            await tlService.clearAllCache(token, 1, parseInt(data.term_category_id));
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }

        let replacementRes;

        let arrayIs = data.replacements.toString().split('|');

        arrayIs = arrayIs.filter(el => el !== '')
        
        arrayIs?.length && arrayIs.map(async element => {
                    try {
                        replacementRes = await tlService.getTreeTager(token, this.props.i18n.language === "de" ? "de_DE" : "en_GB", element);
                        let wordIs = [];
                        let lemmaIs = [];
                        let tagIs = [];

                        replacementRes?.dataTokenizer && replacementRes.dataTokenizer.map(async el => {
                            wordIs.push(el.word);
                            lemmaIs.push(el.lemma);
                            tagIs.push(el.tag)
                        })

                        await tlService.addTermReplacementInCategory(token, data.term_category_id, res.data.id,
                            {
                                words : wordIs,
                                lemma : lemmaIs,
                                tag : tagIs,
                                description: description,
                                global_visible: 1
                            });
                        await tlService.clearAllCache(token, 1, parseInt(data.term_category_id));
                    } catch (e) {
                        console.log('error ' + convertFromErrorObject(t,e));
                        this.setState({error: convertFromErrorObject(t,e)});
                        return false;
                    }
        })

        try {
            await tlService.updateTextTone(token, data.id,
                {
                    state: 1,
                });

        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    actionEdit = async (data) => {
        console.log("actionEdit",data);
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;
        let words, lemma, tag, description;

        try {
            words = typeof data.words === 'string' ? JSON.parse(data.words) : data.words;
            lemma = typeof data.lemma === 'string' ? JSON.parse(data.lemma) : data.lemma;
            tag = typeof data.tag === 'string' ? JSON.parse(data.tag) : data.tag;
        } catch (e) {
            words = [];
            lemma = [];
            tag = [];
        }


        try {
            await tlService.updateTextTone(token, data.id,
                {
                    replacements: data.replacements || "",
                    lemma: lemma,
                    check_words: parseInt(data.check_words),
                    tag: tag,
                    words: words,
                    description: description
                });

        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    actionDelete = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.deleteTextTone(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    openReplacementModal(id) {
        const {rows} = this.state;
        this.openModal(this.props.index !== undefined ? this.props.index + 1 : 0, {
            ...rows.find(element => element.id === id),
            category: this.modalSettings['modalReplacement'].data.category
        }, 'modalReplacement')
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(TextBins);

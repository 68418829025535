import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { getDataFromModals, isObjectEmpty, isObjectsEqual } from '../../utils'
import JsonModal from '../modals/modal-add-edit-benchmark/json-modal'
/*
<SettingTable
    addText='Add setting'
    settingText='Settings'
    valueText='Value'
    valuePlaceholder='Add Value'
    selectText='Select setting name'
    settings={config['termSettings']}
    settingFieldName={"settings"}
    {...this.props}
/>
*/
export default function SettingTable (props) {

  const {
    t: translate,
    addText,
    settingText,
    valueText,
    selectText,
    settings,
    settingsType,
    index,
    settingFieldName,
    modalUpdateData,
    valuePlaceholder
  } = props
  const [tmpSettings, setTmpSettings] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [tempVal, setTempVal] = useState('')
  const [inputName, setInputName] = useState('')
  const [settingName, setSettingName] = useState('')
  const data = getDataFromModals(props, index)

  // get licenses on component mount

  let newSettings = []
  if (!isObjectEmpty(data[settingFieldName])) {
    try {
      const objSetting = typeof data[settingFieldName] === 'object' ? data[settingFieldName] : JSON.parse(data[settingFieldName])
      newSettings = Object.keys(objSetting).map((setting) => {
        return { setting, value: objSetting[setting] }
      })
    } catch (e) {
      newSettings = []
    }
  }

  if (!isObjectsEqual(tmpSettings, newSettings)) {
    setTmpSettings(newSettings || [])
  }

  const handleChangeSetting = (event, json = false) => {
    if(!json){
      event.preventDefault()
    }
    let newTmpSettings = [...tmpSettings]

    const { name, value } = event.target

    if (value) {
      const foundSetting = name.match(/setting_(\d+)/)
      if (foundSetting) {
        newTmpSettings[parseInt(foundSetting[1])].setting = value
        const settName = newTmpSettings[parseInt(foundSetting[1])].setting
        if (settingsType && settingsType[settName] !== undefined) {
          if (settingsType[settName] === 'boolean') {
            newTmpSettings[parseInt(foundSetting[1])].value = false
          }
        }
      }

      const foundValue = name.match(/value_(\d+)/)
      if (foundValue) {
        const settName = newTmpSettings[parseInt(foundValue[1])].setting
        if (settingsType && settingsType[settName] !== undefined) {
          if (settingsType[settName] === 'integer')
            newTmpSettings[parseInt(foundValue[1])].value = parseInt(value)
          else if (settingsType[settName] === 'number')
            newTmpSettings[parseInt(foundValue[1])].value = parseFloat(value)
          else if (settingsType[settName] === 'boolean')
            newTmpSettings[parseInt(foundValue[1])].value = !newTmpSettings[parseInt(foundValue[1])].value
          else
            newTmpSettings[parseInt(foundValue[1])].value = value
        } else {
          newTmpSettings[parseInt(foundValue[1])].value = value
        }
      }
      setTmpSettings(newTmpSettings)
      saveToSetting(newTmpSettings)
    }
    if (!json) {
      const pointer = event.target.selectionStart
      const element = event.target
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer
        element.selectionEnd = pointer
      })
    }
    
  }

  const addSetting = () => {
    const newTmpSettings = tmpSettings.concat([{ setting: '', value: '' }])
    saveToSetting(newTmpSettings)
  }

  const deleteSetting = (index) => {
    let newTmpSettings = [...tmpSettings]
    newTmpSettings.splice(index, 1)
    saveToSetting(newTmpSettings)
  }

  const saveToSetting = (insertData = undefined) => {
    const newTmpSettings = insertData || tmpSettings
    modalUpdateData(index, {
      [settingFieldName]: newTmpSettings.reduce((accumulator, currentValue) => {
        accumulator[currentValue.setting] = currentValue.value
        return accumulator
      }, {})
    })
  }

  //console.log("tmpSettings", tmpSettings)
  const RenderValue = (index, value, valuePlaceholder, type, setting) => {
    switch (type) {
      case 'boolean':
        return (
          <Form.Group controlId={'value_' + index}>
            <Form.Control
              size={'xs'}
              type="checkbox"
              name={'value_' + index}
              checked={value}
              value="checkbox"
              onChange={handleChangeSetting}
              placeholder={translate(valuePlaceholder)}/>
          </Form.Group>
        )
      case 'object':
      case 'array':
        const tmpValue = typeof value == 'object' ? JSON.stringify(value) : value
        return (
          <Form.Group controlId={'value_' + index} onClick={() => {
            setShowModal(true)
            setTempVal(tmpValue)
            setInputName('value_' + index)
            setSettingName(setting)
          }}>
            <Form.Control
              size={'xs'}
              type="text"
              name={'value_' + index}
              value={tmpValue}
              onChange={handleChangeSetting}
              placeholder={translate(valuePlaceholder)}/>
          </Form.Group>
        )
      default:
        return (
          <Form.Group controlId={'value_' + index}>
            <Form.Control
              size={'xs'}
              type="text"
              name={'value_' + index}
              value={value}
              onChange={handleChangeSetting}
              placeholder={translate(valuePlaceholder)}/>
          </Form.Group>
        )
    }
  }

  return (
    <Row>
      <Col>
        <Row>
          <Col md={4}><Form.Label>{translate(settingText)}</Form.Label></Col>
          <Col md={7}><Form.Label>{translate(valueText)}</Form.Label></Col>
        </Row>
        {
          tmpSettings.map((opt, index) => {
            const { setting, value } = opt

            const settName = tmpSettings[index]?.setting
            let type = 'string'
            if (settingsType && settingsType[settName] !== undefined) {
              type = settingsType[settName]
            }

            const filteredSettings = settings.filter(v => tmpSettings.findIndex(el => (el.setting === v && el.setting !== setting)) === -1)

            return (
              <Row key={index}>
                <Col md={4}>
                  <Form.Group controlId={'setting_' + index}>
                    <Form.Control
                      size={'xs'}
                      as="select"
                      value={setting}
                      name={'setting_' + index}
                      onChange={handleChangeSetting}>
                      <option key="" value="">{translate(selectText)}</option>
                      {
                        filteredSettings?.length > 0 && filteredSettings.map((key) => {
                          return (
                            <option key={key} value={key}>{translate(key)}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={7}>
                  {RenderValue(index, value, valuePlaceholder, type, setting)}
                </Col>
                <Col md={1}>
                  <Button
                    onClick={() => deleteSetting(index)}
                    className="ml-2d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm">
                    <i className="fas fa-trash fa-sm"/>
                  </Button>
                </Col>
              </Row>

            )
          })
        }
        <Row>
          <Col>
            <Button
              onClick={() => addSetting()}
              className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm"><i
              className="fas fa-plus fa-sm text-white-50 mr-1"/>{translate(addText)}
            </Button>
          </Col>
        </Row>
      </Col>
      <JsonModal
        showModal={showModal}
        setShowModal={setShowModal}
        tempVal={tempVal}
        setTempVal={setTempVal}
        onChangeSettingValue={handleChangeSetting}
        translate={translate}
        inputName={inputName}
        settingName={settingName}
        isUGC
      />
    </Row>
  )

}


import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    deepCopy,
    getDataFromModals
} from '../../../utils'
import JsonModal from "./json-modal"


export default function SectionTab(props) {

    const {
        t: translate,
        tSectionKey, tSectionVal, setState, defaultSectionSet,
        state: {tSectionAdd},
        userData: {termCategories,user},
        i18n: {language},
        adminData: {settings_dir}
    } = props;
    const {index} = props;
    const data = getDataFromModals(props, index);
    const {settings, locale_name} = data;


    const [invalidValues, setInvalidValues] = useState({});
    const [values, setValues] = useState({});
    //const [tabsSetting, setTabsSetting] = useState('first');
    const [inputName, setInputName] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [tempVal, setTempVal] = useState("");
    const [settingType, setSettingType] = useState("")

    const ids= ["enabled", "scaleFrom", "scaleTo", "target", "yellowGreen", "yellowRed", "clix", "threshold"]

    useEffect(()=>{
        // eslint-disable-next-line array-callback-return
        Object.entries(tSectionVal).map(([tElementKey, tElementVal]) => {
            if (tElementKey !== 'all_values') {
                if (settings[tElementKey] !== undefined) {
                    // eslint-disable-next-line array-callback-return
                    tSectionVal['all_values'].map(valueKey => {
                        if (tElementVal[valueKey]  !== undefined){
                            setValues(s => {
                                return {...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: settings[tElementKey]['values'][valueKey]}
                            })
                        } else  if (settings_dir[tElementKey]?.values && settings_dir[tElementKey].values[valueKey] !== undefined) {
                            setValues(s => {
                                return {...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: settings_dir[tElementKey].values[valueKey][2]}

                            });
                            settings[tElementKey]['values'][valueKey] = settings_dir[tElementKey].values[valueKey][2];
                        }
                    })
                }
            }
        })

    },[])

    const addSetting = () => {
        const {tSectionAdd} = props.state;
        const newSettings =  deepCopy(settings);

        const {index} = props;


        if (defaultSectionSet[tSectionAdd] !== undefined) {
            let newSection = deepCopy(defaultSectionSet[tSectionAdd]);
            for (const [tempKey, tempVal] of Object.entries(defaultSectionSet[tSectionAdd]['values'])) {
                newSection['values'][tempKey] = tempVal[2];
            }

            if (newSettings[tSectionAdd] === undefined)
                newSettings[tSectionAdd] = {};
            newSettings[tSectionAdd] = newSection;
            props.modalUpdateData(index, {
                settings: newSettings
            });
        }

    };

    const onSectionAddChange = (event) => {
        const {value} = event.target;
        setState({tSectionAdd: value});
    }

    const deleteSetting = (ind) => {
        const [, tElementKey] = ind.split('|');

        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        let tSetting = deepCopy(settings);
        delete tSetting[tElementKey];

        props.modalUpdateData(index, {
            settings: tSetting
        });

    };
    const onChangeSettingValue = (event, type) => {
        const {value, name} = event.target;
        const [tSectionKey, tElementKey, valueKey] = name.split('|');

        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        let tSetting = deepCopy(settings);
        let validationError = false;
        if (type === 'boolean')
            setValues(s=>{ return{...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: !s[`${tSectionKey}|${tElementKey}|${valueKey}`]}});
        else
            setValues(s=>{ return{...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: value}});

        switch (type) {
            case 'array':
                let tVal = value;
                if(typeof tVal !== 'object'){
                    try {
                        tVal = JSON.parse(value);
                    } catch(e) {
                        tVal = []
                        validationError = true;
                    }
                }
                tSetting[tElementKey]['values'][valueKey] = tVal;
                break;
            case 'object':
                let tValObj = value
                if (typeof tValObj !== 'object'){
                    try {
                    tValObj = JSON.parse(value);                     
                    } catch (e) {
                        tValObj = {};
                        validationError = true
                    }
                }
                tSetting[tElementKey]['values'][valueKey] = tValObj;
                break;
            case 'integer':
                validationError = isNaN(parseInt(value));
                tSetting[tElementKey]['values'][valueKey] =  parseInt(value);
                break;
            case 'category':
                validationError = isNaN(parseInt(value));
                tSetting[tElementKey]['values'][valueKey] = parseInt(value);
                break;
            case 'number':
                validationError = isNaN(parseFloat(value));
                tSetting[tElementKey]['values'][valueKey] = parseFloat(value);
                break;
            case 'string':
                validationError = !value?.length;
                tSetting[tElementKey]['values'][valueKey] = value;
                break;
            case 'enum':
                tSetting[tElementKey]['values'][valueKey] = value;
                break;
            case 'boolean':
                tSetting[tElementKey]['values'][valueKey] = !tSetting[tElementKey]['values'][valueKey];
                break;
            default:
                break;
        }
        setInvalidValues(s=>{
            return {
                ...s,
            [`${tSectionKey}|${tElementKey}|${valueKey}`]: validationError
            }
        })
        props.modalUpdateData(index, {
            settings: tSetting
        });
    }


    const hasAddButton = [];
    for (const tElementKey of Object.keys(tSectionVal)){
        if (tElementKey !== 'all_values' && settings[tElementKey] === undefined) {
            const langList = settings_dir[tElementKey]?.settings?.languages || []
            if (langList.length === 0 || langList.indexOf(locale_name) > -1) {
                hasAddButton.push(tElementKey)
            }
        }
    }

    return (<>
        <table className="table table-bordered table-striped table-sm">
            <thead>
            <tr>
                <th key='_'>&nbsp;</th>
                {tSectionVal['all_values'].map(valueKeys => {
                 
                        if (ids.indexOf(valueKeys) === -1){
                            return null;
                        }

                        return <th key={valueKeys}
                                   className="font-smaller text-center my-auto py-1">{translate(valueKeys)}</th>
                    }
                )}
                <th className="font-smaller text-center my-auto py-1"
                    key='_edit_'>{translate('edit')}</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(tSectionVal).map(([tElementKey, tElementVal]) => {
                if (tElementKey !== 'all_values') {
                    if (settings[tElementKey] !== undefined) {
                        return (
                            <tr key={'tr_' + tElementKey}>
                                <td key={tElementKey}
                                    className="font-smaller my-auto">{translate(tElementKey)}</td>
                                {tSectionVal['all_values'].map(valueKey => {
                                   
                                        if (ids.indexOf(valueKey) === -1){
                                            return null;
                                        }
                                        const tempValue = values[`${tSectionKey}|${tElementKey}|${valueKey}`] !== undefined ?  values[`${tSectionKey}|${tElementKey}|${valueKey}`] : '';
                                    if (tElementVal[valueKey] !== undefined) {
                                        switch (tElementVal[valueKey]) {
                                                case 'array':
                                                 let tVal = tempValue
                                                 if(typeof tempValue === 'object'){
                                                    tVal = JSON.stringify(tempValue)
                                                 }
                                                return <td key={valueKey}
                                                    className="font-smaller my-auto py-2 px-1"
                                                    onClick={() => {
                                                        setInputName(`${tSectionKey}|${tElementKey}|${valueKey}`)
                                                        setShowModal(true)
                                                        setTempVal(tempValue)
                                                        setSettingType('array')
                                                    }}
                                                >
                                                        <Form.Control
                                                        as="input"
                                                        rows={3}
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        size="xs"
                                                        value={tVal}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                
                                                case 'object':
                                                 let tValObj
                                                 if(typeof tempValue === 'object'){
                                                    tValObj = JSON.stringify(tempValue)
                                                 }
                                                    return <td key={valueKey}
                                                                className="font-smaller my-auto py-2 px-1"
                                                                onClick={() => {
                                                                    setInputName(`${tSectionKey}|${tElementKey}|${valueKey}`)
                                                                    setShowModal(true)
                                                                    setTempVal(tempValue)
                                                                    setSettingType('object')
                                                                }}
                                                    >
                                                        <Form.Control
                                                            as="input"
                                                            rows={3}
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            size="xs"
                                                            value={tValObj}
                                                            placeholder={translate(valueKey)}/>
                                                    </td>;

                                                case 'integer':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'integer')}
                                                        size="xs"
                                                        type="integer"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'string':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'string')}
                                                        size="xs"
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'category':
                                                    if (user.user_type_id !== 1)
                                                        return <td/>;

                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        size="xs"
                                                        as="select"
                                                        value={tempValue}
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'category')}
                                                    >
                                                        {Object.keys(termCategories).map(val => {
                                                            return <option key={val}
                                                                           value={val}>{termCategories[val].name[language]}</option>
                                                        })}
                                                    </Form.Control></td>;
                                                case 'number':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'number')}
                                                        size="xs"
                                                        htmlSize={"30"}
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'boolean':
                                                    return <td key={valueKey}
                                                               className="font-smaller text-center my-auto py-2 px-1"
                                                    ><Form.Check

                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'boolean')}
                                                        checked={tempValue}
                                                        type="checkbox" label=''/></td>;
                                                case 'enum':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        size={"xs"}
                                                        as="select"
                                                        value={tempValue}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'enum')}
                                                    >
                                                        {Object.entries(settings_dir[tElementKey]['limits'][valueKey]).map(([a, ]) => {
                                                            return <option key={a}
                                                                           value={a}>{translate(a)}</option>
                                                        })}
                                                    </Form.Control></td>;
                                                default:
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'string')}
                                                        size="xs"
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                            }
                                        } else {
                                            return <td key={valueKey}/>;
                                        }

                                    }
                                )}
                                <td key={"_edit_"}
                                    style={{verticalAlign:"middle"}}
                                    className="font-smaller text-center my-auto py-2 pl-2">
                                    {!defaultSectionSet[tElementKey]?.settings?.protected ? <Button size={"xs"} onClick={() => deleteSetting(`${tSectionKey}|${tElementKey}`)}><i
                                        className="fas fa-trash"/></Button>: <i title={translate('this value is mandatory')} style={{color: "green"}} className="text-center my-auto fa fa-exclamation-triangle" /> }

                                </td>
                            </tr>
                        );
                    }
                }
                return null;
            })}
            </tbody>
        </table>

        <table className="table table-bordered table-striped table-sm">
            <thead>
            <tr>
                <th key='_'>&nbsp;</th>
                {tSectionVal['all_values'].map(valueKeys => {
                        if (ids.indexOf(valueKeys) > -1){
                            return null;
                        }
                    

                        return <th key={valueKeys}
                                   className="font-smaller text-center my-auto py-1">{translate(valueKeys)}</th>
                    }
                )}
                <th className="font-smaller text-center my-auto py-1"
                    key='_edit_'>{translate('edit')}</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(tSectionVal).map(([tElementKey, tElementVal]) => {
                if (tElementKey !== 'all_values') {
                    if (settings[tElementKey] !== undefined) {
                        return (
                            <tr key={'tr_' + tElementKey}>
                                <td key={tElementKey}
                                    className="font-smaller my-auto">{translate(tElementKey)}</td>
                                {tSectionVal['all_values'].map(valueKey => {
                                        if (ids.indexOf(valueKey) > -1){
                                            return null;
                                        }

                                        const tempValue = values[`${tSectionKey}|${tElementKey}|${valueKey}`] !== undefined ?  values[`${tSectionKey}|${tElementKey}|${valueKey}`] : '';
                                    if (tElementVal[valueKey] !== undefined) {
                                        switch (tElementVal[valueKey]) {
                                            case 'array':
                                                let tVal = tempValue
                                                if(typeof tempValue === 'object'){
                                                    tVal = JSON.stringify(tempValue)
                                                }
                                                return <td key={valueKey}
                                                    className="font-smaller my-auto py-2 px-1"
                                                    onClick={() => {
                                                        setInputName(`${tSectionKey}|${tElementKey}|${valueKey}`)
                                                        setShowModal(true)
                                                        setTempVal(tempValue)
                                                        setSettingType('array')
                                                    }}
                                                >
                                                        <Form.Control
                                                        readOnly
                                                        as="input"
                                                        rows={3}
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        size="xs"
                                                        value={tVal}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;

                                                case 'object':
                                                let tValObj = tempValue
                                                if(typeof tempValue === 'object'){
                                                    tValObj = JSON.stringify(tempValue)
                                                }
                                                    return <td key={valueKey}
                                                                className="font-smaller my-auto py-2 px-1"
                                                                onClick={() => {
                                                                    setInputName(`${tSectionKey}|${tElementKey}|${valueKey}`)
                                                                    setShowModal(true)
                                                                    setTempVal(tempValue)
                                                                    setSettingType('object')
                                                                }}
                                                    >
                                                        <Form.Control
                                                            readOnly
                                                            as="input"
                                                            rows={3}
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            size="xs"
                                                            value={tValObj}
                                                            placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'integer':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'integer')}
                                                        size="xs"
                                                        type="integer"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'string':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'string')}
                                                        size="xs"
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'category':
                                                    if (user.user_type_id !== 1)
                                                        return <td/>;

                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        size="xs"
                                                        as="select"
                                                        value={tempValue}
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'category')}
                                                    >
                                                        {Object.keys(termCategories).map(val => {
                                                            return <option key={val}
                                                                           value={val}>{termCategories[val].name[language]}</option>
                                                        })}
                                                    </Form.Control></td>;
                                                case 'number':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'number')}
                                                        size="xs"
                                                        htmlSize={"30"}
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'boolean':
                                                    return <td key={valueKey}
                                                               className="font-smaller text-center my-auto py-2 px-1"
                                                    ><Form.Check

                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'boolean')}
                                                        checked={tempValue}
                                                        type="checkbox" label=''/></td>;
                                                case 'enum':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        size={"xs"}
                                                        as="select"
                                                        value={tempValue}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'enum')}
                                                    >
                                                        {Object.entries(settings_dir[tElementKey]['limits'][valueKey]).map(([a, ]) => {
                                                            return <option key={a}
                                                                           value={a}>{translate(a)}</option>
                                                        })}
                                                    </Form.Control></td>;
                                                default:
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'string')}
                                                        size="xs"
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                            }
                                        } else {
                                            return <td key={valueKey}/>;
                                        }

                                    }
                                )}
                                <td key={"_edit_"}
                                    style={{verticalAlign:"middle"}}
                                    className="font-smaller text-center my-auto py-2 pl-2">
                                    {!defaultSectionSet[tElementKey]?.settings?.protected ? <Button size={"xs"} onClick={() => deleteSetting(`${tSectionKey}|${tElementKey}`)}><i
                                        className="fas fa-trash"/></Button>: <i title={translate('this value is mandatory')} style={{color: "green"}} className="text-center my-auto fa fa-exclamation-triangle" /> }

                                </td>
                            </tr>
                        );
                    }
                }
                return null;
            })}
            </tbody>
        </table>

            {hasAddButton?.length ?
            <Row>
                <Col md={3} className="font-smaller text-center my-auto py-2 pl-2">
                    <Form.Control
                        size={"xs"}
                        as="select"
                        value={tSectionAdd}
                        name='addSectionSelect'
                        onChange={e => onSectionAddChange(e)}
                    >
                        <option key='' value=''>{translate('Select Setting')}</option>
                        {hasAddButton.map(key => <option key={key} value={key}>{translate(key)}</option>)}
                    </Form.Control>
                </Col>
                <Col md={1} className="font-smaller text-center my-auto py-2 pl-2">
                    <Button size={"xs"}
                            onClick={() => addSetting()}><i
                        className="fas fa-plus"/></Button>
                </Col>
            </Row> : <></>
            }
            {
                <JsonModal 
                    showModal={showModal}
                    setShowModal={setShowModal}
                    inputName={inputName}
                    onChangeSettingValue={onChangeSettingValue}
                    translate={translate}
                    tempVal={tempVal}
                    setTempVal={setTempVal}
                    settingType={settingType}
                />
                
            }
        </>
    );


}

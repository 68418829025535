import {convertArrayToText, convertFromErrorObject, isObjectEmpty, readUploadedFileAsBinary} from "../../../utils";
import * as XLSX from 'xlsx';
import * as Papa from "papaparse";

const convertToArray = (string ="") => {
    try {
        return JSON.parse(string);
    } catch (e) {
        return [];
    }
}

const convertToObject = (string ="") => {
    try {
        return JSON.parse(string);
    } catch (e) {
        return {};
    }
}

export const prepareUploadFile = (e, setUploadFile) => {
    setUploadFile(e.target.files[0]);
}

export const prepareForCompare = (value) => {
    if (typeof value === 'object') {
        try {
            return JSON.stringify(value)
        } catch (e) {
            return '';
        }
    }
    return value;
}

const putXLSX = (dataResult, filename) => {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(dataResult), 'Sheet1');
    const element = document.createElement("a");
    const bin = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
    element.href = URL.createObjectURL(new Blob([bin], {type: 'application/vnd.ms-excel'}));
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

const filterToSimple = (dataResult, checkWords) =>{
    const simpleResult = [];
    for (const res of dataResult) {
        if (!res["words"] || !res["lemma"])
            continue;
        try {
            let replacement = '';
            if (!isObjectEmpty(res["term_replacement"])){
                for (const rep  of res["term_replacement"]){
                    if (!isObjectEmpty(rep['words'])){
                        replacement += convertArrayToText(rep['words']) + '|';
                    }
                }
            }
            replacement = replacement.replace(/\|$/,"");
            const words = checkWords ? convertArrayToText(JSON.parse(res["words"])) : convertArrayToText(JSON.parse(res["lemma"]));
            simpleResult.push({
                "words": words,
                "description": res["description"],
                "replacement_words": replacement
            })
        } catch (e) {
            console.log(e);
        }
    }
    return simpleResult;
}

const putSimpleXLSX = (dataResult, filename, checkWords) => {

    const simpleResult = filterToSimple(dataResult, checkWords);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(simpleResult), 'Sheet1');
    const element = document.createElement("a");
    const bin = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
    element.href = URL.createObjectURL(new Blob([bin], {type: 'application/vnd.ms-excel'}));
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

}

const putSimpleCSV = (dataResult, filename, checkWords) => {
    const columns = ['words','description', 'replacement_words'];

    const simpleResult = filterToSimple(dataResult, checkWords);

    let stringResult = columns.join(",") + "\n";
    for (const res of simpleResult) {
        let tmpString = '';
        for (const col of columns) {
            tmpString += res[col] !== undefined ? '"' + res[col].toString().replaceAll('"', '""') + '",' : '"",';
        }
        stringResult += tmpString.substring(0, tmpString.length - 1) + "\n";
    }

    const blob = new Blob([stringResult.substring(0, stringResult.length - 1)], {type: 'text/csv'});
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

const putCSV = (dataResult, filename) => {
    const columns = ['id', 'check_words', 'description', 'enabled', 'global_visible', 'lemma', 'settings', 'tag', 'wordcount', 'words',
        'replacement_description', 'replacement_global_visible', 'replacement_id', 'replacement_lemma',
        'replacement_settings', 'replacement_tag', 'replacement_wordcount', 'replacement_words'];


    let stringResult = columns.join(",") + "\n";
    for (const res of dataResult) {
        let tmpString = '';
        for (const col of columns) {
            tmpString += res[col] !== undefined ? '"' + res[col].toString().replaceAll('"', '""') + '",' : '"",';
        }
        stringResult += tmpString.substring(0, tmpString.length - 1) + "\n";
    }

    const blob = new Blob([stringResult.substring(0, stringResult.length - 1)], {type: 'text/csv'});
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export const downloadTerms = async (tlService, token, category_id, setDownloadBarVisible, setDownloadBarNow, downloadFormat, checkWords) => {
    let limit = 1000;
    let offset = -limit;
    let fullcount = 0;
    let dataResult = []
    setDownloadBarVisible(true);
    do {
        offset += limit;
        const categoryData = await tlService.getFilteredTermByCategory(token,
            {limit, offset},
            {category_id: category_id});
        if (categoryData['data'] && categoryData['data'].length > 0) {
            fullcount = categoryData['fullcount'];
            // convert data

            for (const dataTmp of categoryData['data']) {
                let returnVal = {};
                for (const [key, value] of Object.entries(dataTmp)) {
                    if (key === 'lemma' && value.length > 0) {
                        returnVal['lemma'] = JSON.stringify(value);
                    } else if (key === 'words' && value.length > 0) {
                        returnVal['words'] = JSON.stringify(value);
                    } else if (key === 'tag' && value.length > 0) {
                        returnVal['tag'] = JSON.stringify(value);
                    } else if (key === 'settings' && !isObjectEmpty(value)) {
                        returnVal['settings'] = JSON.stringify(value);
                    } else if (!isObjectEmpty(value)) {
                        returnVal = {...returnVal, [key]: value}
                    }
                }
                dataResult.push(returnVal);

                // add replacements
                if (dataTmp['term_replacement'] && dataTmp['term_replacement'].length > 0) {
                    let returnValReplacement = {}
                    for (let dataReplacement of dataTmp['term_replacement']) {
                        for (const [key, value] of Object.entries(dataReplacement)) {
                            if (key === 'lemma' && value.length > 0) {
                                returnValReplacement['replacement_lemma'] = JSON.stringify(value);
                            } else if (key === 'words' && value.length > 0) {
                                returnValReplacement['replacement_words'] = JSON.stringify(value);
                            } else if (key === 'tag' && value.length > 0) {
                                returnValReplacement['replacement_tag'] = JSON.stringify(value);
                            } else if (key === 'settings' && !isObjectEmpty(value)) {
                                returnValReplacement['replacement_settings'] = JSON.stringify(value);
                            } else {
                                if (key !== 'id' && !isObjectEmpty(value))
                                    returnValReplacement = {...returnValReplacement, ['replacement_' + key]: value}
                            }
                        }
                        dataResult.push(returnValReplacement)
                    }

                }

            }
            setDownloadBarNow(Math.round((offset + limit) / (fullcount / 100)))
        } else {
            break;
        }
    } while (fullcount > (offset + limit));

    setDownloadBarVisible(false);

    if (downloadFormat === "CSV")
        putCSV(dataResult, `downloadTerm${category_id}.csv`);
    else if (downloadFormat === "XLSX")
        putXLSX(dataResult, `downloadTerm${category_id}.xlsx`);
    else if (downloadFormat === "SimpleXLSX")
        putSimpleXLSX(dataResult, `simpleDownloadTerm${category_id}.xlsx`, checkWords);
    else if (downloadFormat === "SimpleCSV")
        putSimpleCSV(dataResult, `simpleDownloadTerm${category_id}.csv`, checkWords);
}

export const clearCache = async (token, category_id, tlService) => {
    await tlService.clearAllCache(token, 1, category_id === undefined ? undefined : parseInt(category_id));
}

export const calculateSize = async (token, category_id, tlService) => {
    await tlService.calculateCategory(token, parseInt(category_id));
}
// calculateAllSizes
export const calculateAllSizes = async (token, tlService,setDisableButtons) => {
    setDisableButtons(true);
    try {
        const ret = await tlService.getFilteredTermCategories(token);
        for (const cat of ret.data) {
            await calculateSize(token, cat["id"], tlService);
        }
    } catch (e) {
        console.log(e.toString());
    }
    setDisableButtons(false);
}

export const clearAllCaches = async (token, tlService,setDisableButtons) => {
    setDisableButtons(true);
    await clearCache(token, undefined, tlService);
    setDisableButtons(false);
}

const runBunchUpload = async (fullDataLength, token, category_id, fullCounter, sendJSON, setUploadError, setUploadBarNow, setUploadBarText,
                              tlService, returnResultJSON, translate) => {
    if (!isObjectEmpty(sendJSON)) {

        try {
            const res = await tlService.termBunch(token, category_id, {line: fullCounter, data: sendJSON});
            if (res && res?.data?.length) {

                const filteredTerms = res.data.filter(v => v?.term_id > 0);
                const filteredReplacements = res.data.filter(v => !v?.term_id);

                const termIdToPosition = {};
                let returnPosition = 0;
                for (let term of filteredTerms) {
                    // add an error
                    setUploadError(s => s + `\n ` + convertFromErrorObject(translate, {message: JSON.stringify(term)}));

                    // in case of  format error
                    if (term['term_index'] === undefined)
                        continue;

                    const sendTerm = sendJSON[term['term_index']];

                    // if term is exist in send JSON
                    if (sendTerm !== undefined) {
                        returnResultJSON.push({
                            "words": sendTerm['words'] ? JSON.stringify(sendTerm['words']) : "",
                            "lemma": sendTerm['lemma'] ? JSON.stringify(sendTerm['lemma']) : "",
                            "tag": sendTerm['tag'] ? JSON.stringify(sendTerm['tag']) : "",
                            "enabled": sendTerm['enabled'],
                            "check_words": sendTerm['check_words'],
                            "settings": sendTerm['settings'] ? JSON.stringify(sendTerm['settings']) : "",
                            "description": sendTerm['description'],
                            "global_visible": sendTerm['global_visible'],
                            "replacement_words": null,
                            "replacement_lemma": null,
                            "replacement_tag": null,
                            "replacement_settings": null,
                            "replacement_description": null,
                            "replacement_global_visible": null,
                            "warning": convertFromErrorObject(translate, {message: JSON.stringify(term)})
                        });
                        // add to relay  object
                        termIdToPosition[term['term_index']] = returnPosition;
                        returnPosition++;
                        if (!isObjectEmpty(sendTerm['replacements'])) {
                            for (let currReplacement of sendTerm['replacements']) {
                                returnResultJSON.push({
                                    "replacement_words": JSON.stringify(currReplacement['words']),
                                    "replacement_lemma": JSON.stringify(currReplacement['lemma']),
                                    "replacement_tag": JSON.stringify(currReplacement['tag']),
                                    "replacement_settings": JSON.stringify(currReplacement['settings']),
                                    "replacement_description": currReplacement['description'],
                                    "replacement_global_visible": currReplacement['global_visible']
                                });
                                returnPosition++;
                            }
                        }
                    }
                }

                for (let replacement of filteredReplacements) {
                    if (termIdToPosition[replacement['term_index']] !== undefined) {
                        const termPos = termIdToPosition[replacement['term_index']];
                        if (returnResultJSON[termPos + replacement["replacement_index"] + 1] !== undefined) {
                            returnResultJSON[termPos + replacement["replacement_index"] + 1]['warning'] =
                                convertFromErrorObject(translate, {message: JSON.stringify(replacement)});
                        }
                    }

                }
            }
        } catch (e) {
            console.log(convertFromErrorObject(translate,e));
            setUploadError(s => s + convertFromErrorObject(translate,e) + ": id=" +category_id +"\n");
            return false;
        }
        setUploadBarNow(Math.round(fullCounter / (fullDataLength / 100)));
        setUploadBarText(`${fullCounter} / ${fullDataLength}`)
        return true;
    }
    return false;
}

export const uploadTerms = async (token, category_id, tlService, lang, uploadFile, setUploadError, setUploadBarVisible, setUploadBarNow,
                                  setUploadBarText, setUploadFile, refreshFunc, returnResult, setDisableButtons, translate) => {
    const maxSize = 100;
    let returnResultJSON = [];
    let data = [];
    setDisableButtons(true);
    console.log("uploadFile",uploadFile)
    if (uploadFile) {
        if (
            uploadFile['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            uploadFile['type'] === "application/vnd.ms-excel"
        ) {
            const fileContents = await readUploadedFileAsBinary(uploadFile, false);
            const workbook = XLSX.read(fileContents, {type: 'binary'})
            if (workbook && workbook.SheetNames[0] && workbook.Sheets[workbook.SheetNames[0]]) {
                data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 0});
            } else {
                setUploadError(translate('Wrong data'));
                setUploadBarVisible(false);
                setUploadFile(null);
                setDisableButtons(false);
                return;
            }
        } else if (uploadFile['type'] === "text/csv") {
            const fileContents = await readUploadedFileAsBinary(uploadFile, true);
            const res = Papa.parse(fileContents, {header: true, delimiter: ""});
            data = res.data;
        } else {
            setUploadError(translate('Wrong file format'));
            setUploadBarVisible(false);
            setUploadFile(null);
            setDisableButtons(false);
            return;
        }

        if (data && data.length > 0) {

            setUploadBarVisible(true);
            // go through the file lines
            let sendJSON = [];
            let currentTerm = {};
            let currentTermReplacements = [];
            let lineCounter = 0;
            let fullCounter = 0;

            for (let tmpData of data) {

                // if lineCounter reach maximum - > send object and then  clear it
                if (lineCounter >= maxSize) {

                    // if we have any SERVER error -> stop
                    if (!await runBunchUpload(data.length, token, category_id, fullCounter, sendJSON, setUploadError,
                        setUploadBarNow, setUploadBarText, tlService, returnResultJSON, translate)){
                        console.log("runBunchUpload error "+category_id);
                        return;
                    }

                    // reset object
                    sendJSON = [];
                    // reset counter
                    lineCounter = 0;

                }


                //if (tmpData['words']) => a new term
                if (tmpData['words']) {

                    // increase a lineCounter
                    lineCounter++;
                    fullCounter++;

                    // if currentTerm has a keys -> add it and then  clear
                    if (!isObjectEmpty(currentTerm)) {
                        sendJSON.push({...currentTerm, replacements: currentTermReplacements});
                        currentTerm = {};
                        currentTermReplacements = [];
                    }


                    // cast parameters
                    tmpData['enabled'] = isNaN(parseInt(tmpData['enabled'])) ? 0 : parseInt(tmpData['enabled']);
                    tmpData['global_visible'] = isNaN(parseInt(tmpData['global_visible'])) ? 0 : parseInt(tmpData['global_visible']);
                    tmpData['check_words'] = isNaN(parseInt(tmpData['check_words'])) ? 0 : parseInt(tmpData['check_words']);


                    // replace an extra \\
                    if (tmpData['settings'] !== undefined)
                        tmpData['settings'] = tmpData['settings'].replaceAll("\\", "").replace(/"$/, '').replace(/^"/, '');
                    if (tmpData['replacement_settings'] !== undefined)
                        tmpData['replacement_settings'] = tmpData['replacement_settings'].replaceAll("\\", "").replace(/"$/, '').replace(/^"/, '');

                    //console.log("tmpData['settings']", tmpData['settings']);

                    // add a new term
                    currentTerm = {
                        "words": convertToArray(tmpData['words']),
                        "lemma": convertToArray(tmpData['lemma']),
                        "tag": convertToArray(tmpData['tag']),
                        "enabled": tmpData['enabled'],
                        "check_words": tmpData['check_words'],
                        "settings": convertToObject(tmpData['settings']),
                        "description": tmpData['description'],
                        "global_visible": tmpData['global_visible'],
                    };
                }

                // add replacements
                if (tmpData['replacement_words']) {

                    // increase a lineCounter
                    lineCounter++;
                    fullCounter++;

                    tmpData['replacement_global_visible'] = isNaN(parseInt(tmpData['replacement_global_visible'])) ? 0 : parseInt(tmpData['replacement_global_visible']);
                    currentTermReplacements.push({
                        "words": convertToArray(tmpData['replacement_words']),
                        "lemma": convertToArray(tmpData['replacement_lemma']),
                        "tag": convertToArray(tmpData['replacement_tag']),
                        "settings": convertToObject(tmpData['replacement_settings']),
                        "description": tmpData['replacement_description'],
                        "global_visible": tmpData['replacement_global_visible']
                    });
                }
            }

            // check on exit
            if (!isObjectEmpty(currentTerm)) {
                sendJSON.push({...currentTerm, replacements: currentTermReplacements});
            }

            // after all send the last  JSON data
            if (!await runBunchUpload(data.length, token, category_id, fullCounter, sendJSON, setUploadError,
                setUploadBarNow, setUploadBarText, tlService, returnResultJSON, translate)){
                console.log("runBunchUpload error "+category_id);
                return;
            }

            // at the end  calculate size
            await tlService.calculateCategory(token, parseInt(category_id));

            // clear cache
            await tlService.clearAllCache(token, 1, `${parseInt(category_id)}`);
            refreshFunc();

        } else {
            setUploadError(translate('Data is empty'))
        }


    } else {
        setUploadError(translate('Choose file for upload'))
    }

    if (returnResult && returnResultJSON.length > 0) {
        putXLSX(returnResultJSON, `errorTerm${category_id}.xlsx`);
    }

    setUploadBarVisible(false);
    setUploadFile(null);
    setDisableButtons(false);
}

import React from "react";
import {withTranslation} from 'react-i18next';
import {Button} from "react-bootstrap";
import {
    checkUserRight,
    compose, convertFromErrorObject,
    convertTextToTT, getAllModalFunctions,
    getDataFromModals,
    removeEmptyFromObject
} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ModalAddEditReplacement from "../modal-add-edit-replacement";
import DataViewer from "../../elements/data-viewer";


class ModalTableReplacement extends DataViewer {

    /*  define count of modal (0 because opened in modal windows)*/
    modalWindowsCount = 0

    tableFilters = {}

    functions = {
        getAll: this.props.tlService.getFilteredTermReplacement,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditReplacement,
        modalEdit: ModalAddEditReplacement,
        modalView: ModalAddEditReplacement,
    };

    componentDidMount() {
        const {
            userData: {user},
            t: translate
        } = this.props;

        const {index} = this.props;
        const data = getDataFromModals(this.props, index);

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true,
                grow: 0.3
            },
            {
                name: 'words',
                selector: 'words',
                cell: row => JSON.stringify(row.words)
            },
            {
                name: 'description',
                selector: 'description'
            },
            {
                name: 'settings',
                selector: 'settings',
                cell: row => JSON.stringify(row.settings)
            },
            {
                name: 'edit',
                cell: row => {
                    return (
                        <div className="btn-group">
                            {checkUserRight(user, [701, 702]) &&
                            (<>
                                <Button onClick={() => this.openModal(1, row, 'modalDelete')} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [701, 702, 703]);

        // check add right
        this.addButton = checkUserRight(user, [701, 702, 703]);
        //this.addButton = false;


        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: false,
                enabled: true,
                description: "",
                settings: {},
                global_visible: true,
            },
            additionalData: {
                locale_name: data['category'].locale_name,
                term_id: data['id'],
                category_id: data['category'].id,
                rawWords: '',
            },
            show: false,
            header: translate('Add Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: false,
                enabled: true,
                description: "",
                settings: {},
                global_visible: true,
            },
            additionalData: {
                locale_name: data['category'].locale_name,
                term_id: data['id'],
                category_id: data['category'].id,
                rawWords: '',
            },
            show: false,
            header: translate('Edit Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Term Replacement'),
            footer: true,
            text: translate("Delete Term Replacement?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Term Categories'),
            footer: true,
            text: translate('Delete Selected Term Categories'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {
                term_id: this.props.modalData.modal[this.props.index].data.id,
                term_category_id: this.props.modalData.modal[this.props.index].data.category.id
            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Term Replacement'),
                addNewText: translate('Add new term replacement'),
            }
        });

        super.componentDidMount();

    }

    actionAdd = async (data) => {
        const {t} = this.props;
        const {
            modalSetErrorData,
            tlService,
            userData: {token}
        } = this.props;

        const {rawWords, description, locale_name, settings} = data;

        let convertedText;
        try {
            convertedText = await convertTextToTT(token, tlService, rawWords, locale_name)
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
        }

        if (convertedText && convertedText.words.length > 0) {

            const newSetting = Object.keys(settings).length > 0 ?  removeEmptyFromObject(settings) : {};

            if (newSetting['meaning']){
               try {
                   newSetting['meaning'] = JSON.parse(newSetting['meaning'])
               } catch (e) {
                   modalSetErrorData(1, t('meaning should be a JSON array'))
                   this.setState({error: t('meaning should be a JSON array')})
                   console.log('wrong meaning')
                   return false;
               }
            }

            let addReplacementResult;
            try {
                addReplacementResult = await tlService.addTermReplacementInCategory(token, data.category_id, data.term_id,
                    {
                        words: convertedText.words,
                        lemma: convertedText.lemma,
                        tag: convertedText.tag,
                        global_visible: 1,
                        description,
                        settings: newSetting
                    })
            } catch (e) {
                console.log("modalSetErrorData", 1);
                modalSetErrorData(1, convertFromErrorObject(t,e));
                this.setState({error: convertFromErrorObject(t,e)});
            }
            if (addReplacementResult) {
                this.getAllData();
                return true;
            }
        }
        return false;
    }

    actionEdit = async (data) => {
        console.log(data);
         return false;
    }

    actionDelete = async (input) => {
        const {t} = this.props;
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {id: term_id, category: {id: category_id}} = data;
        const {
            userData: {token},
            tlService,
        } = this.props;

         console.log('input ', {input, term_id, category_id});

        // delete
        try {
            await tlService.deleteTermReplacementInCategory(token, category_id,  term_id, input.id );
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        this.getAllData();
        return true;
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalTableReplacement);
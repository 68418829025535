import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Alert, Button, Col, ProgressBar, Row} from "react-bootstrap";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {calculateAllSizes, clearAllCaches, uploadTerms} from '../terms-by-category/upload-file-function'
import Dnd from "../../elements/dnd/dnd";
import {isObjectEmpty} from "../../../utils";


export const TermHeader = (props) => {
    const {lang} = props.state;
    const {t: translate, tlService, userData: {token, user: {user_type_id}}} = props;

    const [uploadBarVisible, setUploadBarVisible] = useState(false);
    const [uploadBarNow, setUploadBarNow] = useState(0);
    const [uploadBarText, setUploadBarText] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadError, setUploadError] = useState('');
    const [filename, setFilename] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const [uploadAlert, setUploadAlert] = useState(false);
    //filename

    useEffect(() => {
        if (!isObjectEmpty(uploadFile) && uploadAlert){
            setUploadAlert(false);
            confirmAlert(options);
        }
    }, [uploadFile, uploadAlert])

    const refreshFunc = () => {};

    const options = {
        title: translate('Are you sure want to upload?'),
        message: translate('All data in the chosen categories will be DELETED!'),
        buttons: [
            {
                label: translate('Run'),
                onClick: async () => {
                    setUploadError('');
                    for (const file of uploadFile) {
                        setUploadBarNow(0);
                        setUploadBarText('0/0');
                        setFilename('');
                        let id = 0;
                        if (file.name.match(/downloadTerm(\d+)\.xlsx/)){
                            id = parseInt(file.name.replace(/downloadTerm(\d+)\.xlsx/, "$1"));
                        } else if (file.name.match(/downloadTerm(\d+)\.csv/)){
                            id = parseInt(file.name.replace(/downloadTerm(\d+)\.csv/, "$1"));
                        }
                        if (id > 0) {
                            setFilename(file.name + " id=" + id);
                            await uploadTerms(token, id, tlService, lang, file, setUploadError,
                                setUploadBarVisible, setUploadBarNow, setUploadBarText, setUploadFile,
                                refreshFunc, false, setDisableButtons, translate)
                        } else {
                            setUploadError(translate('Wrong file name') + file.name);
                            break;
                        }
                    }
                    setFilename('');
                }
            },
            {
                label: translate('Back')
            }
        ],
        childrenElement: () => <div/>,
        closeOnEscape: true,
        closeOnClickOutside: true
    };


    return (
        <Row>
            <Col md={1} className="mr-2"><Link to="/terms"><i className="fas fa-arrow-left"/></Link></Col>
            {user_type_id === 1 &&
                <>
                    <Col className="col-9 m-4">
                        <Row>
                            <Col className="pr-1 ">
                                <Button disabled={disableButtons} className="btn btn-xs btn-info  h-auto" onClick={() =>calculateAllSizes(token, tlService, setDisableButtons)}>
                                    {translate('Recalculate all sizes')}
                                </Button>
                            </Col>
                            <Col className="pr-1">
                                <Button disabled={disableButtons} className="btn btn-xs btn-primary  h-auto" onClick={() =>clearAllCaches(token, tlService, setDisableButtons)}>
                                    {translate('Clear all caches')}
                                </Button>
                            </Col>
                            <Col className="pr-1">
                                {uploadBarVisible &&
                                    <div className="pt-1 mt-1">
                                        {filename} <ProgressBar now={uploadBarNow} variant="warning striped"/> ({uploadBarText})
                                    </div>}
                            </Col>
                            <Col className="pr-1">
                                {!uploadBarVisible &&
                                    <Dnd disabled={disableButtons} text={translate('Mass Upload Category')}
                                         onReadDropFiles={
                                             acceptedFiles => {
                                                 if (!isObjectEmpty(acceptedFiles)) {
                                                     setUploadFile(acceptedFiles);
                                                     setUploadAlert(true);
                                                 }
                                             }}/>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                {uploadError && <Alert variant="danger" onClose={() => setUploadError(false)} dismissible>
                                    <div className="font-smaller mx-auto text-center"><pre>{uploadError}</pre></div>
                                </Alert>}
                            </Col>
                        </Row>
                    </Col>
                </>
            }
        </Row>
    );
}
import HashedData from "../utils/hash";


const updateOpenData = (state, action) => {
    const defaultState = {
        user_types: {},
        licenses: {},
        languages: {},
        locales: new HashedData(),
        countries: {},
        ping: {},
        return_values: {},
        country_languages: {},
        loading: false,
        error: null,

    };

    if (state === undefined) {
        return defaultState;
    }
    switch (action.type) {

        case 'FETCH_PING_REQUEST':
            return {
                ...state.openData,
                ping: defaultState.ping,
                loading: true,
                error: null

            };
        case 'FETCH_PING_SUCCESS':
            return {
                ...state.openData,
                ping: action.payload,
                loading: false,
                error: null

            };
        case 'FETCH_PING_FAILURE':
            return {
                ...state.openData,
                ping: defaultState.ping,
                loading: false,
                error: action.payload
            };

        case 'FETCH_RETURN_VALUES_REQUEST':
            return {
                ...state.openData, ...{
                    return_values: defaultState.return_values,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_RETURN_VALUES_SUCCESS':
            return {
                ...state.openData, ...{
                    return_values: action.payload.reduce((accumulator, currentValue) => {
                        const {type, value} = currentValue;
                        accumulator[value] = type;
                        return accumulator
                    }, {}),
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_RETURN_VALUES_FAILURE':
            return {
                ...state.openData, ...{
                    return_values: defaultState.return_values,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_LICENSES_REQUEST':
            return {
                ...state.openData, ...{
                    licenses: defaultState.licenses,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_LICENSES_SUCCESS':
            return {
                ...state.openData, ...{
                    licenses: action.payload.reduce((accumulator, currentValue) => {
                        const {id, name} = currentValue;
                        accumulator[id] = {name};
                        return accumulator
                    }, {}),
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_LICENSES_FAILURE':
            return {
                ...state.openData, ...{
                    licenses: defaultState.licenses,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_USERTYPES_REQUEST':
            return {
                ...state.openData, ...{
                    user_types: defaultState.user_types,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_USERTYPES_SUCCESS':
            return {
                ...state.openData, ...{
                    user_types: action.payload.reduce((accumulator, currentValue) => {
                        const {id, name} = currentValue;
                        accumulator[id] = {name};
                        return accumulator
                    }, {}),
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_USERTYPES_FAILURE':
            return {
                ...state.openData, ...{
                    user_types: defaultState.user_types,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_LOCALES_REQUEST':
            return {
                ...state.openData, ...{
                    locales: defaultState.locales,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_LOCALES_SUCCESS':
            return {
                ...state.openData, ...{
                    locales: new HashedData(action.payload, ["name", "language_short_name"]),
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_LOCALES_FAILURE':
            return {
                ...state.openData, ...{
                    locales: defaultState.locales,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_COUNTRY_LANGUAGES_REQUEST':
            return {
                ...state.openData, ...{
                    country_languages: defaultState.country_languages,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_COUNTRY_LANGUAGES_SUCCESS':
            return {
                ...state.openData, ...{
                    country_languages: action.payload,
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_COUNTRY_LANGUAGES_FAILURE':
            return {
                ...state.openData, ...{
                    country_languages: defaultState.country_languages,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_LANGUAGES_REQUEST':
            return {
                ...state.openData, ...{
                    languages: defaultState.languages,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_LANGUAGES_SUCCESS':
            return {
                ...state.openData, ...{
                    languages: action.payload.reduce((accumulator, currentValue) => {
                        const {full_name, lang_name, short_name} = currentValue;
                        accumulator[short_name] = {full_name, lang_name};
                        return accumulator
                    }, {}),
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_LANGUAGES_FAILURE':
            return {
                ...state.openData, ...{
                    languages: defaultState.languages,
                    loading: false,
                    error: action.payload
                }
            };

        case 'FETCH_COUNTRIES_REQUEST':
            return {
                ...state.openData, ...{
                    countries: defaultState.countries,
                    loading: true,
                    error: null
                }
            };
        case 'FETCH_COUNTRIES_SUCCESS':
            return {
                ...state.openData, ...{
                    countries: action.payload.reduce((accumulator, currentValue) => {
                        const {full_name, enable, short_name} = currentValue;
                        accumulator[short_name] = {full_name, enable};
                        return accumulator
                    }, {}),
                    loading: false,
                    error: null
                }
            };
        case 'FETCH_COUNTRIES_FAILURE':
            return {
                ...state.openData, ...{
                    countries: defaultState.countries,
                    loading: false,
                    error: action.payload
                }
            };

        default:
            return state.openData;
    }
};

export default updateOpenData;
import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {userGetAllTermCategories} from "../../../actions";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BaseModal from "../base-modal";

/*
check_words: 0
company_id: 1
create_time: "2022-04-01 10:32:10:UTC"
delete_time: ""
description: "Dieser Begriff kann negativ wirken.|Tipp: Versuchen Sie, auf den Begriff zu verzichten und neutral zu formulieren."
id: 3
lemma: "[\"zwingen\"]"
settings: ""
state: 0
tag: "[\"NP\"]"
term_category_id: 106
term_category_name: Object { de: "wtitelint", en: "wtitelint", ru: "" }
update_time: "2022-04-01 10:32:10:UTC"
user_group_id: 1
user_id: 2
words: "[\"zwingen\"]"
*/


class ModalAddEditAllowlist extends BaseModal {

    componentDidMount() {
        const {
            userData: {token, termCategories}
        } = this.props;
        // this.refreshData();
       this.props.userGetAllTermCategories(token)
    }


    render() {
        const {index, t: translate } = this.props;
        const {userData: {token, termCategories}} = this.props;

        const data = getDataFromModals(this.props, index);
        const {check_words, lemma: tLemma, tag: tTag, words: tWords, description, term_category_name, term_category_id} = data;
        let lemma, tag, words
        lemma = typeof tLemma === 'object' ? JSON.stringify(tLemma) : tLemma;
        tag = typeof tTag === 'object' ? JSON.stringify(tTag) : tTag;
        words = typeof tWords === 'object' ? JSON.stringify(tWords) : tWords;


        const termCategory = Object.entries(termCategories);

        return (
            <Container>
                <Row>
                <Col md={3}>
                    <Form.Group controlId="term_category_id">
                        <Form.Control
                            onChange={e => this.handleChange(e)}
                            value={term_category_id}
                            name="term_category_id"
                            as="select">
                                {
                                    !!termCategory?.length && termCategory.map((el, index) => 
                                        <>
                                        <option key={el[0]} value={el[0]}>
                                            {el[1]?.name[this.props.i18n.language]}
                                            </option>
                                        </>
                                    )
                                }
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={3}>
                        <Form.Group controlId="check_words">
                            <Form.Control
                                onChange={e => this.handleChange(e)}
                                value={check_words}
                                name="check_words"
                                as="select">
                                <option key="0" value="0">{translate("by lemma")}</option>
                                <option key="1" value="1">{translate("by words")}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="lemma">
                            <Form.Control
                                type="text"
                                name="lemma"
                                defaultValue={lemma}
                                onChange={e => this.handleChange(e)}
                                placeholder={translate("lemma")}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="words">
                            <Form.Control
                                type="text"
                                name="words"
                                defaultValue={words}
                                onChange={e => this.handleChange(e)}
                                placeholder={translate("words")}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="tag">
                            <Form.Control
                                type="text"
                                name="tag"
                                defaultValue={tag}
                                onChange={e => this.handleChange(e)}
                                placeholder={translate("tag")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col md={3}>
                        <Form.Group controlId="description">
                            <Form.Control
                                type="text"
                                name="description"
                                defaultValue={description}
                                onChange={e => this.handleChange(e)}
                                placeholder={translate("description")}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        userGetAllTermCategories: userGetAllTermCategories(tlService)
    }, dispatch);
};


export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditAllowlist);
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Alert, Button, Col, Row} from "react-bootstrap";
import 'react-confirm-alert/src/react-confirm-alert.css';

const RegexHeader = (props) =>{
    const {page: {pageHeader}} = props.state;
    const {t, userData: {token}, tlService} = props;
    const [error, setError] = useState('');

    const handleReload = async () => {
        let result;
        try {
            await tlService.reloadRegex(token);
            result = t('done');
        } catch (e) {
            result = e.toString()
        }
        setError(result);
    }

    return(<>
        <Row>
            <Col md={1} className="mr-2"><Link to="/terms"><i className="fas fa-arrow-left"/></Link></Col>
            <Col md={3}>
                <h1 className="h3 mb-0 text-gray-800">{pageHeader}</h1>
            </Col>
            <Col className={'col-md-2 p-1'}>
                <Button
                    onClick={handleReload}
                    type="button"
                    className="btn btn-warning btn-user btn-block">
                    {'Reload'}
                </Button>
            </Col>
        </Row>
    {error &&
    <Row>
        <Col className={'p-1'}>
            <Alert variant={'primary'}><div dangerouslySetInnerHTML={{__html: error}}/></Alert>
        </Col>
    </Row>
    }
    </>)
}

export default RegexHeader;
import React, {Component} from 'react';
import Datatable from 'react-data-table-component';
import {Button, Alert, Container, Row, Col, Form} from 'react-bootstrap';
import Spinner from '../spinner';
import TemplateModal from '../../modals/template-modal';
import DataViewerHeader from './data-viewer-header';
import TableFilter from '../../modal-elements/table-filter';
import {isObjectEmpty} from '../../../utils';

class DataViewer extends Component {
    /**
     * config
     */

    /* common variables */
    renderIt = true; // show the page
    addButton = true; // show Add Button
    functions = {}; // dynamical functions

    /* modal data */
    modalSettings = {};
    extendedFilter = false;
    modalWindowsCount = 1; // adding count of modals
    modalComponents = {}; // component
    defaultModal = {
        action: false,
        data: {},
        show: false,
        header: '',
        footer: true,
        text: '',
        ComponentText: undefined,
        saveButton: true,
        saveButtonText: '',
        closeButton: true,
        closeButtonText: '',
        ok: true,
    };

    /* table  */
    table_name = false; // name of table for add multilang support
    extended_fields = []; // fields name for multilang support

    columns_default = [];
    tableFilters = {
        deleted: {
            type: 'boolean',
            default: false,
        },
    };

    /* default states */
    state = {
        /* table data */
        columns: [],
        rows: [],
        rowsPerPage: [5, 10, 20, 50, 100],
        pageNumber: 1,
        totalRows: 0,
        filters: {},
        limits: {},

        /* table selected config */
        selectedMassAction: '',
        selectedRows: [],

        /* page config */
        Header: DataViewerHeader,
        Redirect: false,
        submitted: false,
        error: false,
        page: {
            pageHeader: 'pageHeader',
            addNewText: 'addNewText',
        },
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {lang, filters, limits, pageNumber} = this.state;

        // get new data if change something in table
        if (
            filters !== prevState['filters'] ||
            limits !== prevState['limits'] ||
            pageNumber !== prevState['pageNumber']
        ) {
            this.getAllData();
        }

        // reload table data if language  is change
        if (this.props.i18n.language !== lang) {
            this.reloadTable(this.state.rows);
        }
    }

    componentDidMount() {
        if (this.modalWindowsCount > 0) {
            for (let i = 0; i < this.modalWindowsCount; i++) {
                this.props.modalAdd({...this.defaultModal});
            }
        }
    }

    /* default mass actions */

    actionMassDelete(data) {
        if (data.length > 0)
            this.openModal(
                this.props.index !== undefined ? this.props.index + 1 : 0,
                data,
                'modalMassDelete'
            );
    }

    actionDoMassDelete = async (data) => {
        for (let id of data) {
            await this.actionDelete(id);
        }
        this.getAllData();
        return true;
    };

    /* end mass actions */

    /* default reload  */

    reloadTable(data, totalRows) {
        const columns_default = this.columns_default;
        const {t: translate} = this.props;
        if (data && data.length > 0) {
            let newData = [];
            for (let i of data) {
                newData.push(i);
            }
            /* update columns */
            let newColumns = [];
            for (let i of columns_default) {
                const {name, selector, sortable, cell, grow} = i;
                if (cell === undefined)
                    newColumns.push({
                        name: translate(name),
                        selector,
                        sortable,
                        grow,
                    });
                else
                    newColumns.push({
                        name: translate(name),
                        selector,
                        cell,
                        grow,
                    });
            }
            this.setState({
                totalRows,
                columns: newColumns,
                rows: newData,
                lang: this.props.i18n.language,
            });
        } else {
            this.setState({
                columns: [],
                rows: [],
                lang: this.props.i18n.language,
            });
        }
    }

    getAllData() {
        this.setState({submitted: true});
        const {
            userData: {token},
        } = this.props;
        const {limits, filters} = this.state;

        console.log('getAllData', limits, filters, this.extendedFilter);

        // do not open with no limits!
        if (Object.keys(limits).length === 0) return false;

        let currentFilters = filters;
        if (this.extendedFilter) {
            const arrFilters = [];
            // tableFilters
            if (!isObjectEmpty(filters)) {
                for (const [keyFilter, valFilter] of Object.entries(filters)) {
                    console.log('[keyFilter, valFilter]', [
                        keyFilter,
                        valFilter,
                    ]);
                    if (
                        this.tableFilters[keyFilter] !== undefined &&
                        valFilter !== undefined
                    ) {
                        if (this.tableFilters[keyFilter].type === 'string') {
                            arrFilters.push([
                                `&${keyFilter}`,
                                `l=${valFilter}`,
                            ]);
                        } else if (
                            this.tableFilters[keyFilter].type === 'multilang'
                        ) {
                            const reg = new RegExp('([w]{2})(.+)');
                            let ret = valFilter.match(reg);
                            if (ret) {
                                arrFilters.push([
                                    `&${keyFilter}.${ret[0]}`,
                                    `l=${ret[1]}`,
                                ]);
                            }
                        } else {
                            arrFilters.push([`&${keyFilter}`, `=${valFilter}`]);
                        }
                    }
                }
                currentFilters = {extended_filter: JSON.stringify(arrFilters)};
            }
        }

        this.functions
            .getAll(token, limits, currentFilters)
            .then((res) => {
                console.log('modalAction reloadTable', res);
                this.reloadTable(
                    res.data,
                    res['fullcount'] ? res['fullcount'] : res.data.length
                );
                this.setState({submitted: false});
            })
            .catch(() => {
                this.reloadTable([], 0);
                this.setState({submitted: false});
            });
    }

    openModal(index, data, componentName) {
        this.setState({error: false});

        let newData = data ? data : this.modalSettings[componentName].data;
        if (this.modalSettings[componentName].additionalData) {
            newData = {
                ...newData,
                ...this.modalSettings[componentName].additionalData,
            };
        }
        //console.log('openModal',componentName, newData ,this.modalSettings[componentName].additionalData);

        this.props.modalReplace(index, {
            ...this.modalSettings[componentName],
            actionName: componentName,
            data: newData,
            text: this.modalSettings[componentName].text,
            ComponentText: this.modalComponents[componentName],
            globalActionFunction: this.modalAction,
        });
        this.props.modalOpenClose(index, true);
    }

    closeModal(index) {
        this.setState({error: false});
        this.props.modalOpenClose(index, false);
    }

    checkForOpenModal() {
        for (let mod of this.props.modalData.modal) {
            if (mod['show']) {
                return true;
            }
        }
        return false;
    }

    modalAction = async (index) => {
        const {action, data} = this.props.modalData.modal[index];
        this.setState({error: false});
        this.setState({submitted: true});
        let res = false;
        if (action) {
            res = await action(data);
        }
        if (res) {
            this.getAllData();
            this.closeModal(index);
        }
        this.setState({submitted: false});
    };

    setLimit(limit) {
        const {limits} = this.state;
        this.setState({
            limits: {
                ...limits,
                limit,
            },
        });
    }

    setPage(page) {
        const {limits} = this.state;
        this.setState({
            limits: {
                ...limits,
                offset:
                    parseInt(page) > 0
                        ? (parseInt(page) - 1) * limits['limit']
                        : 0,
            },
        });
    }

    setSort(field, order) {
        const {limits} = this.state;
        this.setState({
            limits: {
                ...limits,
                order_by: field.selector,
                order: order === 'asc' ? 0 : 1,
            },
        });
    }

    handleChangeMassAction = (event) => {
        const {value} = event.target;
        this.setState({selectedMassAction: value});
    };

    selectedRowsChange(rows) {
        let selectedRows = [];
        if (rows['selectedCount'] > 0) {
            for (let row of rows['selectedRows']) {
                selectedRows.push(row);
            }
        }
        this.setState({selectedRows});
    }

    makeMassAction = () => {
        const {selectedMassAction, selectedRows} = this.state;
        if (selectedMassAction && this.functions[selectedMassAction]) {
            this.functions[selectedMassAction](selectedRows);
        }
    };

    render() {
        const {
            modalData: {modal},
            t: translate,
        } = this.props;
        const {
            Redirect,
            Header,
            rowsPerPage,
            error,
            submitted,
            rows,
            columns,
            totalRows,
            limits: {limit},
            page: {addNewText},
        } = this.state;

        const printRows = rows;
        const anyModalShow = this.checkForOpenModal();

        if (Redirect) {
            return Redirect;
        }

        if (!this.renderIt) {
            return '';
        }

        // if (submitted) {
        //     return <Spinner/>
        // }

        return (
            <Container fluid={true}>
                {submitted && <Spinner />}
                {error && !anyModalShow && (
                    <Alert
                        variant="danger"
                        onClose={() => this.setState({error: false})}
                        dismissible
                    >
                        <p className="mx-auto text-center">{error}</p>
                    </Alert>
                )}
                <Row>
                    <Col>
                        <Header
                            getAllData={() => this.getAllData()}
                            setState={(state) => this.setState(state)}
                            openModal={(index, data, componentName) =>
                                this.openModal(index, data, componentName)
                            }
                            {...this.props}
                            state={this.state}
                        />
                    </Col>
                    <Col md={3} className="text-right">
                        {this.addButton && (
                            <Button
                                onClick={() =>
                                    this.openModal(
                                        this.props.index !== undefined
                                            ? this.props.index + 1
                                            : 0,
                                        false,
                                        'modalAdd'
                                    )
                                }
                                className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm"
                            >
                                <i className="fas fa-plus fa-sm text-white-50 mr-1" />
                                {addNewText}
                            </Button>
                        )}
                    </Col>
                </Row>
                {Object.keys(this.tableFilters).length > 0 && (
                    <TableFilter
                        {...this.props}
                        state={this.state}
                        setState={(state) => this.setState(state)}
                        tableFilters={this.tableFilters}
                    />
                )}
                <Row>
                    <Col>
                        {printRows && printRows.length > 0 && (
                            <Datatable
                                contextMessage={{
                                    singular: translate('item'),
                                    plural: translate('items'),
                                    message: translate('selected'),
                                }}
                                columns={columns}
                                data={printRows}
                                responsive={false}
                                highlightOnHover
                                striped
                                dense
                                selectableRowsHighlight
                                selectableRows={true}
                                onSelectedRowsChange={(rows) =>
                                    this.selectedRowsChange(rows)
                                }
                                pagination
                                paginationServer
                                paginationRowsPerPageOptions={rowsPerPage}
                                onChangeRowsPerPage={(rows) =>
                                    this.setLimit(rows)
                                }
                                sortServer
                                paginationTotalRows={totalRows}
                                paginationPerPage={limit}
                                paginationComponentOptions={{
                                    rowsPerPageText: translate('Rows per page'),
                                    rangeSeparatorText: translate('of'),
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: translate('All'),
                                }}
                                onChangePage={(page) => this.setPage(page)}
                                onSort={(field, order) =>
                                    this.setSort(field, order)
                                }
                                on
                            />
                        )}
                    </Col>
                </Row>
                {printRows && printRows.length > 0 && (
                    <Row className=" align-middle">
                        <Col md={2} className="p-0 real-align-middle">
                            <div>
                                <Form.Control
                                    className="p-0 m-0"
                                    size="xs"
                                    as="select"
                                    onChange={this.handleChangeMassAction}
                                    name="mass-change"
                                >
                                    <option key="" value="">
                                        {translate('Choose action')}
                                    </option>
                                    {this.functions?.massDelete && (
                                        <option
                                            key="massDelete"
                                            value="massDelete"
                                        >
                                            {translate('Delete selected items')}
                                        </option>
                                    )}
                                    {this.functions?.massApprove && (
                                        <option
                                            key="massApprove"
                                            value="massApprove"
                                        >
                                            {translate(
                                                'Approve selected items'
                                            )}
                                        </option>
                                    )}
                                    {this.functions?.deleteAll && (
                                        <option
                                            key="deleteAll"
                                            value="deleteAll"
                                        >
                                            {translate('Delete all items')}
                                        </option>
                                    )}
                                    {this.functions?.approveAll && (
                                        <option
                                            key="approveAll"
                                            value="approveAll"
                                        >
                                            {translate('Approve all items')}
                                        </option>
                                    )}
                                </Form.Control>
                            </div>
                        </Col>
                        <Col md={1} className="p-1 real-align-middle">
                            <Button
                                onClick={this.makeMassAction}
                                className="ml-2  btn btn-xs  font-smaller"
                            >
                                <i className="fas fa-mask" />
                            </Button>
                        </Col>
                    </Row>
                )}
                {this.modalWindowsCount > 0 &&
                    modal.map((data, index) => {
                        const {
                            show,
                            closeButton,
                            closeButtonText,
                            saveButton,
                            header,
                            footer,
                            text,
                            ComponentText,
                            saveButtonText,
                            ok,
                        } = data;
                        return (
                            <TemplateModal
                                key={index}
                                index={index}
                                options={{
                                    show,
                                    header,
                                    text,
                                    error,
                                    footer,
                                    closeButton,
                                    closeButtonText,
                                    saveButton,
                                    ok,
                                    saveButtonText,
                                    ComponentText,
                                }}
                                setErrorFalse={() =>
                                    this.setState({error: false})
                                }
                                modalClose={() => this.closeModal(index)}
                                modalAction={() => this.modalAction(index)}
                            />
                        );
                    })}
            </Container>
        );
    }
}

export default DataViewer;

import React  from "react";
import {Accordion, Card, Col, Row} from "react-bootstrap";


const ColorWordSection = (props) => {
    const {
        resultData,
        t: translate,
        keySetting,
        showedSetting,
        accOnClose,
        openedSetting
        } = props;
    return (
        <Card key='color_words_section'>
            <Accordion.Toggle as={Card.Header} eventKey={keySetting}>
                {translate(keySetting)}
            </Accordion.Toggle>
            <Accordion.Collapse onExit={accOnClose}
                                eventKey={keySetting}>
                <Card.Body>
                    <ul>
                        <li className={showedSetting === 'dataColorblue' ? "bg-info" : ""}
                            key={'blue'}
                            onClick={() => openedSetting('dataColor', 'blue')}
                        >
                            <Row>
                                <Col md={8}>Blue</Col>
                                <Col>{resultData['colorBlue']}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === 'dataColorred' ? "bg-info" : ""}
                            key={'red'}
                            onClick={() => openedSetting('dataColor', 'red')}
                        >
                            <Row>
                                <Col md={8}>Red</Col>
                                <Col>{resultData['colorRed']}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === 'dataColorgreen' ? "bg-info" : ""}
                            key={'green'}
                            onClick={() => openedSetting('dataColor', 'green')}
                        >
                            <Row>
                                <Col md={8}>Green</Col>
                                <Col>{resultData['colorGreen']}</Col>
                            </Row>
                        </li>
                        <li
                            className={showedSetting === 'dataColoryellow' ? "bg-info" : ""}
                            key={'yellow'}
                            onClick={() => openedSetting('dataColor', 'yellow')}
                        >
                            <Row>
                                <Col md={8}>Yellow</Col>
                                <Col>{resultData['colorYellow']}</Col>
                            </Row>
                        </li>
                    </ul>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );

}

export default ColorWordSection;
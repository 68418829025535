export const adminGetAllTextTypes = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_TEXTTYPES_REQUEST',
    });
    tlService.getAllTextTypes(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_TEXTTYPES_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_TEXTTYPES_FAILURE',
            payload: err
        }));
};

export const adminGetAllSettings = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_SETTINGS_REQUEST',
    });
    tlService.getAllSettings(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_SETTINGS_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_SETTINGS_FAILURE',
            payload: err
        }));
};


export const adminGetAllBenchmarkTemplates = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_BENCHMARK_TEMPLATES_REQUEST',
    });
    tlService.getBenchmarkTemplates(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_BENCHMARK_TEMPLATES_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_BENCHMARK_TEMPLATES_FAILURE',
            payload: err
        }));
};


export const adminGetAllCompanies = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_COMPANIES_REQUEST',
    });
    tlService.getFilteredCompanies(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_COMPANIES_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_COMPANIES_FAILURE',
            payload: err
        }));
};

export const adminGetAllCalcelements = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_CALCELEMENTS_REQUEST',
    });
    tlService.getAllCalcelements(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_CALCELEMENTS_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_CALCELEMENTS_FAILURE',
            payload: err
        }));
};

export const adminGetAllReturnValues = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_RETURN_VALUES_REQUEST',
    });
    tlService.getAllReturnValues(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_RETURN_VALUES_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_RETURN_VALUES_FAILURE',
            payload: err
        }));
};

export const adminGetAllGroups = (tlService) => (token, company_id = null) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_GROUPS_REQUEST',
    });
    const filters = company_id ? {company_id: company_id} : {};
    tlService.getFilteredGroups(token, {}, filters)
        .then((data) => dispatch({
            type: 'FETCH_ALL_GROUPS_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_GROUPS_FAILURE',
            payload: err
        }));
};
export const adminGetAllRights = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_RIGHTS_REQUEST',
    });
    tlService.getAllRights(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_RIGHTS_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_RIGHTS_FAILURE',
            payload: err
        }));
};
export const adminGetAllOptions = (tlService) => (token) => (dispatch) => {
    dispatch({
        type: 'FETCH_ALL_OPTIONS_REQUEST',
    });
    tlService.getAllOptions(token)
        .then((data) => dispatch({
            type: 'FETCH_ALL_OPTIONS_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((err) => dispatch({
            type: 'FETCH_ALL_OPTIONS_FAILURE',
            payload: err
        }));
};

export const adminUpdateRights = (rights) => (dispatch) => {
    dispatch({
        type: 'UPDATE_ALL_RIGHTS',
        payload: rights
    })
};

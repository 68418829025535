import React from 'react';
import {
    compose,
    convertFromErrorObject, convertLanguagesToFull,
    getAllModalFunctions, stripHtml
} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";


import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllOptions
} from "../../../actions";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import TranslationsHeader from "./translations-header";
import ModalAddEditTranslations from "../../modals/modal-add-edit-translations";
import backendLanguages from "../../../utils/backendlanguages.json";

class Translations extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2;
    extendedFilter = true;

    tableFilters = {
        value: {
            name: 'value',
            type: 'string',
            default: undefined
        },
        translation: {
            name: 'translation',
            type: 'string',
            default: undefined
        },
        locale_name: {
            name: 'locale_name',
            type: 'string',
            default: undefined
        },
        user_id: {
            name: 'user_id',
            type: 'integer',
            default: undefined
        },
        user_group_id: {
            name: 'user_group_id',
            type: 'integer',
            default: undefined
        },
        company_id: {
            name: 'company_id',
            type: 'integer',
            default: undefined
        },
        id: {
            name: 'id',
            type: 'integer',
            default: undefined
        }
    }


    functions = {
        getAll: this.props.tlService.getFilteredTranslations,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditTranslations,
        modalEdit: ModalAddEditTranslations,
        modalView: ModalAddEditTranslations,
        modalCopy: ModalAddEditTranslations,
    };


    async componentDidMount() {
        const {
            userData: {user},
            t: translate
        } = this.props;

        this.setState({Header: TranslationsHeader})

        this.columns_default = [
            {
                name: 'value',
                selector: 'value',
                sortable: true,
                cell: row => row['value']
            },
            {
                name: 'locale_name',
                selector: 'locale_name',
                sortable: true,
                grow: 0.4
            },
            {
                name: 'translation',
                selector: 'translation',
                sortable: true,
                cell: row => stripHtml(row['translation'])
            },

            {
                name: 'settings',
                selector: 'settings',
                sortable: true,
                cell: row => JSON.stringify(row['settings']),
                grow: 0.4
            },
            {
                name: 'user_id',
                selector: 'user_id',
                sortable: true,
                grow: 0.2
            },
            {
                name: 'user_group_id',
                selector: 'user_group_id',
                sortable: true,
                grow: 0.2
            },
            {
                name: 'company_id',
                selector: 'company_id',
                sortable: true,
                grow: 0.2
            },
            {
                name: 'edit',
                selector: 'id',
                grow: 0.5,
                cell: row => {
                    return (
                      <div className="btn-group">
                          <Button onClick={() => this.openModal(0, row, 'modalView')}><i className="fas fa-eye"/></Button>
                          <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                          <Button onClick={() => this.openModal(0, row, 'modalCopy')} className="ml-2"><i className="fas fa-copy"/></Button>
                          <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i className="fas fa-trash"/></Button>
                      </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = (user.user_type_id === 1);

        // check add right
        this.addButton = (user.user_type_id === 1);

        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                value: '',
                translation: '',
                locale_name: convertLanguagesToFull(this.props.i18n.language, backendLanguages),
                settings: '',
                user_id: 0,
                user_group_id: 0,
                company_id: 0
            },
            show: false,
            header: translate('Add Translation'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalCopy'] = {
            action: this.actionAdd,
            data: {
                id: false,
                value: '',
                translation: '',
                locale_name: '',
                settings: '',
                user_id: 0,
                user_group_id: 0,
                company_id: 0
            },
            show: false,
            header: translate('Copy Translation'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                value: '',
                translation: '',
                locale_name: '',
                settings: '',
                user_id: 0,
                user_group_id: 0,
                company_id: 0
            },
            show: false,
            header: translate('Edit Translation'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: false,
                value: '',
                translation: '',
                locale_name: '',
                settings: '',
                user_id: 0,
                user_group_id: 0,
                company_id: 0
            },
            show: false,
            header: translate('View Translation'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Translation'),
            footer: true,
            text: translate("Delete Translation?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Translations'),
            footer: true,
            text: translate('Delete Selected Translations'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {
                user_id: 0,
                user_group_id: 0,
                company_id: 0
            },
            limits: {
                limit: 100,
                offset: 0,
                order_by: 'value',
                order: 0
            },
            page: {
                pageHeader: translate('Translations'),
                addNewText: translate('Add new Translation'),
            }
        });
        super.componentDidMount();
    }

    checkRight = (data) => {

        if (data.user_id < 0){
            this.setState({error: '"user_id" should be 0 or more'});
            return false;
        }
        if (data.user_group_id < 0){
            this.setState({error: '"user_group_id" should be 0 or more'});
            return false;
        }
        if (data.company_id < 0){
            this.setState({error: '"company_id" should be 0 or more'});
            return false;
        }

        if (data.value.length === 0){
            this.setState({error: '"value" should not be empty'});
            return false;
        }

        try {
            if (data.settings.length > 0){
                JSON.parse(data.settings)
            }
        } catch (e) {
            console.log('"settings" should be a JSON object');
            this.setState({error: '"settings" should be a JSON object'});
            return false;
        }

        return true;
    }

    actionAdd = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token}
        } = this.props;

        if (!this.checkRight(data)){
            return false;
        }

        const body = {
            locale_name: data.locale_name,
            user_id: parseInt(data.user_id),
            user_group_id: parseInt(data.user_group_id),
            company_id: parseInt(data.company_id),
            settings: (data.settings.length > 0) ? JSON.parse(data.settings) : {},
            value: data.value,
            translation: data.translation
        }

        try {
            await tlService.addTranslations(token, body);
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    actionEdit = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        if (!this.checkRight(data)){
            return false;
        }

        const body = {
            locale_name: data.locale_name,
            user_id: parseInt(data.user_id),
            user_group_id: parseInt(data.user_group_id),
            company_id: parseInt(data.company_id),
            settings: (data.settings.length > 0) ? JSON.parse(data.settings) : {},
            value: data.value,
            translation: data.translation
        }

        try {
            await tlService.updateTranslations(token, parseInt(data.id), body);
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    actionDelete = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.deleteTranslations(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
  withTranslation(),
  withTLService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Translations);

import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class ModalViewBenchmarkTemplate extends Component {

    state = {
        tmpOptions: [],
        tmpLicenses: []
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    saveToOption(insertData = undefined) {
        const {index} = this.props;
        const {tmpOptions} = this.state;
        const newOptions = insertData || tmpOptions;
        this.props.modalUpdateData(index,{
            company_options: newOptions.reduce((accumulator, currentValue) => {
                accumulator[currentValue.option] = currentValue.value;
                return accumulator
            }, {})

        })
    }

    saveToLicenses(insertData = undefined) {
        const {index} = this.props;
        const {tmpLicenses} = this.state;
        const newLicenses = insertData || tmpLicenses;
        this.props.modalUpdateData(index, {
            company_licenses: newLicenses.filter(this.onlyUnique) || []
        })
    }

    getOption() {
        const {modalData: {modal: {data: {company_options}}}} = this.props;
        const opt = Object.keys(company_options).map((option) => {
            return {option, value: company_options[option]}
        });
        this.setState({tmpOptions: opt || []});
    }

    getLicenses() {
        const {modalData: {modal: {data: {company_licenses}}}} = this.props;
        this.setState({tmpLicenses: company_licenses || []});
    }

    componentDidMount() {
        this.getOption();
        this.getLicenses();
    }

    handleChange = (event) => {
        const {index} = this.props;
        const {name, value} = event.target;
        this.props.modalUpdateData(index,{[name]: value})
    };

    handleChangeLicenses= (event) => {
        let tmpLicenses = [];
        for (let [, value] of Object.entries(event.target.selectedOptions)) {
            tmpLicenses.push(value.value);
        }
        this.saveToLicenses(tmpLicenses);
        this.setState({tmpLicenses});

    };

    handleChangeDescription = (event) => {
        const {index} = this.props;
        const {name, value} = event.target;
        const {modalData: {modal: {data}}} = this.props;
        this.props.modalUpdateData(index,{
            description: {
                ...data.description,
                ...{[name]: value}
            }
        })
    };

    handleChangeOptions = (event) => {
        let tmpOptions = [...this.state.tmpOptions];
        const {name, value} = event.target;
        const option_found = name.match(/option_(\d+)/)
        if (option_found) {
            tmpOptions[parseInt(option_found[1])].option = value;
        }
        const value_found = name.match(/value_(\d+)/)
        if (value_found) {
            tmpOptions[parseInt(value_found[1])].value = value;
        }
        this.setState({tmpOptions});
        this.saveToOption(tmpOptions);
       // this.saveToLicenses(tmpLicenses);
    };


    addOption = (event) => {
        const {tmpOptions} = this.state;
        this.saveToOption();
        this.setState({tmpOptions: tmpOptions.concat([{option: '', value: ''}])})
    };

    deleteOption = (index) => {
        let tmpOptions = [...this.state.tmpOptions];
        tmpOptions.splice(index, 1);
        this.setState({tmpOptions});
        this.saveToOption(tmpOptions);
    }

    render() {
        const {
            t: translate,
            modalData: {modal: {data: {name, company_licenses, company_options}}},
            openData: {licenses}
        } = this.props;
        const {tmpOptions, tmpLicenses} = this.state;
        console.log('render modal');
        console.log(name, company_licenses, company_options);
        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Control

                                type="text"
                                name="name"
                                defaultValue={name}
                                disabled={true}
                                placeholder={translate("Enter name")}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="company_licenses">
                            <Form.Control
                                as="select"
                                multiple
                                value = {tmpLicenses}
                                name="company_licenses"
                                disabled={true}
                            >
                                {
                                    Object.keys(licenses).length > 0 && Object.keys(licenses).map((license) =>
                                        <option key={license}
                                                value={license}>{translate(licenses[license].name)}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">{translate("Options")}</h1>

                <Row>
                    <Col>
                        {
                            tmpOptions.map((opt, index) => {
                                    const {option, value} = opt;
                                    return (
                                        <Row key={index}>
                                            <Col md={4}>
                                                <Form.Group controlId={"option_" + index}>
                                                    <Form.Control
                                                        type="text"
                                                        name={"option_" + index}
                                                        value={option}
                                                        disabled={true}
                                                        placeholder={translate("Enter option name")}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group controlId={"value_" + index}>
                                                    <Form.Control
                                                        type="text"
                                                        name={"value_" + index}
                                                        value={value}
                                                        disabled={true}
                                                        placeholder={translate("Enter description")}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                            )
                        }

                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalViewBenchmarkTemplate);
import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {getDataFromModals} from "../../../utils";


export default function EditTermMainTab(props) {
    const [tmpWords, setTmpWords] = useState('');
    const [errorWords, setErrorWords] = useState(false);
    const [tmpLemma, setTmpLemma] = useState('');
    const [tmpTag, setTmpTag] = useState('');
    const [errorLemma, setErrorLemma] = useState(false);
    const [errorTag, setErrorTag] = useState(false);

    const {index} = props;
    const data = getDataFromModals(props, index);

    const {words ,lemma, tag} = data;

    if (words.length !== 0 && tmpWords.length === 0) {
        setTmpWords(JSON.stringify(words));
    }
    if (lemma.length !== 0 && tmpLemma.length === 0) {
        setTmpLemma(JSON.stringify(lemma));
    }
    if (tag.length !== 0 && tmpTag.length === 0) {
        setTmpTag(JSON.stringify(tag));
    }
    const {
        t: translate
    } = props;

    const setErrorState = (name, text) => {
        if (name === 'words')
            setErrorWords (text);
        else if (name === 'lemma')
            setErrorLemma (text);
        else if (name === 'tag')
            setErrorTag (text);
        return true;
    }

    const updateField = (name, text) => {
        if (name === 'words')
            setTmpWords (text);
        else if (name === 'lemma')
            setTmpLemma (text);
        else if (name === 'tag')
            setTmpTag (text);
        return true;
    }

    const handleChangeJSON = (event, props) => {
        const {name, value} = event.target;
        const {index, t: translate} = props;
        const data = getDataFromModals(props, index);

        updateField(name, value);

        let convertedData;
        try {
            convertedData = JSON.parse(value);
        } catch (e) {
            setErrorState(name, translate('must be an JSON array'));
            return true;
        }

        const checkData = name === 'words' ? data.lemma : data.words

        if (convertedData.length !== checkData.length) {
            setErrorState('words', translate('Lengths must be same'));
            setErrorState('lemma', translate('Lengths must be same'));
            props.modalUpdateData(index, {
                [name]: convertedData
            })
            return true;
        } else {
            setErrorState('words',  false);
            setErrorState('lemma',  false);
        }
        setErrorState(name,  false);
        props.modalUpdateData(index, {
            [name]: convertedData
        })
    }

    return (
        <>
            <Row>
                <Col md={1} className="p-2 font-weight-bold real-align-middle">{translate('words')}</Col>
                <Col className="p-2 font-weight-bold">
                    <Form.Control
                        isInvalid={!!errorWords}
                        size="xs"
                        type="text"
                        name="words"
                        value={tmpWords}
                        onChange={e => handleChangeJSON(e, props)}
                        placeholder={translate("words")}/>
                    {errorWords && <small className="text-danger">{errorWords}</small>}
                </Col>
                <Col md={1} className="p-2 font-weight-bold real-align-middle">{translate('lemma')}</Col>
                <Col className="p-2">
                    <Form.Control
                        isInvalid={!!errorLemma}
                        size="xs"
                        type="text"
                        name="lemma"
                        value={tmpLemma}
                        onChange={e => handleChangeJSON(e, props)}
                        placeholder={translate("lemmas")}/>
                    {errorLemma && <small className="text-danger">{errorLemma}</small>}
                </Col>
                <Col md={1} className="p-2 font-weight-bold real-align-middle">{translate('tag')}</Col>
                <Col className="p-2">
                    <Form.Control
                        isInvalid={!!errorTag}
                        size="xs"
                        type="text"
                        name="tag"
                        value={tmpTag}
                        onChange={e => handleChangeJSON(e, props)}
                        placeholder={translate("tag")}/>
                    {errorLemma && <small className="text-danger">{errorTag}</small>}
                </Col>
            </Row>
        </>
    )
}


import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {adminUpdateRights} from "../../../actions";


class ModalViewUser extends Component {

    state = {
        groups: [],
        tmpOptions: [],
        tmpLicenses: [],
        tmpRights: [],
        password1: '',
        password2: '',
        passwordInvalid: false,
        company_id: 0
    }


    getOption() {
        const {modalData: {modal: {data: {user_options}}}} = this.props;
        const opt = user_options && Object.keys(user_options).map((option) => {
            return {option, value: user_options[option]}
        });
        this.setState({tmpOptions: opt || []});
    }

    getRights() {
        const {modalData: {modal: {data: {user_rights}}}} = this.props;
        this.setState({tmpRights: user_rights || []});
    }

    componentDidMount() {
        this.getOption();
        this.getRights();
    }

    render() {
        const {
            t: translate, i18n,
            adminData: {companies, groups, rights},
            modalData: {
                modal: {
                    data: {
                        user_rights, user_options, language_id, country_id, user_name,
                        email, password, first_name, last_name, zip_code, city, street, house_number,
                        enabled, user_group_id, user_type_id, license_id
                    }

                }
            },
            openData: {licenses, countries, languages, company_id, user_types}
        } = this.props;
        const {tmpOptions, tmpRights, password1, password2, passwordInvalid} = this.state;
        console.log('render modal');
        console.log({
            email, password, first_name, last_name, zip_code, city, street, house_number,
            enabled, user_group_id, user_type_id, license_id, user_options, user_rights
        });
        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="user_name">
                            <Form.Label>{translate('User name')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="user_name"
                                value={user_name}
                                 placeholder={translate("Enter user_name")}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="email">
                            <Form.Label>{translate('Email')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="email"
                                name="email"
                                defaultValue={email}
                                placeholder={translate("Enter email")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="password1">
                            <Form.Label>{translate('Password')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                isInvalid={passwordInvalid}
                                type="password"
                                name="password1"
                                defaultValue={password1}
                                placeholder={translate("Enter password")}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="password2">
                            <Form.Label>{translate('Repeat password')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                isInvalid={passwordInvalid}
                                type="password"
                                name="password2"
                                defaultValue={password2}
                                placeholder={translate("Re-enter password")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="first_name">
                            <Form.Label>{translate('First name')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="first_name"
                                defaultValue={first_name}
                                placeholder={translate("Enter first_name")}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="last_name">
                            <Form.Label>{translate('Last name')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="last_name"
                                defaultValue={last_name}
                                placeholder={translate("Enter last_name")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="zip_code">
                            <Form.Label>{translate('Zip code')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="zip_code"
                                defaultValue={zip_code}
                                placeholder={translate("Enter zip_code")}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="city">
                            <Form.Label>{translate('City')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="city"
                                defaultValue={city}
                                placeholder={translate("Enter city")}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="street">
                            <Form.Label>{translate('Street')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="street"
                                defaultValue={street}
                                placeholder={translate("Enter street")}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="house_number">
                            <Form.Label>{translate('House number')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                type="text"
                                name="house_number"
                                defaultValue={house_number}
                                placeholder={translate("Enter house_number")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="country_id">
                            <Form.Label>{translate('Country')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={country_id}
                                name="country_id"
                                as="select">
                                {
                                    Object.keys(countries).length > 0 && Object.keys(countries).map((country) =>
                                        <option key={country}
                                                value={country}>{countries[country].full_name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="language_id">
                            <Form.Label>{translate('Language')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={language_id || i18n.language}
                                name="language_id"
                                as="select">
                                {
                                    Object.keys(languages).length > 0 && Object.keys(languages).map((language) =>
                                        <option key={language}
                                                value={language}>{languages[language].lang_name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="company_id">
                            <Form.Label>{translate('Company')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={company_id}
                                name="company_id"
                                as="select">
                                {
                                    Object.keys(companies).length > 0 && Object.keys(companies).map((key) =>
                                        <option key={key} value={key}>{companies[key].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="user_group_id">
                            <Form.Label>{translate('Group')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={user_group_id}
                                name="user_group_id"
                                as="select">
                                {
                                    Object.keys(groups).length > 0 && Object.keys(groups).map((key) =>
                                        <option key={key} value={key}>{groups[key].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="license_id">
                            <Form.Label>{translate('License')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={license_id}
                                name="license_id"
                                as="select">
                                {
                                    Object.keys(licenses).length > 0 && Object.keys(licenses).map((key) =>
                                        <option key={key} value={key}>{licenses[key].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="user_type_id">
                            <Form.Label>{translate('User type')}</Form.Label>
                            <Form.Control
                                disabled={true}
                                //onChange={this.handleChange}
                                value={user_type_id}
                                name="user_type_id"
                                as="select">
                                {
                                    Object.keys(user_types).length > 0 && Object.keys(user_types).map((key) =>
                                        <option key={key} value={key}>{user_types[key].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Form.Group controlId="enabled">
                            <Form.Check
                                disabled={true}
                                checked={enabled}
                                //onChange={this.handleChangeEnabled}
                                name="enabled"
                                type="checkbox"
                                label={translate("Is user enabled?")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <h1 className="h5 mb-0 text-gray-800 my-3">Rights</h1>

                <Row>
                    <Col>
                        <Row>
                            <Col md={5}><Form.Label>{translate('Right')}</Form.Label></Col>
                            <Col md={5}><Form.Label>{translate('Group')}</Form.Label></Col>
                        </Row>
                        {
                            tmpRights.map((value, index) => {
                                const {right_id, user_group_id} = value;
                                return (
                                    <Row key={index}>
                                        <Col md={5}>
                                            <Form.Group controlId={"right" + index}>

                                                <Form.Control
                                                    disabled={true}
                                                    as="select"
                                                    value={right_id}
                                                    name={"right" + index}
                                                >
                                                    <option key="0" value="0">{translate('Select right')}</option>
                                                    {
                                                        Object.keys(rights).length > 0 && Object.keys(rights).map((key) => {
                                                            if (key >= 500 || parseInt(user_type_id) === 2) {
                                                                return (<option key={key}
                                                                                value={key}>{rights[key]['description']}</option>)
                                                            }
                                                            return '';
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={5}>
                                            <Form.Group controlId={"user_group" + index}>

                                                <Form.Control
                                                    disabled={true}
                                                    value={user_group_id}
                                                    name={"user_group" + index}
                                                    as="select">
                                                    <option key="0" value="0">{translate('No group')}</option>
                                                    {
                                                        Object.keys(groups).length > 0 && Object.keys(groups).map((key) =>
                                                            <option key={key} value={key}>{groups[key].name}</option>
                                                        )
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>

                                        </Col>

                                    </Row>

                                )
                            })
                        }
                    </Col>
                </Row>




                <h1 className="h5 mb-0 text-gray-800 my-3">{translate("Options")}</h1>

                <Row>
                    <Col>
                        <Row>
                            <Col md={4}><Form.Label>{translate('Option')}</Form.Label></Col>
                            <Col md={7}><Form.Label>{translate('Value')}</Form.Label></Col>
                        </Row>
                        {
                            tmpOptions.map((opt, index) => {
                                    const {option, value} = opt;
                                    return (
                                        <Row key={index}>
                                            <Col md={4}>
                                                <Form.Group controlId={"option_" + index}>
                                                    <Form.Control
                                                        disabled={true}
                                                        type="text"
                                                        name={"option_" + index}
                                                        value={option}
                                                       // onChange={this.handleChangeOptions}
                                                        placeholder={translate("Enter option name")}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group controlId={"value_" + index}>
                                                    <Form.Control
                                                        disabled={true}
                                                        type="text"
                                                        name={"value_" + index}
                                                        value={value}
                                                       // onChange={this.handleChangeOptions}
                                                        placeholder={translate("Enter description")}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={1}>

                                            </Col>
                                        </Row>
                                    )
                                }
                            )
                        }

                    </Col>
                </Row>



            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            adminUpdateRights: adminUpdateRights
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalViewUser)
;
import React, {useState, useEffect} from "react";
import {Form} from "react-bootstrap";
import {getDataFromModals, onlyUnique} from "../../utils";


export default function LicenseTable(props) {

    const {licenses, index, licenseFieldName, modalUpdateData, multiple, t:translate} = props;

    const [tmpLicenses, setTmpLicenses] = useState(multiple ? [] : 0);
    const data = getDataFromModals(props, index);

    // get licenses on component mount
    if (JSON.stringify(tmpLicenses) !== JSON.stringify(data[licenseFieldName])) {
        setTmpLicenses(data[licenseFieldName]);
    }

    const saveToLicenses = (insertData = undefined) => {
        let newLicenses = insertData || tmpLicenses;
        newLicenses = multiple ? newLicenses.filter(onlyUnique) || []
            : newLicenses || 0;
        modalUpdateData(index, {[licenseFieldName]: newLicenses})
    }

    const handleChangeLicenses = (event) => {
        let newTmpLicenses = [];
        for (let [, value] of Object.entries(event.target.selectedOptions)) {
            if (multiple) {
                newTmpLicenses.push(value.value);
            } else {
                newTmpLicenses = value.value;
            }
        }

        saveToLicenses(newTmpLicenses);
        //setTmpLicenses(newTmpLicenses);
    };

    console.log('tmpLicenses', tmpLicenses)
    return (
        <Form.Group controlId="company_licenses">
            <Form.Label>{translate('License')}</Form.Label>
            <Form.Control
                size="xs"
                as="select"
                multiple={multiple}
                value={tmpLicenses}
                name="company_licenses"
                onChange={handleChangeLicenses}>
                {
                    Object.keys(licenses).length > 0 && Object.keys(licenses).map((license) =>
                        <option key={license}
                                value={license}>{translate(licenses[license].name)}</option>
                    )
                }
            </Form.Control>
        </Form.Group>
    );
}
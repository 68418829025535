export const loginUserLDAP = (tlService) => (serviceId, remember) => (dispatch) => {
    dispatch({type: 'FETCH_USER_LOGIN_REQUEST'});
    tlService.loginUserLDAP(serviceId)
        .then((data) => {
            if (remember) {
                localStorage.setItem('token', data.token);
            }
            dispatch({type: 'FETCH_USER_LOGIN_SUCCESS', payload: data});
            tlService.meUser(data.token)
                .then((data) => dispatch({type: 'FETCH_MEUSER_SUCCESS', payload: data}))
                .catch((error) => dispatch({type: 'FETCH_MEUSER_FAILURE', payload: error}));
        })
        .catch((error) => dispatch({type: 'FETCH_USER_LOGIN_FAILURE', payload: error}));
};

export const userGetAllTermCategories = (tlService) => (token) => (dispatch) => {
  //fullcount
    const asyncCall = async (token, limit) => {
      let res = await tlService.getFilteredTermCategories(token,{limit})
      let data = res.data || [];
      for (let i = res.data.length; i < res.fullcount; i += limit){
        res = await tlService.getFilteredTermCategories(token,{offset: i,limit})
        const tData = res.data || [];
        data = [...data, ...tData]
      }
      return data
    }

    dispatch({
        type: 'FETCH_TERM_CATEGORIES_REQUEST',
    });
  asyncCall(token, 1000)
        .then((data) =>{
          console.log(data);
          dispatch({
            type: 'FETCH_TERM_CATEGORIES_SUCCESS',
            payload: data
          })
        })
        .catch((err) => dispatch({
            type: 'FETCH_TERM_CATEGORIES_FAILURE',
            payload: err
        }));
};

export const userSetToken = (token) => {
    return {
        type: 'USER_SET_TOKEN',
        payload: token
    };
};

export const userSetUser = (tlService) => (token) => (dispatch) => {
  localStorage.setItem('token', token)
  dispatch({
    type: 'USER_SET_TOKEN',
    payload: token
  });
  tlService.meUser(token)
    .then((data) => dispatch({type: 'FETCH_MEUSER_SUCCESS', payload: data}))
    .catch((error) => dispatch({type: 'FETCH_MEUSER_FAILURE', payload: error}));
};

export const userSetAlertState = () => (alert) => (dispatch) => {
    dispatch({
        type: 'USER_SET_ALERT_STATE',
        payload: !!alert
    });
};

export const renewUser = (tlService) => (token, remember, ttl = 9999999) => (dispatch) => {
    tlService.renewUser(token,ttl)
        .then((data) => {
            if (remember) {
                localStorage.setItem('token', data.token);
            }
            dispatch({type: 'FETCH_USER_RENEW_SUCCESS', payload: data});
        })
        .catch((error) => dispatch({type: 'FETCH_USER_RENEW_FAILURE', payload: error}));
};

export const loginUser = (tlService) => (login, password, remember) => (dispatch) => {
    dispatch({type: 'FETCH_USER_LOGIN_REQUEST'});
    tlService.loginUser(login, password, 9999999)
        .then((data) => {
            if (remember) {
                localStorage.setItem('token', data.token);
            }
            dispatch({type: 'FETCH_USER_LOGIN_SUCCESS', payload: data});
            tlService.meUser(data.token)
                .then((data) => dispatch({type: 'FETCH_MEUSER_SUCCESS', payload: data}))
                .catch((error) => dispatch({type: 'FETCH_MEUSER_FAILURE', payload: error}));
        })
        .catch((error) => dispatch({type: 'FETCH_USER_LOGIN_FAILURE', payload: error}));
};

export const signupUser = (tlService) => (state) => (dispatch) => {
    dispatch({type: 'FETCH_SIGNUP_REQUEST'});
    tlService.signupUser(state)
        .then((data) => {
            //localStorage.setItem('token', data.jwt);
            dispatch({type: 'FETCH_SIGNUP_SUCCESS', payload: data.user})
        })
        .catch((error) => dispatch({type: 'FETCH_SIGNUP_FAILURE', payload: error}));
};

export const meUser = (tlService) => (token) => (dispatch) => {
    dispatch({type: 'FETCH_MEUSER_REQUEST'});
    tlService.meUser(token)
        .then((data) => dispatch({type: 'FETCH_MEUSER_SUCCESS', payload: data}))
        .catch((error) => dispatch({type: 'FETCH_MEUSER_FAILURE', payload: error}));
};

export const forgotPasswordUser = (tlService) => (data) => (dispatch) => {
    dispatch({type: 'FETCH_FORGOTPASSWORD_REQUEST'});
    tlService.forgotPasswordUser(data)
        .then((data) => dispatch({type: 'FETCH_FORGOTPASSWORD_SUCCESS', payload: data}))
        .catch((error) => dispatch({type: 'FETCH_FORGOTPASSWORD_FAILURE', payload: error}));
};

export const clearUser = () => () => (dispatch) => {
    localStorage.removeItem('token');
    dispatch({type: 'USER_CLEAR'});
};

export const messagesUser = (tlService) => (token, user) => (dispatch) => {
    dispatch({type: 'FETCH_MESSAGES_REQUEST'});
    tlService.getAllMessages(token,user)
        .then((data) => dispatch({
            type: 'FETCH_MESSAGES_SUCCESS',
            payload: data.data !== undefined && data.data.length > 0 ? data.data : []
        }))
        .catch((error) => dispatch({type: 'FETCH_MESSAGES_FAILURE', payload: error}));
};

import React from 'react';
import {
    compose,
    convertFromErrorObject,
    getAllModalFunctions
} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";


import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllOptions
} from "../../../actions";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import ModalAddEditRegexes from "../../modals/modal-add-edit-regexes";
import RegexHeader from "./regex-header";


class Regexes extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        expression: {
            name: 'expression',
            type: 'string',
            default: undefined
        }
    }


    functions = {
        getAll: this.props.tlService.getFilteredRegexes,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditRegexes,
        modalEdit: ModalAddEditRegexes,
        modalView: ModalAddEditRegexes,
    };


    async componentDidMount() {
        const {
            userData: {user},
            t: translate
        } = this.props;

        this.setState({Header: RegexHeader})

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'category',
                selector: 'category',
                sortable: true
            },
            {
                name: 'expression',
                selector: 'expression',
                sortable: true
            },
            {
                name: 'include',
                selector: 'include',
                sortable: true,
                cell: row => row.include.length > 100 ? row.include.substring(0,100) + '...' : row.include

            },
            {
                name: 'exclude',
                selector: 'exclude',
                sortable: true,
                cell: row => row.exclude.length > 100 ? row.exclude.substring(0,100) + '...' : row.exclude

            },
            {
                name: 'edit',
                selector: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            <Button onClick={() => this.openModal(0, row, 'modalView')}><i className="fas fa-eye"/></Button>
                            <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                            <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i className="fas fa-trash"/></Button>
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = (user.user_type_id === 1);

        // check add right
        this.addButton = (user.user_type_id === 1);

        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                category: 0,
                expression: '',
                include: '',
                exclude: ''
            },
            show: false,
            header: translate('Add Regex'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                category: 0,
                expression: '',
                include: '',
                exclude: ''
            },
            show: false,
            header: translate('Edit Regex'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                category: 0,
                expression: '',
                include: '',
                exclude: ''
            },
            show: false,
            header: translate('View Regex'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Regex'),
            footer: true,
            text: translate("Delete Regex?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Regexes'),
            footer: true,
            text: translate('Delete Selected Regexes'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {
            },
            limits: {
                limit: 100,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Regexes'),
                addNewText: translate('Add new regex'),
            }
        });
        super.componentDidMount();
    }

    checkRight = (data) => {

        if (data.category < 0){
            this.setState({error: '"category" should be 0 or more'});
            return false;
        }

        if (data.expression.length === 0){
            this.setState({error: '"expression" should not be empty'});
            return false;
        }

        try {
            if (data.include.length > 0){
                JSON.parse(data.include)
            }
        } catch (e) {
            console.log('"include" should be a JSON array');
            this.setState({error: '"include" should be a JSON array'});
            return false;
        }

        try {
            if (data.exclude.length > 0){
                JSON.parse(data.exclude)
            }
        } catch (e) {
            console.log('"exclude" should be a JSON array');
            this.setState({error: '"exclude" should be a JSON array'});
            return false;
        }
        return true;
    }

    actionAdd = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token}
        } = this.props;

        if (!this.checkRight(data)){
            return false;
        }

        const body = {
            category: parseInt(data.category),
            expression: data.expression
        }
        if (data?.include)
            body['include']= data.include;
        if (data?.exclude)
            body['exclude']= data.exclude;

        try {
            await tlService.addRegex(token, body);
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    actionEdit = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        if (!this.checkRight(data)){
            return false;
        }

        const body = {
            category: parseInt(data.category),
            expression: data.expression
        }
        if (data?.include)
            body['include']= data.include;
        if (data?.exclude)
            body['exclude']= data.exclude;

        try {
            await tlService.updateRegex(token, parseInt(data.id), body);
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

    actionDelete = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.deleteRegex(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        return true;
    }

}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Regexes);

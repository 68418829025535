import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './components/app';
import {HelmetProvider} from "react-helmet-async";
import TLService from './services/tl-service';
import {TLServiceProvider} from './components/tl-api-context';
import {Provider} from 'react-redux';
import Spinner from "./components/elements/spinner";

import store from './store';

import {userSetToken} from "./actions";
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {convertLanguagesToFull} from "./utils";
import backendLanguages from "./utils/backendlanguages.json";
// is for const LazyPDFDocument = React.lazy(() => import("./PDFPreview");


// disable console.log in production!

if (process.env.REACT_APP_CONSOLE_LOG === "false") {

    // eslint-disable-next-line
    console = {};
    // eslint-disable-next-line
    console.log = (...args) => {
    };
    // eslint-disable-next-line
    console.error = (...args) => {
    };
    window.console = console;
}

const tlService = new TLService();

// get storageToken
const storageToken = localStorage.getItem('token');
if (storageToken && storageToken !== 'null') {
    store.dispatch(userSetToken(storageToken));
}

TLService.localGetAPIBase().then(api => {

    tlService._apiBase = api;

    i18n
        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'de',
            debug: true,
            cleanCode: true,
            lowerCaseLng: true,
            load: 'languageOnly',
            backend: {
                customHeaders: () => {
                    const storageToken = localStorage.getItem('token');
                    if (storageToken && storageToken !== 'null') {
                        return {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Access-Control-Max-Age': 600,
                            'Authorization': `Bearer ${storageToken}`
                        };
                    }
                    return {
                        'Content-Type': 'application/json',
                        'Access-Control-Max-Age': 600,
                        'Accept': 'application/json'
                    };
                },
                loadPath: lng => {
                    return api + `/translations/view/${convertLanguagesToFull(lng, backendLanguages)}`;
                }
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            }
        });

    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<Spinner/>}>
                <TLServiceProvider value={tlService}>
                    <HelmetProvider>
                        <Router>
                            <App/>
                        </Router>
                    </HelmetProvider>
                </TLServiceProvider>
            </Suspense>
        </Provider>
        , document.getElementById('root'));


});




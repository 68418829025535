import React, {Component} from "react";
import Footer from "../../page-elements/footer";
import TopBar from "../../page-elements/top-bar";
import Sidebar from "../../page-elements/sidebar";

// pages
import {
    Allowlists,
    TextBins,
    Dashboard,
    Users,
    Groups,
    Companies,
    Rights,
    Messages,
    Abbreviations,
    ExceptionWords,
    Enduser,
    BenchmarkTemplates,
    Benchmarks,
    Terms,
    TermsByCategory,
    Regexes, Translations
} from "../../pages";


class MainPage extends Component {

    components = {
        dashboard: Dashboard,
        users: Users,
        groups: Groups,
        companies: Companies,
        allowlists: Allowlists,
        textbins : TextBins,
        rights: Rights,
        messages: Messages,
        abbreviations: Abbreviations,
        exceptionwords: ExceptionWords,
        enduser: Enduser,
        benchmark_templates: BenchmarkTemplates,
        benchmarks: Benchmarks,
        terms: Terms,
        terms_by_category: TermsByCategory,
        regexes: Regexes,
        translations: Translations
    };

    render() {
        const {match} = this.props;
        const InnerPage = this.components[this.props.page] ? this.components[this.props.page] :  this.components['dashboard'];

        return (
            <div id="wrapper">
                <Sidebar match={match}/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar match={match}/>
                        <InnerPage match={match} />
                    </div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default MainPage;
import React, { useState } from "react";
import { withTranslation } from 'react-i18next';
import { Button, Col, Container, Form, Row, Table, Alert } from "react-bootstrap";
import { compose, getAllModalFunctions, getDataFromModals } from "../../../utils";
import { withTLService } from "../../hoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BaseModal from "../base-modal";
import { adminGetAllGroups } from "../../../actions";
import Spinner from "../../elements/spinner";

class ModalCopyBenchmarks extends BaseModal {

  state = {
    BenchmarkID: 0,
    tcList: [],
    benchmark: {}
  }

  render() {

    const { index } = this.props;
    const data = getDataFromModals(this.props, index);
    const { createUGC } = data;
    const {
      t: translate,
      openData: { licenses },
      adminData,
      userData: { token },
      tlService,
    } = this.props;
    const listTC = this.state.tcList !== undefined ? this.state.tcList : null;


    console.log('render modal Modal Copy Benchmark', this.props, this.state);

    const handleCheckboxChange = (tcId, field) => {
      const updatedData = listTC.map(item => {
        if (item.id === tcId) {
          switch (field) {
            case 'ignore':
              return { ...item, clone: false, ugc: false, child: false, ignore: !item.ignore };
            case 'clone':
              if (item.child && !item.clone)
                item.child = false;
              if (item.ignore)
                item.ignore = false;
              return { ...item, clone: !item.clone };
            case 'child':
              if (item.clone && !item.child)
                item.clone = false;
              if (item.ignore)
                item.ignore = false;
              return { ...item, child: !item.child };
            case 'ugc':
              if (item.ignore)
                item.ignore = false;
              return { ...item, ugc: !item.ugc };

            default:
              return item;
          }
        }
        return item;
      });
      this.setState({ ...this.state, tcList: updatedData });
      // console.log("checked", updatedData);

      if (this.state.tcList.length > 0) {
        this.props.modalUpdateData(index, { ...data, benchmark: this.state.benchmark, benchmarkID: this.state.BenchmarkID, tcList: updatedData });
      }
    };

    const handleClick = async () => {
      this.setState({ ...this.state, isLoading: true });

      const bID = parseInt(this.state.BenchmarkID);
      if (isNaN(bID) || !(bID > 0)) {
        this.setState({ ...this.state, errMessage: 'Invalid benchmark id', isLoading: false });
        return false;
      }

      let companyIDs = [];
      try {
        companyIDs = JSON.parse(this.state.companiesList);
      } catch (error) {
        console.log("error on company list input", error);
        this.setState({ ...this.state, errMessage: 'Error on company list input', isLoading: false });
        return false;
      }

      if (!Array.isArray(companyIDs) || companyIDs.length === 0) {
        console.log('invalid or empty company list');
        this.setState({ ...this.state, errMessage: 'Invalid or empty company list', isLoading: false });
        return false;
      }

      console.log("data in comapny", data);
      const resCompanies = [];
      for (let c of companyIDs) {
        try {
          const resCompany = await tlService.getCompanyById(token, c);
          if (resCompany.id === c) {
            resCompanies.push(resCompany);
          } else {
            console.log("ERROR1", resCompany, c);
            this.setState({ ...this.state, errMessage: 'Invalid company ' + c, isLoading: false });
            return false;
          }
        } catch (e) {
          console.log("error geting companies", e);
          this.setState({ ...this.state, errMessage: 'Company ID ' + c + ' does not exist', isLoading: false });
          return false;
        }
      }

      if (resCompanies.length > 0) {
        this.props.modalUpdateData(index, { ...data, resCompanies: resCompanies });
      } else {
        this.setState({ ...this.state, errMessage: 'Empty Company List', isLoading: false });
        return false;
      }

      let benchmark;
      try {
        const res = await tlService.getFilteredBenchmarks(
          token,
          {},
          { "extended_filter": JSON.stringify([["&id", `=${bID}`]]) }
        );
        benchmark = res.data.length > 0 ? res.data[0] : null;

      } catch (e) {
        console.log("error on getting benchmark", e);
        this.setState({ ...this.state, errMessage: 'Benchmark ID ' + bID + ' does not exist', isLoading: false });
        return false;
      }

      if (benchmark) {
        this.setState({ benchmark: benchmark });
        let settingsList = {};
        if (adminData) {
          settingsList = adminData.settings_dir;
        } else {
          console.log("no admin data");
          this.setState({ ...this.state, errMessage: 'Can not get settings_dir from adminData.', isLoading: false });
          return false;
        }

        const settingsTC = {};

        for (const [setting_, value_] of Object.entries(settingsList)) {
          if (value_.hasOwnProperty('values')) {
            for (const [key_, valueArray] of Object.entries(value_["values"])) {
              if (valueArray[0] === 'category' || key_ === '_list') {
                if (settingsTC.hasOwnProperty(setting_))
                  settingsTC[setting_].push(key_);
                else
                  settingsTC[setting_] = [key_];
              }
            }
          }
        }
        console.log("settings with category", settingsTC);

        let settings = {};
        let new_settings = {};
        for (let set of Object.keys(benchmark["settings"])) {
          if (!(set in settingsTC)) {
            settings[set] = benchmark["settings"][set];
          } else {
            new_settings[set] = benchmark["settings"][set];
          }
        }

        let formData = [];
        for (let set of Object.keys(new_settings)) {
          if (settingsTC[set].includes('_list')) {
            for (let tc of Object.keys(new_settings[set].values._list)) {
              formData.push({
                id: parseInt(tc),
                name: 'cat_name_' + tc,
                settings: set,
                field: '_list'
              });
            }
          } else {
            for (let tc of settingsTC[set]) {
              formData.push({
                id: new_settings[set]["values"][tc],
                name: 'cat_name_' + new_settings[set]["values"][tc],
                settings: set,
                field: tc
              });
            }
          }

        }

        for (let tc in formData) {
          try {
            const resTermCategories = await tlService.getFilteredTermCategories(
              token,
              {},
              { id: formData[tc].id }
            );
            if (resTermCategories.data.length > 0) {
              formData[tc]["name"] = resTermCategories.data[0].name.en;
              formData[tc]["clone"] = false;
              formData[tc]["ugc"] = true;
              formData[tc]["ignore"] = false;
              formData[tc]["child"] = true;
              formData[tc]["tc_data"] = resTermCategories.data[0];
            }

          } catch (e) {
            console.log("error geting term category", tc, e);
            this.setState({ ...this.state, errMessage: 'Error on getting term category ' + formData[tc].id, isLoading: false });
            return false;
          }
        }

        formData.sort((a, b) => a.id - b.id);

        this.setState({ ...this.state, tcList: formData });
        if (this.state.tcList.length > 0) {
          this.props.modalUpdateData(index, { ...data, benchmark: this.state.benchmark, benchmarkID: this.state.BenchmarkID, tcList: this.state.tcList });
        }

      } else {
        this.setState({ ...this.state, errMessage: 'Benchmark ID ' + bID + ' does not exist', isLoading: false });
        return false;
      }

      this.setState({ ...this.state, errMessage: undefined, isLoading: false });

    };

    if (this.state.isLoading) {
      return <Spinner />
    }

    return (
      <Container>
        <Row>
          <Col>
            <Alert show={this.state.errMessage !== undefined} variant="danger">
              <Alert.Heading>Error</Alert.Heading>
              <p>
                {this.state.errMessage}
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => this.setState({ ...this.state, errMessage: undefined })} variant="outline-danger">
                  Close
                </Button>
              </div>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Form.Group controlId="BenchmarkID">
              <Form.Label>Benchmark ID to Copy:</Form.Label>
              <Form.Control
                type="text"
                name="BenchmarkID"
                defaultValue={this.state.BenchmarkID || '1010'}
                onChange={this.handleChangeState}
                placeholder="1000"
              />

            </Form.Group>
          </Col>
          <Col sm={8}>
            <Form.Group controlId="companyIDs">
              <Form.Label>Company IDs:</Form.Label>
              <Form.Control
                type="text"
                name="companiesList"
                defaultValue={this.state.companiesList || ''}
                onChange={this.handleChangeState}
                placeholder={"company id list [1,2]"} />
            </Form.Group>
          </Col>
          <Col sm={1} className="d-flex align-items-center">
            <Button onClick={handleClick}>Fetch</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {listTC && listTC.length > 0 && (
              <Table striped bordered hover>
                <thead>
                  <tr>

                    <td>TC ID</td>
                    <td>Category Name</td>
                    <td>Settings</td>
                    <td>Clone</td>
                    <td>UGC</td>
                    <td>Child</td>
                    <td>Ignore</td>

                  </tr>
                </thead>
                <tbody>
                  {listTC.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.settings}</td>
                      <td><center>
                        <input
                          type="checkbox"
                          checked={item.clone}
                          onChange={() => handleCheckboxChange(item.id, 'clone')}
                        /></center>
                      </td>
                      <td>
                        <center>
                          <input
                            type="checkbox"
                            checked={item.ugc}
                            onChange={() => handleCheckboxChange(item.id, 'ugc')}
                          /></center>
                      </td>
                      <td>
                        <center>
                          <input
                            type="checkbox"
                            checked={item.child}
                            onChange={() => handleCheckboxChange(item.id, 'child')}
                          /></center>
                      </td>
                      <td>
                        <center>
                          <input
                            type="checkbox"
                            checked={item.ignore}
                            disabled={adminData.settings_dir[item.settings].settings.protected}
                            onChange={() => handleCheckboxChange(item.id, 'ignore')}
                          /></center>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>

        <Row>

          <Col>
            <Form.Group controlId="createUGC">
              <Form.Check
                size="xs"
                checked={createUGC}
                onChange={e => { this.handleChange(e, 'createUGC', true) }}
                name="createUGC"
                type="checkbox"
                label={"Create BenchmarkUGC for each company"} />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    )
  }
}


const
  mapStateToProps = ({ userData, modalData, openData, adminData }) => {
    return { userData, openData, modalData, adminData };
  };

const
  mapDispatchToProps = (dispatch, { tlService }) => {
    return bindActionCreators({
      ...getAllModalFunctions(),
      adminGetAllGroups: adminGetAllGroups(tlService)
    }, dispatch);
  };

export default compose(
  withTranslation(),
  withTLService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ModalCopyBenchmarks);
import React from "react";
import {withTranslation} from 'react-i18next';
import {Button, Col, Container, Form, Row, Tab, Tabs} from "react-bootstrap";
import {
    compose,
    convertArrayToText,
    convertTextToTT,
    getAllModalFunctions,
    getDataFromModals
} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Spinner from "../../elements/spinner";
import EditTermMainTab from "./edit-term-main-tab";
import BaseModal from "../base-modal";
import SettingTable from "../../modal-elements/setting-table";
import config from "../../../config";


class ModalAddEditTerm extends BaseModal {

    state = {
        submitted: false,
        rawWords: '',
        tmpReplacements: [],
        lemma: "",
        words: "",
        tag: "",
        lemma_error: false,
        words_error: false,
    }


    getAllData() {
        console.log('getallData')
    }


    proceedRawWord = async () => {
        const {rawWords} = this.state
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {locale_name} = data;
        const {
            userData: {token},
            tlService,
        } = this.props;
        this.setState({submitted: true})
        const convertedText = await convertTextToTT(token, tlService, rawWords, locale_name)

        if (convertedText) {
            this.props.modalUpdateData(index, {
                lemma: convertedText.lemma,
                words: convertedText.words,
                tag: convertedText.tag
            })
        }

        console.log('convertedText', convertedText);

        this.setState({submitted: false})
    }

    handleKeyPress = (event, targetName = false, boolValue = false) => {
        if (event.charCode === 13) {
            this.proceedRawWord();
        }
    }

    render() {


        const {index, t: translate, userData: {user: {user_type_id}}} = this.props;

        const data = getDataFromModals(this.props, index);

        const {words, check_words, id, description} = data;

        const newCheckWords = typeof check_words == 'boolean' ? +check_words : +parseInt(check_words);

        console.log(check_words, newCheckWords)
        const {rawWords, submitted} = this.state;

        if (submitted) {
            return <Spinner/>
        }

        return (
            <Container>
                { !id &&
                    <Row>
                        <Col md={3} className="pt-2  real-align-middle">
                            <Form.Control
                                size="xs"
                                type="text"
                                name="rawWords"
                                defaultValue={rawWords}
                                onChange={this.handleChangeState}
                                onKeyPress={this.handleKeyPress}
                                placeholder={translate("Enter words")}/>
                        </Col>
                        <Col className="real-align-middle">
                            <Button
                                onClick={this.proceedRawWord}
                                className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm">
                                {translate('Convert')}
                            </Button>
                        </Col>
                    </Row>
                }

                {words.length > 0 &&
                <Row>
                    <Col>
                        <Tabs className="font-smaller" id="main-tab">
                            <Tab tabClassName="p-2 my-2" key="term" eventKey="term" title={translate('termin tab')}>
                                {(user_type_id === 1) ?
                                    <EditTermMainTab
                                        {...this.props}
                                    />
                                    :
                                <Row>
                                    <Col className="m-2 font-smaller">{translate('words')}: <strong>{convertArrayToText(words)}</strong></Col>
                                </Row>
                                }

                                <Row>
                                    <Col md={3}>
                                        <Form.Control
                                            size="xs"
                                            onChange={this.handleChange}
                                            value={newCheckWords}
                                            name="check_words"
                                            as="select">
                                            <option key={0} value={0}>{translate("Find in text by lemmas")}</option>
                                            <option key={1} value={1}>{translate("Find in text by words")}</option>
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            size="xs"
                                            onChange={this.handleChange}
                                            value={description}
                                            name="description"
                                            rows={3}
                                            as="textarea"
                                            placeholder={translate('description')}/>
                                    </Col>
                                </Row>
                            </Tab>
                             <Tab tabClassName="p-2 my-2" key="settings" disabled={words.length === 0}
                                 eventKey="settings" title={translate('settings tab')}>
                                <SettingTable
                                    addText='Add setting'
                                    settingText='Settings'
                                    valueText='Value'
                                    valuePlaceholder='Add Value'
                                    selectText='Select setting name'
                                    settings={config['termSettings']}
                                    settingsType={config['termSettingsType']}
                                    settingFieldName={"settings"}
                                    {...this.props}
                                />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                }
            </Container>
        )
    }
}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditTerm);
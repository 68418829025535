import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class ModalCloneTermCategory extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevData = prevProps.modalData.modal.data;
        const newData = this.props.modalData.modal.data;
        if (prevData !== newData) {
            this.checkForm();
        }
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.props.modalUpdateData({[name]: value})
    };

    checkForm() {
        const {
            modalData: {
                modal: {
                    data: {
                        level, from_inf, template
                    }
                }
            }
        } = this.props;

        if (level && from_inf && template) {
            this.props.modalUpdate({ok: true});
        } else {
            this.props.modalUpdate({ok: false});
        }
    }

    level = ['alert', 'warning', 'info']
    template = ['text_message', 'text_message_no_answer', 'must_change_password', 'terms_and_conditions']

    render() {
        const {
            t: translate,
            modalData: {
                modal: {
                    data: {
                         id, level, from_inf, template, text
                    }
                }
            }
        } = this.props;
        console.log('render modal');
        console.log({id, level, from_inf, template, text});
        return (
            <Container>
                 <Row>
                    <Col md={4}>
                        <Form.Group controlId="level">
                            <Form.Label>{translate('Level')}</Form.Label>
                            <Form.Control
                                onChange={e => this.handleChange(e)}
                                value={level}
                                name="level"
                                as="select">
                                {
                                    this.level.map((key)=> <option key={key} value={key}>{key}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="template">
                            <Form.Label>{translate('template')}</Form.Label>
                            <Form.Control
                                onChange={e => this.handleChange(e)}
                                value={template}
                                name="template"
                                as="select">
                                {
                                    this.template.map((key)=> <option key={key} value={key}>{key}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="from_inf">
                            <Form.Label>{translate('from_inf')}</Form.Label>
                            <Form.Control
                                type="text"
                                name="from_inf"
                                defaultValue={from_inf}
                                onChange={e => this.handleChange(e)}
                                placeholder={translate("Enter From")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="text">
                            <Form.Label>{translate('Enter message')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                name="text"
                                defaultValue={text}
                                onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalCloneTermCategory)
;
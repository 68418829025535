import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import { Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class ModalViewGroup extends Component {

    state = {
        tmpOptions: []
    }

    getOption() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const user_group_options = data?.user_group_options  ? data.user_group_options : {};

        const opt = Object.keys(user_group_options).map((option) => {
            return {option, value: user_group_options[option]}
        });
        this.setState({ tmpOptions: opt || [] });
    }

    componentDidMount() {
        this.getOption();
    }

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {name, company_id } = data;
        const {
            t: translate,
            adminData: {companies},
        } = this.props;
        const {tmpOptions} = this.state;

        console.log('render modal');

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="company_id">
                            <Form.Control
                                disabled={true}
                                value={company_id}
                                name="company_id"
                                as="select">
                                {
                                    Object.keys(companies).length > 0 && Object.keys(companies).map((company) =>
                                        <option key={company}
                                                value={company}>{translate(companies[company].name)}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Control
                                type="text"
                                name="name"
                                defaultValue={name}
                                disabled={true}
                                placeholder={translate("Enter name")}/>
                        </Form.Group>
                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">{translate("Options")}</h1>

                <Row>
                    <Col>
                        {
                            tmpOptions.map((opt,index) => {
                                const {option, value} = opt;
                                    return (
                                        <Row key={index}>
                                            <Col md={4}>
                                                <Form.Group controlId={"option_" + index}>
                                                    <Form.Control
                                                        type="text"
                                                        name={"option_" + index}
                                                        value={translate(option)}
                                                        disabled={true}
                                                        placeholder={translate("Enter option name")}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group controlId={"value_" + index}>
                                                    <Form.Control
                                                        type="text"
                                                        name={"value_" + index}
                                                        value={value}
                                                        disabled={true}
                                                        placeholder={translate("Enter description")}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                            )
                        }

                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalViewGroup);
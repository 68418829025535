import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BaseModal from "../base-modal";
import config from "../../../config";

class ModalSendMessageUser extends BaseModal {


    checkForm() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {level, from_inf, template} = data;
        //const modal = getModalFromModals(this.props, index);

        if (level && from_inf && template) {
            this.props.modalUpdate(index, {ok: true});
        } else {
            this.props.modalUpdate(index, {ok: false});
        }
    }

    level = config['messagesLevels'];
    template = config['messagesTemplates'];

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {id, level, from_inf, template, text} = data;

        const {
            t: translate,
        } = this.props;

        console.log('render modal');

        return (
            <Container>
                <Row>
                    <Col>
                        <h2>{translate('send message to user', {id})}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="level">
                            <Form.Label>{translate('Level')}</Form.Label>
                            <Form.Control
                                onChange={this.handleChange}
                                value={level}
                                name="level"
                                as="select">
                                <option key='' value=''>{translate('select')}</option>
                                {
                                    this.level.map((key) => <option key={key} value={key}>{translate(key)}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="template">
                            <Form.Label>{translate('template')}</Form.Label>
                            <Form.Control
                                onChange={this.handleChange}
                                value={template}
                                name="template"
                                as="select">
                                <option key='' value=''>{translate('select')}</option>
                                {
                                    this.template.map((key) => <option key={key} value={key}>{translate(key)}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="from_inf">
                            <Form.Label>{translate('from_inf')}</Form.Label>
                            <Form.Control
                                type="text"
                                name="from_inf"
                                defaultValue={from_inf}
                                onChange={this.handleChange}
                                placeholder={translate("Enter From")}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="text">
                            <Form.Label>{translate('Enter message')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                name="text"
                                defaultValue={text}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalSendMessageUser)
;
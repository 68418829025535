import {
    modalAdd,
    modalCleanAll,
    modalDelete,
    modalOpenClose,
    modalUpdate,
    modalUpdateData,
    modalReplace,
    modalReplaceData,
    modalSetErrorData
} from "../actions";

function getAllModalFunctions() {
    return {
        modalSetErrorData: modalSetErrorData,
        modalUpdateData: modalUpdateData,
        modalReplaceData: modalReplaceData,
        modalUpdate: modalUpdate,
        modalReplace: modalReplace,
        modalOpenClose: modalOpenClose,
        modalCleanAll: modalCleanAll,
        modalAdd: modalAdd,
        modalDelete: modalDelete
    }

}

export {
    getAllModalFunctions
}